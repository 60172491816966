// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_boost__tnHfj {
  border: 2px solid rgb(117, 83, 240);
  border-radius: 10px;
  padding: 4px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}
.style_boost__final__2TRoP {
  background: rgb(117, 83, 240);
}

.style_danger__qN5oB {
  color: #c10000;
  text-align: center;
  font-size: 1.4rem;
  font-weight: 500;
}`, "",{"version":3,"sources":["webpack://./src/features/boosts/components/boost-item/style.module.scss","webpack://./public/assets/colors.scss"],"names":[],"mappings":"AAEA;EACE,mCAAA;EACA,mBAAA;EACA,YAAA;EACA,aAAA;EACA,sBAAA;EACA,QAAA;EACA,WAAA;AADF;AAEE;EACE,6BCXG;ADWP;;AAIA;EACE,cCJO;EDKP,kBAAA;EACA,iBAAA;EACA,gBAAA;AADF","sourcesContent":["@import '../../../../../public/assets/colors';\n\n.boost {\n  border: 2px solid $main;\n  border-radius: 10px;\n  padding: 4px;\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n  width: 100%;\n  &__final {\n    background: $main;\n  }\n}\n\n.danger {\n  color: $danger;\n  text-align: center;\n  font-size: 1.4rem;\n  font-weight: 500;\n}\n","$main: rgba(117, 83, 240, 1);\n$light-main: rgba(237, 235, 254, 1);\n$black-extra: rgba(27, 27, 27, 1);\n$dark-grey: rgba(135, 135, 135, 1);\n$light-background: rgba(247, 246, 255, 1);\n$white: rgba(255, 255, 255, 1);\n$light-grey: rgba(213, 212, 220, 1);\n$yellow: rgba(255, 199, 1, 1);\n$grey: rgba(158, 158, 158, 1);\n$light-green: #39b283;\n$green: #1f6b4e;\n$dark-green: #1f4c3a;\n$danger: #c10000;\n$red: #e73232;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"boost": `style_boost__tnHfj`,
	"boost__final": `style_boost__final__2TRoP`,
	"danger": `style_danger__qN5oB`
};
export default ___CSS_LOADER_EXPORT___;
