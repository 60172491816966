import { useState, useEffect } from 'react';
import classes from './style.module.scss';
import { convertMSToReadableTime } from '../../utils/utils';

interface TimerProps {
  time: number;
  onTimerExceed: () => void; // Add this prop
}

const Timer = ({ time, onTimerExceed }: TimerProps) => {
  const [currentTime, setCurrentTime] = useState(time);

  useEffect(() => {
    const timerId = setInterval(() => {
      setCurrentTime((prevTime) => {
        if (prevTime <= 1000) {
          clearInterval(timerId); // Clear interval when timer reaches 0
          onTimerExceed(); // Notify parent that timer has exceeded
          return 0;
        }
        return prevTime - 1000; // Decrease time by 1 second (1000 ms)
      });
    }, 1000);

    return () => clearInterval(timerId); // Clear interval on component unmount
  }, [onTimerExceed]);

  const convertedTime = convertMSToReadableTime(currentTime);

  return (
    <div className={classes.timer}>
      <i className="icon-timer"></i>
      <p>{convertedTime} left</p>
    </div>
  );
};

export default Timer;
