import { useSpring, animated } from '@react-spring/web';
import { useEffect, useRef, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import classes from './style.module.scss';
import { RootState } from '../../../services/store';
import { formatCoins } from '../../../utils/utils';
import { vaultSizes } from '../../../pages/game/constants';
import Button, { ButtonSize, ButtonType, ButtonVariant } from '../../button';
import { useClaimVaultMutation } from '../../../features/tower/api/gameApi';
import { useGetUserQuery } from '../../../services/user/userApi';
import { Howl } from 'howler';
import { GtagEventType, trackGtagEvent } from '../../../utils/gtagEventHandler';

const Vault = () => {
  const vault = useSelector(
    (state: RootState) => state.user.user.towerGame.vaultBalance,
  );
  const user = useSelector((state: RootState) => state.user.user);
  const isEnoughToClaim = vault > 5000;
  const { refetch } = useGetUserQuery();
  const vaultSize = vaultSizes[user.towerGame.storageLevel] || 5000;
  const [claimVault, { isLoading: vaultClaiming }] = useClaimVaultMutation();

  const prevVaultRef = useRef<number | null>(null);
  const [prevVault, setPrevVault] = useState<number>(vault);
  const [isFirstRender, setIsFirstRender] = useState<boolean>(true);

  useEffect(() => {
    if (prevVaultRef.current !== null && vault !== prevVaultRef.current) {
      setPrevVault(prevVaultRef.current);
    }
    prevVaultRef.current = vault;
  }, [vault]);

  useEffect(() => {
    setIsFirstRender(false);
  }, []);

  const { number } = useSpring({
    from: { number: prevVault },
    to: { number: vault },
    config: { duration: 500 },
    immediate: isFirstRender,
  });

  const onHandleClaim = async () => {
    if (isEnoughToClaim) {
      try {
        await claimVault().unwrap();
        if (user.soundEnabled) {
          const claimSound = new Howl({
            src: [`${process.env.PUBLIC_URL}/assets/audio/claim.mp3`],
          });
          claimSound.play();
        }

        await refetch();
        await trackGtagEvent(GtagEventType.GAME_CLAIM);
      } catch (error) {
        console.error('Error claiming the vault:', error);
      }
    }
  };
  const debounce = <F extends (...args: any[]) => void>(
    func: F,
    delay: number,
  ): F => {
    let debounceTimer: ReturnType<typeof setTimeout>;
    return function (this: any, ...args: any[]) {
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => func.apply(this, args), delay);
    } as F;
  };

  const handleViewportChange = useCallback(
    debounce(() => {
      refetch();
    }, 1000),
    [],
  );

  useEffect(() => {
    const handleResize = () => {
      handleViewportChange();
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleViewportChange]);

  return (
    <div className={classes.vault}>
      <div className={classes.vault__size}>
        <img
          className={classes.confirmation__image}
          src={`${process.env.PUBLIC_URL}/assets/images/boosts/coin-m.svg`}
          alt="coin icon"
        />
        <animated.span>
          {number.to((n: number) => formatCoins(Math.round(n)))}
        </animated.span>{' '}
        / {formatCoins(vaultSize)}
      </div>
      <Button
        type={ButtonType.PRIMARY}
        variant={isEnoughToClaim ? ButtonVariant.ORANGE : ButtonVariant.GREY}
        size={ButtonSize.SMALL}
        title="CLAIM"
        isLoading={vaultClaiming}
        onClick={onHandleClaim}
      >
        {!isEnoughToClaim && (
          <div className={classes.vault__requirements}>
            <p className={classes.vault__requirements__title}>CLAIM</p>
            <div className={classes.vault__requirements__amount}>
              <img
                src={`${process.env.PUBLIC_URL}/assets/images/coin-m.svg`}
                alt="coin icon"
              />
              {formatCoins(5000)}
            </div>
          </div>
        )}
      </Button>
    </div>
  );
};

export default Vault;
