import Balance from '../../../../components/balance';
import ButtonAlt, {
  ButtonSize,
  ButtonType,
  ButtonVariant,
} from '../../../../components/button';
import classes from './style.module.scss';

interface ReferralBalanceProps {
  availableBalance: number;
  onClaim: () => void;
  isLoading: boolean;
}

const ReferralBalance = ({
  availableBalance,
  onClaim,
  isLoading,
}: ReferralBalanceProps) => {
  return (
    <div className={classes.referral}>
      <Balance title="Unclaimed reward" amount={availableBalance} />
      <ButtonAlt
        type={ButtonType.PRIMARY}
        size={ButtonSize.LARGE}
        variant={ButtonVariant.ORANGE}
        isLoading={isLoading}
        title="CLAIM"
        onClick={onClaim}
      />
    </div>
  );
};

export default ReferralBalance;
