import { useDispatch } from 'react-redux';
import Button, {
  ButtonSize,
  ButtonType,
  ButtonVariant,
} from '../../../../components/button';
import {
  Task,
  useClaimTaskMutation,
  useGetTasksInfoQuery,
} from '../../../../services/tasks/tasksApi';
import { useGetUserQuery } from '../../../../services/user/userApi';
import { TaskDetails } from '../../completed-tasks';
import TaskItem from '../task-item';
import { showAchievement } from '../../../../services/achievement/achievementSlice';
import {
  NotificationType,
  showNotification,
} from '../../../../services/notification/notificationSlice';
import ROUTES from '../../../../utils/routes';
import { useNavigate } from 'react-router-dom';
import { setNavigation } from '../../../../services/navigation/navigationSlice';

interface CuberiumInternalEmailProps {
  task: Task;
  readyForClaiming: boolean;
  details: TaskDetails;
}

const CuberiumInternalEmail = ({
  task,
  readyForClaiming,
  details,
}: CuberiumInternalEmailProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [claimTask, { isLoading: claimLoading }] = useClaimTaskMutation();
  const { refetch } = useGetUserQuery();
  const { refetch: refetchTasks } = useGetTasksInfoQuery();

  const onHandleClaim = async () => {
    try {
      await claimTask(task._id).unwrap();
      dispatch(
        showAchievement({
          title: 'REWARD',
          amount: task.coinsReward,
        }),
      );
      await refetch();
      await refetchTasks();
    } catch (error) {
      dispatch(
        showNotification({
          message: 'Sorry, something went wrong!',
          type: NotificationType.ERROR,
        }),
      );
    }
  };

  const onOpen = () => {
    dispatch(setNavigation(ROUTES.EARN));
    navigate(ROUTES.EMAIL);
  };
  return (
    <TaskItem
      title={details.title}
      icon={details.icon}
      reward={task.coinsReward}
    >
      {!readyForClaiming ? (
        <Button
          type={ButtonType.PRIMARY}
          variant={ButtonVariant.PURPLE}
          size={ButtonSize.SMALL}
          title={'OPEN'}
          onClick={onOpen}
        />
      ) : (
        <Button
          type={ButtonType.PRIMARY}
          variant={ButtonVariant.ORANGE}
          size={ButtonSize.SMALL}
          isLoading={claimLoading}
          title={'CLAIM'}
          onClick={() => {
            readyForClaiming && onHandleClaim();
          }}
        />
      )}
    </TaskItem>
  );
};

export default CuberiumInternalEmail;
