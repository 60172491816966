import React, { useState } from 'react';
import Button, {
  ButtonSize,
  ButtonType,
  ButtonVariant,
} from '../../../../components/button';
import {
  Task,
  TaskActionType,
  useClaimTaskMutation,
  useCompleteTaskMutation,
  useGetTasksInfoQuery,
} from '../../../../services/tasks/tasksApi';
import { TaskDetails } from '../../completed-tasks';
import { useDispatch } from 'react-redux';
import {
  NotificationType,
  showNotification,
} from '../../../../services/notification/notificationSlice';
import { showAchievement } from '../../../../services/achievement/achievementSlice';
import { useGetUserQuery } from '../../../../services/user/userApi';
import PopoverWarning from '../../../../components/popovers/popover-warning';
import TaskItem from '../task-item';
import {
  GtagEventType,
  trackGtagEvent,
} from '../../../../utils/gtagEventHandler';
import { User } from '../../../../services/user/types';
import { Howl } from 'howler';

interface ExternalWebsiteProps {
  user: User;
  task: Task;
  readyForClaiming: boolean;
  details: TaskDetails;
  isPartner: boolean;
}

export const ExternalWebsite = ({
  user,
  task,
  readyForClaiming,
  details,
  isPartner,
}: ExternalWebsiteProps) => {
  const dispatch = useDispatch();
  const { refetch } = useGetUserQuery();
  const [claimTask] = useClaimTaskMutation();
  const [completeTask, { isLoading: taskCompleting }] =
    useCompleteTaskMutation();
  const { refetch: refetchTasks } = useGetTasksInfoQuery();

  const [check, setCheck] = useState(false);
  const [warning, setWarning] = useState(false);

  const handleOpen = async () => {
    if (!check) {
      if (window.Telegram.WebApp) {
        window.Telegram.WebApp.openLink(task.link);
      } else {
        window.open(task.link, '_blank');
      }
      setTimeout(() => {
        window.focus();
      }, 100);
      setCheck(true);
    } else {
      setWarning(true);
    }
  };

  const handleComplete = async () => {
    setCheck(false);
    setWarning(false);
    try {
      await completeTask(task._id).unwrap();
      refetchTasks();
      refetch();
    } catch (error) {
      dispatch(
        showNotification({
          message: 'Something went wrong',
          type: NotificationType.ERROR,
        }),
      );
    }
  };

  const handleCloseWarning = () => {
    setCheck(false);
    setWarning(false);
  };

  const handleClaim = async () => {
    try {
      await claimTask(task._id).unwrap();
      if (user.soundEnabled) {
        const claimSound = new Howl({
          src: [`${process.env.PUBLIC_URL}/assets/audio/claim.mp3`],
        });
        claimSound.play();
      }
      if (task.actionType === TaskActionType.DISCORD) {
        await trackGtagEvent(GtagEventType.JOIN_DISCORD);
      } else if (task.actionType === TaskActionType.JOIN_CHANNEL) {
        await trackGtagEvent(GtagEventType.JOIN_TELEGRAM);
      }
      await trackGtagEvent(GtagEventType.WEBSITE_TASK);
      dispatch(
        showAchievement({
          title: 'REWARD',
          amount: task.coinsReward,
        }),
      );
      refetch();
    } catch (error) {
      console.error('Error claiming reward:', error);
    }
  };

  return (
    <TaskItem
      title={details.title}
      icon={details.icon}
      isPartner={isPartner}
      reward={task.coinsReward}
    >
      {!readyForClaiming ? (
        <Button
          type={ButtonType.PRIMARY}
          variant={ButtonVariant.PURPLE}
          size={ButtonSize.SMALL}
          isLoading={taskCompleting}
          title={check ? 'Check' : 'OPEN'}
          onClick={() => handleOpen()}
        />
      ) : (
        <Button
          type={ButtonType.PRIMARY}
          variant={ButtonVariant.ORANGE}
          size={ButtonSize.SMALL}
          isLoading={false}
          title={'CLAIM'}
          onClick={() => {
            readyForClaiming && handleClaim();
          }}
        />
      )}
      {warning && (
        <PopoverWarning onClose={handleCloseWarning} onClick={handleComplete} />
      )}
    </TaskItem>
  );
};

export default ExternalWebsite;
