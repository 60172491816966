import { useSpring, animated } from '@react-spring/web';
import { useEffect, useRef, useState } from 'react';
import classes from './style.module.scss';
import CoinBadgeImage from '../../assets/images/coin-m.svg';
import { formatCoins, roundAmount } from '../../utils/utils';

interface BalanceProps {
  title: string;
  amount: number;
  black?: boolean;
}

const Balance = ({ title, amount, black }: BalanceProps) => {
  const prevAmountRef = useRef<number>(amount);
  const [prevAmount, setPrevAmount] = useState(amount);

  useEffect(() => {
    if (amount !== prevAmountRef.current) {
      setPrevAmount(prevAmountRef.current);
      prevAmountRef.current = amount;
    }
  }, [amount]);

  const roundedAmount = roundAmount(amount);
  const previousAmount = roundAmount(prevAmount);

  const { number } = useSpring({
    from: { number: previousAmount },
    to: { number: roundedAmount },
    config: { duration: 1500 },
  });

  return (
    <div className={classes.balance}>
      <h2
        className={`${classes.balance__title} ${black && classes.balance__title__dark}`}
      >
        {title}
      </h2>
      <div className={classes.balance__amount}>
        <div className={classes.balance__icon}>
          <img src={CoinBadgeImage} alt="coin icon" />
        </div>
        <h4
          className={`${classes.balance__value} ${black && classes.balance__value__black}`}
        >
          <animated.span>
            {number.to((n) => formatCoins(Math.round(n)))}
          </animated.span>
        </h4>
      </div>
    </div>
  );
};

export default Balance;
