import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../services/store';
import CoinRain from './coin-rain';
import classes from './style.module.scss';
import { hideAchievement } from '../../services/achievement/achievementSlice';
import CoinBadgeImage from '../../assets/images/coin-m.svg';

import Button, { ButtonSize, ButtonType, ButtonVariant } from '../button';
import ROUTES from '../../utils/routes';
import { useEffect } from 'react';

const Achievement = () => {
  const reward = useSelector((state: RootState) => state.achievement);

  const dispatch = useDispatch();

  const close = () => {
    dispatch(hideAchievement());
  };

  useEffect(() => {
    if (reward.show) {
      document.body.style.overflow = 'hidden';
      return () => {
        const noScrollRoutes = [
          ROUTES.HOME,
          ROUTES.BOOSTS,
          ROUTES.REFERRAL,
          ROUTES.REFERRAL_ALL,
          ROUTES.EMAIL,
          ROUTES.JOIN_CLAN,
        ];
        if (noScrollRoutes.includes(location.pathname)) {
          document.body.style.overflow = 'hidden';
        } else {
          document.body.style.overflow = '';
        }
      };
    }
  }, [reward.show]);

  if (!reward.show) {
    return null;
  }

  return (
    <div className={classes.achievement}>
      <div className={classes.achievement__block}>
        <div className={classes.sunshine}>
          <img
            src={`${process.env.PUBLIC_URL}/assets/images/reward/sunshine.svg`}
          />
        </div>
        <div className={classes.coin}>
          <img
            src={`${process.env.PUBLIC_URL}/assets/images/reward/coin_large.svg`}
          />
        </div>
        <div className={classes.cloud__left}>
          <img
            src={`${process.env.PUBLIC_URL}/assets/images/reward/cloud.svg`}
          />
        </div>
        <div className={classes.cloud__right}>
          <img
            src={`${process.env.PUBLIC_URL}/assets/images/reward/cloud.svg`}
          />
        </div>
        <div className={classes.cloud__middle}>
          <div className={classes.cloud__middle__content}>
            <img
              className={classes.cloud__middle__image}
              src={`${process.env.PUBLIC_URL}/assets/images/reward/cloud.svg`}
            />
            <div className={classes.text}>
              <p className={classes.text__title}>
                {reward.title.toUpperCase()}
              </p>
              <p className={classes.text__amount}>
                <img src={CoinBadgeImage} alt="coin icon" />
                <span className={classes.text__amount__text}>
                  {reward.amount}
                </span>
              </p>
              <p className={classes.text__exp}>+{reward.xp} XP</p>
            </div>
          </div>
        </div>
        <CoinRain />
      </div>
      <div className={classes.action}>
        <Button
          type={ButtonType.PRIMARY}
          variant={ButtonVariant.PURPLE}
          size={ButtonSize.LARGE}
          title="CONTINUE"
          onClick={close}
        />
      </div>
    </div>
  );
};

export default Achievement;
