import { useState } from 'react';
import PageTitle from '../../../components/page-title';
import classes from './style.module.scss';
import Users from './users';
import SectionDivider from '../../../components/section-divider';
import Footer from '../../../components/footer';
import { useGetUserQuery } from '../../../services/user/userApi';
import { useGetAllReferralsQuery } from '../api/referralApi';

const ReferralsList = () => {
  const [page, setPage] = useState<number>(1);
  const limit = 10;
  const { data: user } = useGetUserQuery();

  const { data: referralData } = useGetAllReferralsQuery({ page, limit });

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  return (
    <div className={classes.referral}>
      <PageTitle title="REFERRALS" />
      <SectionDivider>
        <div className={classes.total__icon}>
          <i className="icon-person"></i>
          {referralData?.totalReferrals}
        </div>
      </SectionDivider>
      {referralData && (
        <Users data={referralData} onChange={handlePageChange} />
      )}
      {user && <Footer />}
    </div>
  );
};

export default ReferralsList;
