import { useState } from 'react';
import Button, {
  ButtonSize,
  ButtonType,
  ButtonVariant,
} from '../../../../components/button';
import {
  Task,
  TaskSocialNetworkType,
  useClaimTaskMutation,
  useCompleteTaskMutation,
  useGetTasksInfoQuery,
} from '../../../../services/tasks/tasksApi';
import { TaskDetails } from '../../completed-tasks';
import { useDispatch } from 'react-redux';
import {
  NotificationType,
  showNotification,
} from '../../../../services/notification/notificationSlice';
import { showAchievement } from '../../../../services/achievement/achievementSlice';
import { useGetUserQuery } from '../../../../services/user/userApi';
import PopoverWarning from '../../../../components/popovers/popover-warning';
import TaskItem from '../task-item';
import { User } from '../../../../services/user/types';

interface CuberiumDiscordProps {
  task: Task;
  readyForClaiming: boolean;
  details: TaskDetails;
  user: User;
  isPartner?: boolean;
}

export const CuberiumSocial = ({
  task,
  readyForClaiming,
  details,
  user,
  isPartner,
}: CuberiumDiscordProps) => {
  const dispatch = useDispatch();
  const { refetch } = useGetUserQuery();
  const [claimTask] = useClaimTaskMutation();
  const [completeTask, { isLoading: taskCompleting }] =
    useCompleteTaskMutation();
  const { refetch: refetchTasks } = useGetTasksInfoQuery();

  const [check, setCheck] = useState(false);
  const [warning, setWarning] = useState(false);

  const handleOpen = () => {
    const openLink = () => {
      if (window.Telegram?.WebApp) {
        if (task.link.includes('t.me')) {
          window.Telegram.WebApp.openTelegramLink(task.link);
        } else {
          window.Telegram.WebApp.openLink(task.link);
        }
      } else {
        window.open(task.link, '_blank');
      }
      setCheck(true);
    };

    const showWarning = () => {
      setWarning(true);
    };

    const notifyToConnectSocialNetwork = (
      socialNetworkType: TaskSocialNetworkType,
    ) => {
      dispatch(
        showNotification({
          message: `Please start by going to your Profile and connecting your ${socialNetworkType} account`,
          type: NotificationType.ERROR,
        }),
      );
    };

    const handleDiscordTask = () => {
      const findUserSocialNetwork = user.connectedSocials.find(
        (connectedSocial) =>
          connectedSocial.socialNetworkType === task.socialNetworkType,
      );

      if (!findUserSocialNetwork) {
        notifyToConnectSocialNetwork(task.socialNetworkType);
      } else if (!check) {
        openLink();
      } else {
        showWarning();
      }
    };

    const handleOtherTask = () => {
      if (!check) {
        openLink();
      } else {
        showWarning();
      }
    };

    if (task.socialNetworkType === TaskSocialNetworkType.DISCORD) {
      handleDiscordTask();
    } else {
      handleOtherTask();
    }
  };

  const handleComplete = async () => {
    setCheck(false);
    setWarning(false);
    try {
      await completeTask(task._id).unwrap();
      refetchTasks();
      refetch();
    } catch (error: any) {
      if (error.data.error) {
        dispatch(
          showNotification({
            message: error.data.error,
            type: NotificationType.ERROR,
          }),
        );
      } else {
        dispatch(
          showNotification({
            message: 'Something went wrong',
            type: NotificationType.ERROR,
          }),
        );
      }
    }
  };

  const handleCloseWarning = () => {
    setCheck(false);
    setWarning(false);
  };

  const handleClaim = async () => {
    try {
      await claimTask(task._id).unwrap();
      dispatch(
        showAchievement({
          title: 'REWARD',
          amount: task.coinsReward,
        }),
      );
      refetch();
    } catch (error: any) {
      dispatch(
        showNotification({
          message: error.data.error || 'Something went wrong!',
          type: NotificationType.ERROR,
        }),
      );
    }
  };

  return (
    <TaskItem
      title={details.title}
      icon={details.icon}
      reward={task.coinsReward}
      isPartner={isPartner}
    >
      {!readyForClaiming ? (
        <Button
          type={ButtonType.PRIMARY}
          variant={ButtonVariant.PURPLE}
          size={ButtonSize.SMALL}
          isLoading={taskCompleting}
          title={check ? 'Check' : 'OPEN'}
          onClick={() => handleOpen()}
        />
      ) : (
        <Button
          type={ButtonType.PRIMARY}
          variant={ButtonVariant.ORANGE}
          size={ButtonSize.SMALL}
          isLoading={false}
          title={'CLAIM'}
          onClick={() => {
            readyForClaiming && handleClaim();
          }}
        />
      )}
      {warning && (
        <PopoverWarning onClose={handleCloseWarning} onClick={handleComplete} />
      )}
    </TaskItem>
  );
};

export default CuberiumSocial;
