import { useNavigate, useLocation } from 'react-router-dom';
import classes from './style.module.scss';
import ROUTES from '../../utils/routes';
import { useDispatch, useSelector } from 'react-redux';
import { showPopover } from '../../services/popover/popoverSlice';
import { useGetUserQuery } from '../../services/user/userApi';
import {
  useGetTasksInfoQuery,
  useGetDailyTaskQuery,
} from '../../services/tasks/tasksApi';
import { FooterItem, footerItems } from './items';
import { useEffect, useState } from 'react';
import { RootState } from '../../services/store';
import {
  displayFooterHint,
  toggleFooterFirstHint,
} from '../../services/user/userSlice';

const Footer = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { data: user } = useGetUserQuery();
  const { data: tasks } = useGetTasksInfoQuery();
  const { data: dailyTask } = useGetDailyTaskQuery();
  const footerHint = useSelector(
    (state: RootState) => state.user.showFooterHint,
  );
  const firstLoadForFooterHint = useSelector(
    (state: RootState) => state.user.footerHintFirstLoad,
  );

  const [isFadingOut, setIsFadingOut] = useState(false);

  const handleShowPopover = () => {
    dispatch(
      showPopover({
        title: 'Farming starts: soon',
        description:
          'We are crafting this crazy Meme Coins farming game for you.',
      }),
    );
  };

  const handleNavigation = (path: string, item: FooterItem) => {
    dispatch(displayFooterHint(false));
    dispatch(toggleFooterFirstHint(false));
    item.isActive ? navigate(path) : handleShowPopover();
  };

  const isActive = (path: string, subPath: string) =>
    location.pathname === path || location.pathname === subPath;

  const isNotificationVisible = (item: FooterItem) => item.hasIcon;

  const getIncompleteTasks = () => {
    const incompleteTasks =
      tasks?.filter((task) => {
        const isCompleted = user?.completedTasks?.some(
          (completedTask) =>
            completedTask.taskId === task._id && completedTask.isClaimed,
        );
        return !isCompleted;
      }) || [];

    const dailyTaskIncomplete = dailyTask && dailyTask.canClaimToday ? 1 : 0;

    return incompleteTasks.length + dailyTaskIncomplete;
  };

  const isHomePage = location.pathname === ROUTES.HOME;

  useEffect(() => {
    if (location.pathname !== '/' && firstLoadForFooterHint) {
      dispatch(toggleFooterFirstHint(false));
    }
  }, []);

  useEffect(() => {
    if (dailyTask && dailyTask.canClaimToday && firstLoadForFooterHint) {
      dispatch(displayFooterHint(true));
      const timer = setTimeout(() => {
        setIsFadingOut(true);
        setTimeout(() => {
          dispatch(toggleFooterFirstHint(false));
          dispatch(displayFooterHint(false));
        }, 1000);
      }, 6500);

      return () => clearTimeout(timer);
    }
  }, [user, tasks, dailyTask, firstLoadForFooterHint]);

  return (
    <div
      className={`${classes.footer} ${!isHomePage && classes.footer__filled}`}
    >
      {footerItems.map((item, index) => (
        <div
          key={index}
          onClick={() => handleNavigation(item.path, item)}
          className={`${classes.footer__item} ${isActive(item.path, item.subPath) ? classes.footer__item__active : ''} ${index === 2 ? classes.footer__item__main : ''}`}
        >
          <i
            className={`${classes.footer__icon} icon-${item.icon} ${isActive(item.path, item.subPath) ? classes.footer__icon__active : ''}`}
          />
          {isNotificationVisible(item) && user && tasks && dailyTask && (
            <div className={classes.footer__item__notification}>
              <p className={classes.footer__item__notification__text}>
                {getIncompleteTasks()}
              </p>
            </div>
          )}
          {isNotificationVisible(item) &&
            user &&
            tasks &&
            dailyTask &&
            dailyTask.canClaimToday &&
            footerHint &&
            firstLoadForFooterHint && (
              <div
                className={`${classes.footer__item__tooltip} ${isFadingOut ? classes.fadeOut : classes.fadeIn}`}
              >
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/earn-tooltip.svg`}
                />
              </div>
            )}
          <p
            className={`${classes.footer__item__text} ${isActive(item.path, item.subPath) ? classes.footer__item__text__active : ''}`}
          >
            {item.text}
          </p>
        </div>
      ))}
    </div>
  );
};

export default Footer;
