import {
  BOOSTS_TYPE,
  TOWER_POWER,
  TREASURE_MAP,
  VAULT_SIZE,
} from '../../../api/types';
import { formatReward } from '../../../../../utils/utils';

export const boostConfirmationContent = {
  [BOOSTS_TYPE.TOWER_POWER]: {
    title: 'TOWER POWER',
    image: 'tower',
    description: (boost: TOWER_POWER) =>
      `Upgrade to level ${boost.next?.level} and increase your turret's firing speed to ${boost.next?.coinFrequency} sec.`,
  },
  [BOOSTS_TYPE.VAULT_SIZE]: {
    title: 'VAULT SIZE',
    image: 'vault',
    description: (boost: VAULT_SIZE) =>
      `Upgrade to level ${boost.next?.level} and increase its capacity to ${formatReward(boost.next?.storageCapacity || 0)} coins.`,
  },
  [BOOSTS_TYPE.TREASURE_MAP]: {
    title: 'DAILY CHEST',
    image: 'treasure',
    description: (boost: TREASURE_MAP) =>
      `Upgrade to level ${boost.next?.level} and get ${boost.next?.numberOfChests} chest per day.`,
  },
};

export const isTowerPower = (boost: any): boost is TOWER_POWER =>
  boost.boostType === BOOSTS_TYPE.TOWER_POWER;
export const isVaultSize = (boost: any): boost is VAULT_SIZE =>
  boost.boostType === BOOSTS_TYPE.VAULT_SIZE;
export const isTreasureMap = (boost: any): boost is TREASURE_MAP =>
  boost.boostType === BOOSTS_TYPE.TREASURE_MAP;
