import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReauth } from '../../../services/baseQuery';
import { UserReferInfo, UserReferrals } from './types';

const BASE_URL = '/referral';

export const referralApi = createApi({
  reducerPath: 'referralApi',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['user'],
  endpoints: (builder) => ({
    claimReferral: builder.mutation<{ claimed: number }, void>({
      query: () => ({
        url: `${BASE_URL}/claim`,
        method: 'PATCH',
      }),
      invalidatesTags: ['user'],
    }),
    getReferralLink: builder.query<{ referralLink: string }, void>({
      query: () => ({
        url: `${BASE_URL}/link`,
        method: 'GET',
      }),
    }),

    getReferralInfo: builder.query<UserReferInfo, void>({
      query: () => ({
        url: `${BASE_URL}/info/v2`,
      }),
      providesTags: ['user'],
    }),

    getAllReferrals: builder.query<
      UserReferrals,
      { page?: number; limit?: number }
    >({
      query: ({ page = 1, limit = 10 }) => ({
        url: `${BASE_URL}`,
        params: { page, limit },
      }),
      providesTags: ['user'],
    }),
  }),
});

export const {
  useGetReferralInfoQuery,
  useLazyGetReferralLinkQuery,
  useGetReferralLinkQuery,
  useClaimReferralMutation,
  useGetAllReferralsQuery,
} = referralApi;
