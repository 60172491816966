import { useEffect, useRef } from 'react';
import {
  useGetUserQuery,
  useGetUserStatsQuery,
  useToggleSoundMutation,
} from '../../services/user/userApi';
import SectionDivider from '../../components/section-divider';
import SectionTitle from '../../components/section-title';
import classes from './style.module.scss';
import ProgressNumbers from '../../components/progress-numbers';
import Balance from '../../components/balance';
import SmallBalance from '../../components/small-balance';
import Socials from './socials';
import UserInfoBadge from '../../components/user-info-badge';
import { useGetTasksInfoQuery } from '../../services/tasks/tasksApi';
import { calculateTimeSpent, formatCoins } from '../../utils/utils';
import StatisticsText from '../../components/statistics/statistics-text';
import { useDispatch, useSelector } from 'react-redux';
import { hideLoader } from '../../services/loader/loaderSlice';
import { RootState } from '../../services/store';
import TasksWrapper from '../earn/tasks/tasks-wrapper';
import PageDescription from '../../components/page-description';
import StatisticsItem from '../../components/statistics/statistics-item';
import { GtagEventType, trackGtagEvent } from '../../utils/gtagEventHandler';
import Footer from '../../components/footer';

const Profile = () => {
  const { data: user } = useGetUserQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });

  const { data: tasks } = useGetTasksInfoQuery();
  const { data: stats } = useGetUserStatsQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });
  const [toggleSound] = useToggleSoundMutation();

  const dispatch = useDispatch();
  const loading = useSelector((state: RootState) => state.loader.loading);
  const hasSentGtag = useRef(false);

  const onSoundToggle = async () => {
    await toggleSound();
  };

  useEffect(() => {
    if (loading) {
      setTimeout(() => {
        dispatch(hideLoader());
      }, 1500);
    }
  }, [loading, dispatch]);

  useEffect(() => {
    if (!hasSentGtag.current) {
      trackGtagEvent(GtagEventType.VISIT_PROFILE);
      hasSentGtag.current = true;
    }
  }, []);

  return (
    <div className={classes.profile}>
      {user && tasks && (
        <>
          <UserInfoBadge user={user} />
          <ProgressNumbers currentXP={user.totalXp} level={user.level} />
          <div className={classes.sound} onClick={onSoundToggle}>
            <i
              className={`icon-${user.soundEnabled ? 'sound_on' : 'sound_off'}`}
            ></i>
          </div>
          <Balance
            title="Coins balance"
            amount={user.coinsBalance}
            black={true}
          />
          <SectionDivider>
            <SectionTitle title="SOCIAL" />
          </SectionDivider>
          <Socials user={user} tasks={tasks} />
          <PageDescription description="Your profile is not visible to other users" />
          <SectionDivider>
            <SectionTitle title="STATISTICS" />
          </SectionDivider>
          {stats && (
            <TasksWrapper>
              <StatisticsItem title="Total Earned">
                <SmallBalance amount={stats.totalEarned} />
              </StatisticsItem>
              <StatisticsItem title="Spent Coins">
                <SmallBalance amount={stats.spentCoins} />
              </StatisticsItem>
              <StatisticsItem title="Time in game">
                <StatisticsText title={calculateTimeSpent(user.createdAt)} />
              </StatisticsItem>
              <StatisticsItem title="Boss defeated">
                <StatisticsText title={formatCoins(stats.bossesDefeated)} />
              </StatisticsItem>
              <StatisticsItem title="Bombs used">
                <StatisticsText title={formatCoins(stats.bombsExploded)} />
              </StatisticsItem>
              <StatisticsItem title="Mushrooms streak">
                <StatisticsText title={formatCoins(user.tapGame.maxStreak)} />
              </StatisticsItem>
              <StatisticsItem title="Mushrooms">
                <StatisticsText title={formatCoins(user.tapGame.totalTaps)} />
              </StatisticsItem>
              <StatisticsItem title="Referrals">
                <StatisticsText
                  icon="person"
                  title={user.referralsAmount.toString()}
                />
              </StatisticsItem>
            </TasksWrapper>
          )}
        </>
      )}
      {user && <Footer />}
    </div>
  );
};

export default Profile;
