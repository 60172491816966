import { Leaderboard } from '../../../../../clans-war/api/types';
import classes from './style.module.scss';
import {
  formatTotalCoins,
  getColorForInitial,
  getInitial,
  maskUsername,
} from '../../../../../../utils/utils';

interface LeaderboardUsersProps {
  data: Leaderboard;
  mainColor: string;
}

const LeaderboardUsers = ({ data, mainColor }: LeaderboardUsersProps) => {
  return (
    <div className={classes.users}>
      {data.current && (
        <div className={classes.current} style={{ backgroundColor: mainColor }}>
          <div className={classes.current__part}>
            <h3 className={classes.coins}>
              {formatTotalCoins(data.current.place, true)}
            </h3>
            <div className={classes.current__icon}>
              <p
                style={{
                  color: getColorForInitial(data.current.username),
                  fontWeight: 600,
                }}
              >
                {getInitial(data.current.username)}
              </p>
            </div>
            <p className={classes.current__title}>{data.current.username}</p>
          </div>
          <div className={classes.current__part}>
            <h3 className={classes.coins}>
              {formatTotalCoins(data.current.points)}
            </h3>
          </div>
        </div>
      )}

      <div
        className={classes.list}
        style={{ border: `2px solid ${mainColor}` }}
      >
        {data.top.map((leaderboardEntry) =>
          leaderboardEntry.userId === data.current?.userId ? (
            <div
              className={classes.list__current}
              key={leaderboardEntry.userId}
              style={{ backgroundColor: mainColor }}
            >
              <div className={classes.current__part}>
                <h3 className={classes.coins} style={{ width: '20px' }}>
                  {formatTotalCoins(data.current?.place, true)}
                </h3>
                <div className={classes.current__icon}>
                  <p
                    style={{
                      color: getColorForInitial(data.current?.username || ''),
                      fontWeight: 600,
                    }}
                  >
                    {getInitial(data.current?.username || '')}
                  </p>
                </div>
                <p className={classes.current__title}>
                  {data.current?.username}
                </p>
              </div>
              <div className={classes.current__part}>
                <h3 className={classes.coins}>
                  {formatTotalCoins(data.current?.points)}
                </h3>
              </div>
            </div>
          ) : (
            <div key={leaderboardEntry.userId} className={classes.user}>
              <div className={classes.user__part}>
                <h3
                  className={classes.coins}
                  style={{ color: mainColor, width: '20px' }}
                >
                  {formatTotalCoins(leaderboardEntry.place, true)}
                </h3>
                <div className={classes.current__icon}>
                  <p
                    style={{
                      color: getColorForInitial(leaderboardEntry.username),
                      fontWeight: 600,
                    }}
                  >
                    {getInitial(leaderboardEntry.username)}
                  </p>
                </div>
                <p className={classes.user__title}>
                  {maskUsername(leaderboardEntry.username)}
                </p>
              </div>
              <div className={classes.user__part}>
                <h3 className={classes.coins}>
                  {formatTotalCoins(leaderboardEntry.points)}
                </h3>
              </div>
            </div>
          ),
        )}
      </div>
    </div>
  );
};

export default LeaderboardUsers;
