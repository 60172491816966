import { useEffect, useRef } from 'react';
import Balance from '../../components/balance';
import Page from '../../components/layouts/page';
import SectionDivider from '../../components/section-divider';
import SectionTitle from '../../components/section-title';
import { useGetBoostsQuery } from './api/boostsApi';
import { useGetUserQuery } from '../../services/user/userApi';
import BoostItem from './components/boost-item';
import { GtagEventType, trackGtagEvent } from '../../utils/gtagEventHandler';
import Footer from '../../components/footer';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../services/store';
import { hideLoader } from '../../services/loader/loaderSlice';

const Boosts = () => {
  const { data: user } = useGetUserQuery();
  const { data: boosts } = useGetBoostsQuery();
  const hasSentGtag = useRef(false);

  const dispatch = useDispatch();
  const loading = useSelector((state: RootState) => state.loader.loading);

  useEffect(() => {
    if (loading) {
      setTimeout(() => {
        dispatch(hideLoader());
      }, 1500);
    }
  }, []);

  useEffect(() => {
    if (!hasSentGtag.current) {
      trackGtagEvent(GtagEventType.VISIT_BOOST);
      hasSentGtag.current = true;
    }
  }, []);

  return (
    <Page title="BOOSTS">
      {user && (
        <Balance
          title="Coins balance"
          amount={user.coinsBalance}
          black={true}
        />
      )}
      <SectionDivider>
        <SectionTitle title="UPGRADES" />
      </SectionDivider>
      {user && boosts && (
        <>
          <BoostItem
            boost={boosts.towerPower}
            maxLevel={boosts.towerPower.maxLevel}
            user={user}
          />
          <BoostItem
            boost={boosts.vaultSize}
            maxLevel={boosts.vaultSize.maxLevel}
            user={user}
          />
        </>
      )}
      {user && <Footer />}
    </Page>
  );
};

export default Boosts;
