import React, { ReactNode, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ROUTES from '../../utils/routes';

interface ScrollProviderProps {
  children: ReactNode;
}

const ScrollProvider = ({ children }: ScrollProviderProps) => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);

    const noScrollRoutes = [
      ROUTES.HOME,
      ROUTES.BOOSTS,
      ROUTES.REFERRAL,
      ROUTES.REFERRAL_ALL,
      ROUTES.EMAIL,
      ROUTES.JOIN_CLAN,
    ];

    if (noScrollRoutes.includes(location.pathname)) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }

    return () => {
      document.body.style.overflow = '';
    };
  }, [location.pathname]);

  return <>{children}</>;
};

export default ScrollProvider;
