import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../services/store';
import { hidePopover } from '../../../services/popover/popoverSlice';
import AnimatedPopoverWrapper from '../components/popover-animated-wrapper';
import PopoverTitle from '../components/popover-title';
import PopoverDescription from '../components/popover-description';
import Button, { ButtonSize, ButtonType, ButtonVariant } from '../../button';

const Popover = () => {
  const dispatch = useDispatch();
  const { isVisible, title, description } = useSelector(
    (state: RootState) => state.popover,
  );

  const handleClose = () => {
    dispatch(hidePopover());
  };

  return (
    <AnimatedPopoverWrapper isVisible={isVisible}>
      <PopoverTitle title={title} />
      <PopoverDescription description={description} />
      <Button
        title="CONTINUE"
        onClick={handleClose}
        type={ButtonType.PRIMARY}
        variant={ButtonVariant.ORANGE}
        size={ButtonSize.LARGE}
      />
    </AnimatedPopoverWrapper>
  );
};

export default Popover;
