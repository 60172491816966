import { useState } from 'react';
import classes from './style.module.scss';
import Ribbon from '../../../clans/components/ribbon';
import Timer from '../../../../components/timer';

interface ClanWarsBannerProps {
  initialIsEnded: boolean; // Renamed to initialIsEnded to clarify it's an initial state
  week: number;
  timesLeft: number;
}

const ClanWarsBanner = ({
  initialIsEnded,
  week,
  timesLeft,
}: ClanWarsBannerProps) => {
  const [isEnded, setIsEnded] = useState(initialIsEnded);

  const handleTimerExceed = () => {
    setIsEnded(true);
  };

  return (
    <div className={classes.banner}>
      <Ribbon title="CLAN WARS" />
      <div className={classes.banner__confetti}>
        <img
          src={`${process.env.PUBLIC_URL}/assets/images/reward/confetti-background.png`}
          alt="Confetti Background"
        />
      </div>

      <h3 className={classes.banner__title}>WEEK {week}</h3>
      <div className={classes.banner__images}>
        <img
          src={`${process.env.PUBLIC_URL}/assets/images/clan/sword-left.svg`}
          alt="Left Sword"
        />
        <div className={classes.banner__trophy}>
          <div className={classes.banner__trophy__image}>
            <img
              src={`${process.env.PUBLIC_URL}/assets/images/reward/prize.svg`}
              alt="Trophy"
            />
          </div>
          <div className={classes.banner__sunshine}>
            <img
              src={`${process.env.PUBLIC_URL}/assets/images/reward/rays.svg`}
              alt="Sunshine"
            />
          </div>
        </div>
        <img
          src={`${process.env.PUBLIC_URL}/assets/images/clan/sword-right.svg`}
          alt="Right Sword"
        />
      </div>
      <div className={classes.banner__timer}>
        {isEnded && `Week ${week} Tournament ended`}
        {!isEnded && (
          <Timer time={timesLeft} onTimerExceed={handleTimerExceed} />
        )}
      </div>
      {isEnded && <p className={classes.finished}>Processing rewards.</p>}

      {!isEnded && (
        <div className={classes.rules}>
          <p className={classes.rules__description}>
            Earn coins during the tournament week to lead your clan to victory.
          </p>
          <p className={classes.rules__reward}>1 Coin = 1 Point</p>
          <p className={classes.rules__description}>
            {`Coins earned with referrals and "Earn" tasks are excluded.`}
          </p>
        </div>
      )}
    </div>
  );
};

export default ClanWarsBanner;
