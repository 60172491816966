import Button, {
  ButtonSize,
  ButtonType,
  ButtonVariant,
} from '../../../../../components/button';
import { TOWER_POWER, TREASURE_MAP, VAULT_SIZE } from '../../../api/types';
import { formatReward } from '../../../../../utils/utils';
import classes from './style.module.scss';

interface BoostUpgradeProps {
  icon: string;
  boost: TOWER_POWER | VAULT_SIZE | TREASURE_MAP;
  value: string;
  isDisabled: boolean;
  onUpgrade: () => void;
  isLoading: boolean;
}

const BoostUpgrade = ({
  icon,
  boost,
  value,
  isDisabled,
  onUpgrade,
  isLoading,
}: BoostUpgradeProps) => {
  return (
    <div className={classes.upgrade}>
      <div className={classes.upgrade__left}>
        <div className={classes.upgrade__left__part}>
          <img
            className={classes.update__image}
            src={`${process.env.PUBLIC_URL}/assets/images/boosts/arrow.svg`}
            alt="upgrade arrow"
          />
          <h3 className={classes.upgrade__title}>
            Lvl. {boost.current.level + 1}
          </h3>
        </div>
        <div className={classes.upgrade__description}>
          <div className={classes.upgrade__item}>
            <img
              className={classes.upgrade__image}
              src={`${process.env.PUBLIC_URL}/assets/images/boosts/coin-m.svg`}
              alt="upgrade arrow"
            />
            <p className={classes.upgrade__item__title}>
              {formatReward(boost.next!.cost)}
            </p>
          </div>
          <div className={classes.upgrade__item}>
            <i className={`icon-${icon}`}></i>
            <p className={classes.upgrade__item__title}>{value}</p>
          </div>
        </div>
      </div>
      <div className={classes.boost__upgrade__right}>
        {isDisabled ? (
          <Button
            type={ButtonType.PRIMARY}
            variant={ButtonVariant.GREY}
            size={ButtonSize.SMALL}
            title="UPGRADE"
            onClick={() => {}}
          />
        ) : (
          <Button
            type={ButtonType.PRIMARY}
            variant={ButtonVariant.GREEN}
            size={ButtonSize.SMALL}
            title="UPGRADE"
            isLoading={isLoading}
            onClick={onUpgrade}
          />
        )}
      </div>
    </div>
  );
};

export default BoostUpgrade;
