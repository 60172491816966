// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_referral__RJ4zP {
  padding: 12px 16px 100px 16px;
  width: 100vw;
  overflow-y: auto;
  height: 100vh;
  background: rgb(247, 246, 255);
  text-align: center;
  display: flex;
  flex-direction: column;
  margin: 0;
}

.style_total__icon__DNph8 {
  display: flex;
  color: rgb(27, 27, 27);
  gap: 4px;
}
.style_total__icon__DNph8 i {
  color: rgb(27, 27, 27);
}`, "",{"version":3,"sources":["webpack://./src/features/referrals/referrals-list/style.module.scss","webpack://./public/assets/colors.scss"],"names":[],"mappings":"AAEA;EACE,6BAAA;EACA,YAAA;EACA,gBAAA;EACA,aAAA;EACA,8BAAA;EACA,kBAAA;EACA,aAAA;EACA,sBAAA;EACA,SAAA;AADF;;AAIA;EACE,aAAA;EACA,sBCdY;EDeZ,QAAA;AADF;AAEE;EACE,sBCjBU;ADiBd","sourcesContent":["@import '../../../../public/assets/colors';\n\n.referral {\n  padding: 12px 16px 100px 16px;\n  width: 100vw;\n  overflow-y: auto;\n  height: 100vh;\n  background: rgba(247, 246, 255, 1);\n  text-align: center;\n  display: flex;\n  flex-direction: column;\n  margin: 0;\n}\n\n.total__icon {\n  display: flex;\n  color: $black-extra;\n  gap: 4px;\n  i {\n    color: $black-extra;\n  }\n}\n","$main: rgba(117, 83, 240, 1);\n$light-main: rgba(237, 235, 254, 1);\n$black-extra: rgba(27, 27, 27, 1);\n$dark-grey: rgba(135, 135, 135, 1);\n$light-background: rgba(247, 246, 255, 1);\n$white: rgba(255, 255, 255, 1);\n$light-grey: rgba(213, 212, 220, 1);\n$yellow: rgba(255, 199, 1, 1);\n$grey: rgba(158, 158, 158, 1);\n$light-green: #39b283;\n$green: #1f6b4e;\n$dark-green: #1f4c3a;\n$danger: #c10000;\n$red: #e73232;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"referral": `style_referral__RJ4zP`,
	"total__icon": `style_total__icon__DNph8`
};
export default ___CSS_LOADER_EXPORT___;
