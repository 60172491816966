import { useState } from 'react';
import { useGetClansQuery, useJoinClanMutation } from '../api/clansApi';
import { ClanShortInfo } from '../api/types';
import classes from './style.module.scss';
import ClanItem from './clan-item';
import PopoverAnimatedWrapper from '../../../components/popovers/components/popover-animated-wrapper';
import PopoverTitle from '../../../components/popovers/components/popover-title';
import PopoverDescription from '../../../components/popovers/components/popover-description';
import Button, {
  ButtonSize,
  ButtonType,
  ButtonVariant,
} from '../../../components/button';
import { useNavigate } from 'react-router-dom';
import ROUTES from '../../../utils/routes';
import { useGetUserQuery } from '../../../services/user/userApi';

const ClanSelection = () => {
  const { data } = useGetClansQuery();
  const [selectedClan, setSelectedClan] = useState<ClanShortInfo | null>(null);
  const [joinClan, { isLoading: clanJoinLoading }] = useJoinClanMutation();
  const { refetch } = useGetUserQuery();
  const navigate = useNavigate();

  const onSelect = (clan: ClanShortInfo) => {
    setSelectedClan(clan);
  };

  const onJoin = async () => {
    try {
      if (selectedClan) {
        setSelectedClan(null);
        await joinClan(selectedClan._id);
        await refetch();
        navigate(`${ROUTES.CLAN}/${selectedClan._id}`);
      }
    } catch (e) {
      console.error('error', e);
    }
  };

  return (
    <div className={classes.page}>
      <h1 className={classes.page__title}>JOIN YOUR CLAN</h1>
      <p className={classes.description}>
        Choose your favorite meme clan <br />
        and be a part of great clan wars soon!
      </p>

      {data && (
        <>
          <div className={classes.clans}>
            {data.clans.map((clan: ClanShortInfo) => (
              <ClanItem
                key={clan._id}
                clan={clan}
                onSelect={onSelect}
                isLoading={selectedClan?._id === clan._id || clanJoinLoading}
              />
            ))}
            <p className={classes.description}>
              *You can change clan only once per day
            </p>
          </div>
        </>
      )}
      {selectedClan && (
        <PopoverAnimatedWrapper
          isVisible
          backgroundColor={selectedClan.coloringSchemaHEX}
        >
          <div className={classes.popover__image}>
            <img
              src={
                process.env.REACT_APP_API_BASE_URL +
                '/images/clans/logos/' +
                selectedClan.logoFilename
              }
              alt="clan logo"
            />
          </div>
          <PopoverTitle title={`Join ${selectedClan.name} Clan?`} />
          <div className={classes.popover__text__limitation}>
            <PopoverDescription description="You can change clan later, but only once per day" />
          </div>
          <div className={classes.popover__actions}>
            <Button
              type={ButtonType.PRIMARY}
              variant={ButtonVariant.GREY}
              size={ButtonSize.LARGE}
              title="CANCEL"
              onClick={() => setSelectedClan(null)}
            />
            <Button
              type={ButtonType.PRIMARY}
              variant={ButtonVariant.ORANGE}
              size={ButtonSize.LARGE}
              title="JOIN"
              onClick={onJoin}
            />
          </div>
        </PopoverAnimatedWrapper>
      )}
    </div>
  );
};

export default ClanSelection;
