import { ReactNode } from 'react';
import classes from './style.module.scss';

interface TaskWrapperProps {
  children: ReactNode;
}
const TasksWrapper = ({ children }: TaskWrapperProps) => {
  return <div className={classes.wrapper}>{children}</div>;
};

export default TasksWrapper;
