import PageDescription from '../../components/page-description';
import {
  useGetDailyTaskQuery,
  useGetPartnersTasksQuery,
  useGetTasksInfoQuery,
} from '../../services/tasks/tasksApi';
import { useGetUserQuery } from '../../services/user/userApi';
import classes from './style.module.scss';
import CuberiumTasks from './cuberium-tasks';
import CompletedTasks from './completed-tasks';
import { useEffect, useRef } from 'react';
import { hideLoader } from '../../services/loader/loaderSlice';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../services/store';
import { GtagEventType, trackGtagEvent } from '../../utils/gtagEventHandler';
import Page from '../../components/layouts/page';
import Footer from '../../components/footer';

const Earn = () => {
  const { data: user } = useGetUserQuery();
  const { data: tasks } = useGetTasksInfoQuery();
  const { data: partnerTasks } = useGetPartnersTasksQuery();
  const { data: dailyStreak } = useGetDailyTaskQuery();
  const hasSentGtag = useRef(false);

  const dispatch = useDispatch();
  const loading = useSelector((state: RootState) => state.loader.loading);

  useEffect(() => {
    if (loading) {
      setTimeout(() => {
        dispatch(hideLoader());
      }, 1500);
    }
  }, []);

  useEffect(() => {
    if (!hasSentGtag.current) {
      trackGtagEvent(GtagEventType.VISIT_EARN);
      hasSentGtag.current = true;
    }

    return () => {
      hasSentGtag.current = false;
    };
  }, []);

  return (
    <Page title="EARN">
      <PageDescription description="Сomplete tasks and earn coins" />
      <div className={classes.tasks}>
        {user && tasks && dailyStreak && partnerTasks && (
          <CuberiumTasks
            user={user}
            tasks={tasks}
            dailyStreak={dailyStreak}
            partnerTasks={partnerTasks}
          />
        )}
        {user && tasks && partnerTasks && (
          <CompletedTasks
            user={user}
            tasks={tasks}
            partnerTasks={partnerTasks}
          />
        )}
      </div>
      {user && <Footer />}
    </Page>
  );
};

export default Earn;
