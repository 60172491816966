import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AchievementRewardState {
  show: boolean;
  title: string;
  taskId: string;
  subtitle: string;
  shareMessage: string;
  shareLink: string;
  reward: number;
}

const initialState: AchievementRewardState = {
  show: false,
  title: 'LEVEL 3',
  taskId: '',
  subtitle: 'Reached',
  shareMessage: '',
  shareLink: 'https://t.me/TheTester325Bot/app?startapp=97980088344607496819',
  reward: 25000,
};

const achievementRewardSlice = createSlice({
  name: 'achievementReward',
  initialState,
  reducers: {
    showAchievementReward(
      state,
      action: PayloadAction<Partial<AchievementRewardState>>,
    ) {
      const { taskId, title, subtitle, shareMessage, shareLink, reward } =
        action.payload;
      state.show = true;
      state.taskId = taskId || state.taskId;
      state.title = title || state.title;
      state.subtitle = subtitle || state.subtitle;
      state.shareMessage = shareMessage || state.shareMessage;
      state.shareLink = shareLink || state.shareLink;
      state.reward = reward || state.reward;
    },
    hideAchievementReward(state) {
      state.show = false;
      state.title = '';
      state.taskId = '';
      state.subtitle = '';
      state.shareMessage = '';
      state.shareLink = '';
    },
  },
});

export const { showAchievementReward, hideAchievementReward } =
  achievementRewardSlice.actions;

export default achievementRewardSlice.reducer;
