import React, { useState, useEffect, ReactNode } from 'react';
import classes from './style.module.scss';

interface PopoverAnimatedWrapperProps {
  isVisible: boolean;
  children: ReactNode;
  backgroundColor?: string;
}

const PopoverAnimatedWrapper = ({
  isVisible,
  children,
  backgroundColor,
}: PopoverAnimatedWrapperProps) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (isVisible) {
      setTimeout(() => setShow(true), 10);
    } else {
      setShow(false);
    }
  }, [isVisible]);

  if (!isVisible && !show) return null;

  return (
    <div
      className={`${classes.confirmation} ${show ? classes.confirmation__show : ''}`}
    >
      <div
        className={classes.confirmation__content}
        style={{ backgroundColor }}
      >
        {children}
      </div>
    </div>
  );
};

export default PopoverAnimatedWrapper;
