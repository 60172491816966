import clsx from 'clsx';
import {
  DailyTask,
  TaskDay,
  TaskStatus,
} from '../../../../../services/tasks/tasksApi';
import classes from './style.module.scss';
import { formatCoins } from '../../../../../utils/utils';

interface CuberiumDailyTaskProps {
  task: DailyTask;
  streak: number;
  canClaim: boolean;
}

const CuberiumDailyTask = ({
  task,
  streak,
  canClaim,
}: CuberiumDailyTaskProps) => {
  return (
    <div
      className={clsx(classes.task, {
        [classes.task__active]:
          task.day === TaskDay.TODAY &&
          task.status === TaskStatus.ACTIVE &&
          canClaim,
        [classes.task__active__special]:
          task.day === TaskDay.TODAY && streak >= 6 && canClaim,
        [classes.task__completed]:
          task.day === TaskDay.YESTERDAY || task.status === TaskStatus.CLAIMED,
        [classes.task__next__active]:
          task.day === TaskDay.TOMORROW && !canClaim && streak >= 1,
        [classes.task__next__special]:
          (task.day === TaskDay.DAY_AFTER_TOMORROW && streak >= 5) ||
          (task.day === TaskDay.TOMORROW && streak >= 6) ||
          (task.day === TaskDay.TOMORROW && canClaim && streak === 5),

        [classes.task__next]:
          task.day === TaskDay.TOMORROW ||
          (task.day === TaskDay.DAY_AFTER_TOMORROW && streak < 1),
        [classes.task]: task.day === TaskDay.TODAY && !canClaim,
      })}
    >
      {task.day === TaskDay.TODAY && task.status !== TaskStatus.CLAIMED && (
        <div className={classes.task__content}>
          <h4 className={classes.task__content__day}>
            {(task.day === TaskDay.TODAY || task.day === TaskDay.TOMORROW) &&
              (streak === 5 || streak === 6) && (
                <>{streak === 6 && <i className="icon-crown"></i>}</>
              )}
            {task.title}
          </h4>
          <div className={classes.task__content__amount}>
            <img src={`${process.env.PUBLIC_URL}/assets/images/coin-m.svg`} />
            <p>{formatCoins(task.coinsReward)}</p>
          </div>
        </div>
      )}

      {(task.status === TaskStatus.CLAIMED ||
        task.day === TaskDay.YESTERDAY) && (
        <div className={classes.task__claimed}>
          <i className={`icon-check ${classes.task__claimed__icon}`}></i>
          <h4 className={classes.task__claimed__title}>{task.title}</h4>
        </div>
      )}

      {(task.day === TaskDay.TOMORROW ||
        task.day === TaskDay.DAY_AFTER_TOMORROW) && (
        <div className={classes.task__default}>
          <h4 className={classes.task__default__day}>
            {streak === 5 &&
              !canClaim &&
              task.day === TaskDay.DAY_AFTER_TOMORROW && (
                <i className="icon-crown"></i>
              )}
            {streak === 5 && canClaim && task.day === TaskDay.TOMORROW && (
              <i className="icon-crown"></i>
            )}

            {streak === 6 && !canClaim && task.day === TaskDay.TOMORROW && (
              <i className="icon-crown"></i>
            )}

            {task.title}
          </h4>
          <div className={classes.task__default__amount}>
            <img src={`${process.env.PUBLIC_URL}/assets/images/coin-m.svg`} />
            <p>{formatCoins(task.coinsReward)}</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default CuberiumDailyTask;
