import classes from './style.module.scss';
import CoinBadgeImage from '../../../../../assets/images/coin-m.svg';

interface RewardAmountProps {
  amount?: number;
}

const RewardAmount = ({ amount }: RewardAmountProps) => {
  return (
    <div className={classes.reward}>
      <span>GET</span>
      <div className={classes.reward__icon}>
        <img src={CoinBadgeImage} alt="coin icon" />
      </div>
      <span>{amount ? amount : 5000}</span>
    </div>
  );
};

export default RewardAmount;
