// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_clan__AlmSC {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 12px 8px 8px;
  border-radius: 10px;
}
.style_clan__avatar__R0bAu {
  width: 50px;
  height: 50px;
}
.style_clan__avatar__R0bAu img {
  width: 100%;
  height: 100%;
}
.style_clan__left__v-laK {
  display: flex;
  gap: 8px;
  align-items: center;
}
.style_clan__info__BGIyK {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 4px;
}
.style_clan__info__title__SliSm {
  color: rgb(255, 255, 255);
  font-weight: 700;
  font-size: 1.8rem;
  line-height: 18px;
}
.style_clan__info__members__QOyy7 {
  color: rgb(255, 255, 255);
  display: flex;
  font-size: 1.4rem;
  gap: 4px;
}`, "",{"version":3,"sources":["webpack://./src/features/clans/clan-selection/clan-item/style.module.scss","webpack://./public/assets/colors.scss"],"names":[],"mappings":"AAEA;EACE,WAAA;EACA,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,yBAAA;EACA,mBAAA;AADF;AAEE;EACE,WAAA;EACA,YAAA;AAAJ;AACI;EACE,WAAA;EACA,YAAA;AACN;AAGE;EACE,aAAA;EACA,QAAA;EACA,mBAAA;AADJ;AAIE;EACE,aAAA;EACA,sBAAA;EACA,8BAAA;EACA,QAAA;AAFJ;AAGI;EACE,yBCzBE;ED0BF,gBAAA;EACA,iBAAA;EACA,iBAAA;AADN;AAGI;EACE,yBC/BE;EDgCF,aAAA;EACA,iBAAA;EACA,QAAA;AADN","sourcesContent":["@import '../../../../../public/assets/colors';\n\n.clan {\n  width: 100%;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  padding: 8px 12px 8px 8px;\n  border-radius: 10px;\n  &__avatar {\n    width: 50px;\n    height: 50px;\n    img {\n      width: 100%;\n      height: 100%;\n    }\n  }\n\n  &__left {\n    display: flex;\n    gap: 8px;\n    align-items: center;\n  }\n\n  &__info {\n    display: flex;\n    flex-direction: column;\n    justify-content: space-between;\n    gap: 4px;\n    &__title {\n      color: $white;\n      font-weight: 700;\n      font-size: 1.8rem;\n      line-height: 18px;\n    }\n    &__members {\n      color: $white;\n      display: flex;\n      font-size: 1.4rem;\n      gap: 4px;\n    }\n  }\n}\n","$main: rgba(117, 83, 240, 1);\n$light-main: rgba(237, 235, 254, 1);\n$black-extra: rgba(27, 27, 27, 1);\n$dark-grey: rgba(135, 135, 135, 1);\n$light-background: rgba(247, 246, 255, 1);\n$white: rgba(255, 255, 255, 1);\n$light-grey: rgba(213, 212, 220, 1);\n$yellow: rgba(255, 199, 1, 1);\n$grey: rgba(158, 158, 158, 1);\n$light-green: #39b283;\n$green: #1f6b4e;\n$dark-green: #1f4c3a;\n$danger: #c10000;\n$red: #e73232;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"clan": `style_clan__AlmSC`,
	"clan__avatar": `style_clan__avatar__R0bAu`,
	"clan__left": `style_clan__left__v-laK`,
	"clan__info": `style_clan__info__BGIyK`,
	"clan__info__title": `style_clan__info__title__SliSm`,
	"clan__info__members": `style_clan__info__members__QOyy7`
};
export default ___CSS_LOADER_EXPORT___;
