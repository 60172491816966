import { useMemo } from 'react';
import classes from './style.module.scss';
import { User } from '../../../services/user/types';
import { Task } from '../../../services/tasks/tasksApi';
import EmailStrategy from './tasks/email';

interface SocialsProps {
  user: User;
  tasks: Array<Task>;
}

interface TasksStrategies {
  [key: string]: React.ComponentType<{ task: Task; user: User }>;
}

const tasksStrategies: TasksStrategies = {
  email: EmailStrategy,
};

const Socials = ({ user, tasks }: SocialsProps) => {
  const tasksWithUserCompletion = useMemo(() => {
    return tasks.map((task: Task) => {
      const userTask = user.completedTasks.find((ut) => ut.taskId === task._id);
      return {
        ...task,
        isCompleted: !!userTask,
        isClaimed: userTask ? userTask.isClaimed : false,
      };
    });
  }, [tasks, user.completedTasks]);

  return (
    <div className={classes.socials}>
      <div className={classes.tasks}>
        {tasksWithUserCompletion.map((task: Task) => {
          const Component = tasksStrategies[task.actionType];
          return Component ? (
            <div key={task._id} className={classes.task__wrapper}>
              <Component key={`${task._id}`} task={task} user={user} />
            </div>
          ) : null;
        })}
      </div>
    </div>
  );
};

export default Socials;
