import { useState } from 'react';
import {
  Task,
  TaskSurvey,
  useClaimTaskMutation,
  useCompleteTaskQuizMutation,
  useGetTasksInfoQuery,
} from '../../../../services/tasks/tasksApi';
import { TaskDetails } from '../../completed-tasks';
import { useDispatch } from 'react-redux';
import { useGetUserQuery } from '../../../../services/user/userApi';
import { showAchievement } from '../../../../services/achievement/achievementSlice';
import {
  NotificationType,
  showNotification,
} from '../../../../services/notification/notificationSlice';
import Button, {
  ButtonSize,
  ButtonType,
  ButtonVariant,
} from '../../../../components/button';
import CuberiumSurveyQuiz from './cuberium-survey-quiz';
import TaskItem from '../task-item';
import {
  GtagEventType,
  trackGtagEvent,
} from '../../../../utils/gtagEventHandler';

interface CuberiumSurveyProps {
  task: Task;
  readyForClaiming: boolean;
  details: TaskDetails;
}
const CuberiumSurvey = ({
  task,
  readyForClaiming,
  details,
}: CuberiumSurveyProps) => {
  const [viewQuiz, setViewQuiz] = useState(false);
  const dispatch = useDispatch();
  const [claimTask, { isLoading: claimLoading }] = useClaimTaskMutation();
  const [completeTask, { isLoading: completeLoading }] =
    useCompleteTaskQuizMutation();
  const { refetch } = useGetUserQuery();
  const { refetch: refetchTasks } = useGetTasksInfoQuery();

  const onHandleClaim = async () => {
    try {
      await claimTask(task._id).unwrap();
      dispatch(
        showAchievement({
          title: 'REWARD',
          amount: task.coinsReward,
        }),
      );
      await refetch();
      await refetchTasks();
    } catch (error) {
      dispatch(
        showAchievement({
          message: 'Sorry, something went wrong!',
          type: NotificationType.ERROR,
        }),
      );
    }
  };

  const handleStories = () => {
    setViewQuiz(true);
  };

  const completeQuiz = async (data: Array<string>) => {
    try {
      await completeTask({
        id: task._id,
        answers: data,
      }).unwrap();
      trackGtagEvent(GtagEventType.POLL_TASK);
      await refetch();
      await refetchTasks();
      setViewQuiz(false);
    } catch (error) {
      setViewQuiz(false);
      dispatch(
        showNotification({
          message: 'Sorry, something went wrong!',
          type: NotificationType.ERROR,
        }),
      );
    }
  };

  return (
    <TaskItem
      title={details.title}
      icon={details.icon}
      reward={task.coinsReward}
    >
      {!readyForClaiming ? (
        <Button
          type={ButtonType.PRIMARY}
          variant={ButtonVariant.PURPLE}
          size={ButtonSize.SMALL}
          isLoading={completeLoading}
          title={'OPEN'}
          onClick={handleStories}
        />
      ) : (
        <Button
          type={ButtonType.PRIMARY}
          variant={ButtonVariant.ORANGE}
          size={ButtonSize.SMALL}
          isLoading={claimLoading}
          title={'CLAIM'}
          onClick={() => {
            readyForClaiming && onHandleClaim();
          }}
        />
      )}
      {viewQuiz && (
        <CuberiumSurveyQuiz
          task={task as TaskSurvey}
          onFinish={completeQuiz}
          isLoading={completeLoading}
        />
      )}
    </TaskItem>
  );
};

export default CuberiumSurvey;
