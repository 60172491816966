// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_label__HRTQp {
  display: flex;
  align-items: center;
  gap: 4px;
  padding-right: 10px;
  overflow: hidden;
}
.style_label__HRTQp span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.style_label__HRTQp i {
  color: rgb(213, 212, 220);
}`, "",{"version":3,"sources":["webpack://./src/pages/profile/socials/components/task-label/style.module.scss","webpack://./public/assets/colors.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,mBAAA;EACA,QAAA;EACA,mBAAA;EACA,gBAAA;AADF;AAEE;EACE,mBAAA;EACA,gBAAA;EACA,uBAAA;AAAJ;AAEE;EACE,yBCRS;ADQb","sourcesContent":["@import 'public/assets/colors';\n\n.label {\n  display: flex;\n  align-items: center;\n  gap: 4px;\n  padding-right: 10px;\n  overflow: hidden;\n  span {\n    white-space: nowrap;\n    overflow: hidden;\n    text-overflow: ellipsis;\n  }\n  i {\n    color: $light-grey;\n  }\n}\n","$main: rgba(117, 83, 240, 1);\n$light-main: rgba(237, 235, 254, 1);\n$black-extra: rgba(27, 27, 27, 1);\n$dark-grey: rgba(135, 135, 135, 1);\n$light-background: rgba(247, 246, 255, 1);\n$white: rgba(255, 255, 255, 1);\n$light-grey: rgba(213, 212, 220, 1);\n$yellow: rgba(255, 199, 1, 1);\n$grey: rgba(158, 158, 158, 1);\n$light-green: #39b283;\n$green: #1f6b4e;\n$dark-green: #1f4c3a;\n$danger: #c10000;\n$red: #e73232;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"label": `style_label__HRTQp`
};
export default ___CSS_LOADER_EXPORT___;
