// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_confirmation__kfHWB {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(28, 25, 46, 0.9);
  color: white;
  text-align: center;
  padding: 10px;
  z-index: 9000;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  transition: all 0.3s ease-out;
  visibility: hidden;
  opacity: 0;
}

.style_confirmation__kfHWB.style_confirmation__show__4Zauz {
  visibility: visible;
  opacity: 1;
}

.style_confirmation__kfHWB .style_confirmation__content__KqCzZ {
  background-color: rgb(117, 83, 240);
  padding: 20px;
  border-radius: 10px;
  transform: translateY(100%);
  transition: transform 0.5s ease-out;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}

.style_confirmation__kfHWB.style_confirmation__show__4Zauz .style_confirmation__content__KqCzZ {
  transform: translateY(0);
}`, "",{"version":3,"sources":["webpack://./src/components/popovers/components/popover-animated-wrapper/style.module.scss","webpack://./public/assets/colors.scss"],"names":[],"mappings":"AAEA;EACE,eAAA;EACA,MAAA;EACA,OAAA;EACA,QAAA;EACA,SAAA;EACA,uCAAA;EACA,YAAA;EACA,kBAAA;EACA,aAAA;EACA,aAAA;EACA,aAAA;EACA,uBAAA;EACA,qBAAA;EACA,6BAAA;EACA,kBAAA;EACA,UAAA;AADF;;AAIA;EACE,mBAAA;EACA,UAAA;AADF;;AAIA;EACE,mCC3BK;ED4BL,aAAA;EACA,mBAAA;EACA,2BAAA;EACA,mCAAA;EACA,aAAA;EACA,mBAAA;EACA,sBAAA;EACA,QAAA;EACA,WAAA;AADF;;AAIA;EACE,wBAAA;AADF","sourcesContent":["@import 'public/assets/colors';\n\n.confirmation {\n  position: fixed;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  background-color: rgba(28, 25, 46, 0.9);\n  color: white;\n  text-align: center;\n  padding: 10px;\n  z-index: 9000;\n  display: flex;\n  justify-content: center;\n  align-items: flex-end;\n  transition: all 0.3s ease-out;\n  visibility: hidden;\n  opacity: 0;\n}\n\n.confirmation.confirmation__show {\n  visibility: visible;\n  opacity: 1;\n}\n\n.confirmation .confirmation__content {\n  background-color: $main;\n  padding: 20px;\n  border-radius: 10px;\n  transform: translateY(100%);\n  transition: transform 0.5s ease-out;\n  display: flex;\n  align-items: center;\n  flex-direction: column;\n  gap: 8px;\n  width: 100%;\n}\n\n.confirmation.confirmation__show .confirmation__content {\n  transform: translateY(0);\n}\n","$main: rgba(117, 83, 240, 1);\n$light-main: rgba(237, 235, 254, 1);\n$black-extra: rgba(27, 27, 27, 1);\n$dark-grey: rgba(135, 135, 135, 1);\n$light-background: rgba(247, 246, 255, 1);\n$white: rgba(255, 255, 255, 1);\n$light-grey: rgba(213, 212, 220, 1);\n$yellow: rgba(255, 199, 1, 1);\n$grey: rgba(158, 158, 158, 1);\n$light-green: #39b283;\n$green: #1f6b4e;\n$dark-green: #1f4c3a;\n$danger: #c10000;\n$red: #e73232;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"confirmation": `style_confirmation__kfHWB`,
	"confirmation__show": `style_confirmation__show__4Zauz`,
	"confirmation__content": `style_confirmation__content__KqCzZ`
};
export default ___CSS_LOADER_EXPORT___;
