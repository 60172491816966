// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_confirmation__image__Mo5Bb {
  width: 120px;
  height: 120px;
}
.style_confirmation__image__Mo5Bb img {
  width: 100%;
  height: 100%;
}

.style_confirmation__actions__JbcSe {
  display: flex;
  width: 100%;
  gap: 8px;
}`, "",{"version":3,"sources":["webpack://./src/components/confirmation/style.module.scss"],"names":[],"mappings":"AAEA;EACE,YAAA;EACA,aAAA;AADF;AAEE;EACE,WAAA;EACA,YAAA;AAAJ;;AAIA;EACE,aAAA;EACA,WAAA;EACA,QAAA;AADF","sourcesContent":["@import 'public/assets/colors';\n\n.confirmation__image {\n  width: 120px;\n  height: 120px;\n  img {\n    width: 100%;\n    height: 100%;\n  }\n}\n\n.confirmation__actions {\n  display: flex;\n  width: 100%;\n  gap: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"confirmation__image": `style_confirmation__image__Mo5Bb`,
	"confirmation__actions": `style_confirmation__actions__JbcSe`
};
export default ___CSS_LOADER_EXPORT___;
