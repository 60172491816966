// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_profile__oyPkj {
  padding: 12px 16px 100px 16px;
  width: 100vw;
  height: 100vh;
  overflow-y: auto;
  text-align: center;
  background: rgb(247, 246, 255);
  display: flex;
  flex-direction: column;
  gap: 16px;
  position: relative;
  align-items: center;
  margin: 0;
}
.style_profile__section__a6TOt {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  margin-top: 8px;
}
.style_profile__hint__ak4k6 {
  text-align: center;
  margin-top: 12px;
  color: rgb(135, 135, 135);
  font-weight: 1.4rem;
  margin-bottom: 20px;
}

.style_sound__Gmns9 {
  position: absolute;
  top: 15px;
  right: 20px;
  border-radius: 10px;
  border: 1px solid rgb(230, 230, 230);
  width: 45px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2.5rem;
  color: rgb(27, 27, 27);
}`, "",{"version":3,"sources":["webpack://./src/pages/profile/style.module.scss","webpack://./public/assets/colors.scss"],"names":[],"mappings":"AAEA;EACE,6BAAA;EACA,YAAA;EACA,aAAA;EACA,gBAAA;EACA,kBAAA;EACA,8BAAA;EACA,aAAA;EACA,sBAAA;EACA,SAAA;EACA,kBAAA;EACA,mBAAA;EACA,SAAA;AADF;AAEE;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,QAAA;EACA,eAAA;AAAJ;AAEE;EACE,kBAAA;EACA,gBAAA;EACA,yBCtBQ;EDuBR,mBAAA;EACA,mBAAA;AAAJ;;AAIA;EACE,kBAAA;EACA,SAAA;EACA,WAAA;EACA,mBAAA;EACA,oCAAA;EACA,WAAA;EACA,YAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,iBAAA;EACA,sBCzCY;ADwCd","sourcesContent":["@import 'public/assets/colors';\n\n.profile {\n  padding: 12px 16px 100px 16px;\n  width: 100vw;\n  height: 100vh;\n  overflow-y: auto;\n  text-align: center;\n  background: rgba(247, 246, 255, 1);\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n  position: relative;\n  align-items: center;\n  margin: 0;\n  &__section {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    gap: 8px;\n    margin-top: 8px;\n  }\n  &__hint {\n    text-align: center;\n    margin-top: 12px;\n    color: $dark-grey;\n    font-weight: 1.4rem;\n    margin-bottom: 20px;\n  }\n}\n\n.sound {\n  position: absolute;\n  top: 15px;\n  right: 20px;\n  border-radius: 10px;\n  border: 1px solid rgba(230, 230, 230, 1);\n  width: 45px;\n  height: 45px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  font-size: 2.5rem;\n  color: $black-extra;\n}\n","$main: rgba(117, 83, 240, 1);\n$light-main: rgba(237, 235, 254, 1);\n$black-extra: rgba(27, 27, 27, 1);\n$dark-grey: rgba(135, 135, 135, 1);\n$light-background: rgba(247, 246, 255, 1);\n$white: rgba(255, 255, 255, 1);\n$light-grey: rgba(213, 212, 220, 1);\n$yellow: rgba(255, 199, 1, 1);\n$grey: rgba(158, 158, 158, 1);\n$light-green: #39b283;\n$green: #1f6b4e;\n$dark-green: #1f4c3a;\n$danger: #c10000;\n$red: #e73232;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"profile": `style_profile__oyPkj`,
	"profile__section": `style_profile__section__a6TOt`,
	"profile__hint": `style_profile__hint__ak4k6`,
	"sound": `style_sound__Gmns9`
};
export default ___CSS_LOADER_EXPORT___;
