import React, { ReactNode } from 'react';
import classes from './style.module.scss';

interface SectionDividerProps {
  children: ReactNode;
  color?: string;
}

const SectionDivider = ({ children, color }: SectionDividerProps) => {
  const lineColor = color || 'rgba(117, 83, 240, 1)';

  return (
    <div className={classes.section__divider}>
      <div
        className={classes.horizontal__line}
        style={{ backgroundColor: lineColor }}
      >
        <span
          className={classes.lineExtension}
          style={{ backgroundColor: lineColor }}
        />
      </div>
      {children}
      <div
        className={classes.horizontal__line__reversed}
        style={{ backgroundColor: lineColor }}
      >
        <span
          className={classes.lineExtension}
          style={{ backgroundColor: lineColor }}
        />
      </div>
    </div>
  );
};

export default SectionDivider;
