// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_email__bI37p {
  padding: 12px 16px;
  width: 100vw;
  height: 100vh;
  background: rgb(247, 246, 255);
  text-align: center;
  margin: 0;
}
.style_email__section__uHUxG {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  margin-top: 8px;
  width: 100%;
}

.style_form__description__F9g5p {
  text-align: center;
  margin-top: 12px;
  color: rgb(135, 135, 135);
  font-weight: 1.4rem;
  margin-bottom: 20px;
}

.style_form__3BNbG {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.style_resend__button__9DAzw {
  background: none;
  color: rgb(117, 83, 240);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  font-size: 1.6rem;
  border: none;
  box-shadow: none;
  width: 100%;
  padding: 0;
  font-weight: 700;
}`, "",{"version":3,"sources":["webpack://./src/features/email/style.module.scss","webpack://./public/assets/colors.scss"],"names":[],"mappings":"AAEA;EACE,kBAAA;EACA,YAAA;EACA,aAAA;EACA,8BAAA;EAEA,kBAAA;EACA,SAAA;AAFF;AAGE;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,SAAA;EACA,eAAA;EACA,WAAA;AADJ;;AAKA;EACE,kBAAA;EACA,gBAAA;EACA,yBCpBU;EDqBV,mBAAA;EACA,mBAAA;AAFF;;AAKA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;AAFF;;AAKA;EACE,gBAAA;EACA,wBCpCK;EDqCL,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,QAAA;EACA,iBAAA;EACA,YAAA;EACA,gBAAA;EACA,WAAA;EACA,UAAA;EACA,gBAAA;AAFF","sourcesContent":["@import '../../../public/assets/colors';\n\n.email {\n  padding: 12px 16px;\n  width: 100vw;\n  height: 100vh;\n  background: rgba(247, 246, 255, 1);\n\n  text-align: center;\n  margin: 0;\n  &__section {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    gap: 16px;\n    margin-top: 8px;\n    width: 100%;\n  }\n}\n\n.form__description {\n  text-align: center;\n  margin-top: 12px;\n  color: $dark-grey;\n  font-weight: 1.4rem;\n  margin-bottom: 20px;\n}\n\n.form {\n  display: flex;\n  flex-direction: column;\n  gap: 12px;\n}\n\n.resend__button {\n  background: none;\n  color: $main;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  gap: 8px;\n  font-size: 1.6rem;\n  border: none;\n  box-shadow: none;\n  width: 100%;\n  padding: 0;\n  font-weight: 700;\n}\n","$main: rgba(117, 83, 240, 1);\n$light-main: rgba(237, 235, 254, 1);\n$black-extra: rgba(27, 27, 27, 1);\n$dark-grey: rgba(135, 135, 135, 1);\n$light-background: rgba(247, 246, 255, 1);\n$white: rgba(255, 255, 255, 1);\n$light-grey: rgba(213, 212, 220, 1);\n$yellow: rgba(255, 199, 1, 1);\n$grey: rgba(158, 158, 158, 1);\n$light-green: #39b283;\n$green: #1f6b4e;\n$dark-green: #1f4c3a;\n$danger: #c10000;\n$red: #e73232;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"email": `style_email__bI37p`,
	"email__section": `style_email__section__uHUxG`,
	"form__description": `style_form__description__F9g5p`,
	"form": `style_form__3BNbG`,
	"resend__button": `style_resend__button__9DAzw`
};
export default ___CSS_LOADER_EXPORT___;
