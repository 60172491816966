// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_progress__container__E8D-f {
  width: 85vw;
  background-color: rgb(213, 212, 220);
  border-radius: 10px;
  position: relative;
}

.style_progress__bar__4kxjs {
  min-width: 10px;
  height: 20px;
  background-color: rgb(255, 199, 1);
  border-radius: 10px;
}

.style_progress__numbers__xFeDy {
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.4rem;
  color: rgb(135, 135, 135);
  font-weight: 700;
  z-index: 1;
}`, "",{"version":3,"sources":["webpack://./src/components/progress-numbers/style.module.scss","webpack://./public/assets/colors.scss"],"names":[],"mappings":"AAEA;EACE,WAAA;EACA,oCCEW;EDDX,mBAAA;EACA,kBAAA;AADF;;AAIA;EACE,eAAA;EACA,YAAA;EACA,kCCLO;EDMP,mBAAA;AADF;;AAIA;EACE,kBAAA;EACA,WAAA;EACA,QAAA;EACA,SAAA;EACA,gCAAA;EACA,iBAAA;EACA,yBCpBU;EDqBV,gBAAA;EACA,UAAA;AADF","sourcesContent":["@import 'public/assets/colors';\n\n.progress__container {\n  width: 85vw;\n  background-color: $light-grey;\n  border-radius: 10px;\n  position: relative;\n}\n\n.progress__bar {\n  min-width: 10px;\n  height: 20px;\n  background-color: $yellow;\n  border-radius: 10px;\n}\n\n.progress__numbers {\n  position: absolute;\n  width: 100%;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  font-size: 1.4rem;\n  color: $dark-grey;\n  font-weight: 700;\n  z-index: 1;\n}\n","$main: rgba(117, 83, 240, 1);\n$light-main: rgba(237, 235, 254, 1);\n$black-extra: rgba(27, 27, 27, 1);\n$dark-grey: rgba(135, 135, 135, 1);\n$light-background: rgba(247, 246, 255, 1);\n$white: rgba(255, 255, 255, 1);\n$light-grey: rgba(213, 212, 220, 1);\n$yellow: rgba(255, 199, 1, 1);\n$grey: rgba(158, 158, 158, 1);\n$light-green: #39b283;\n$green: #1f6b4e;\n$dark-green: #1f4c3a;\n$danger: #c10000;\n$red: #e73232;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"progress__container": `style_progress__container__E8D-f`,
	"progress__bar": `style_progress__bar__4kxjs`,
	"progress__numbers": `style_progress__numbers__xFeDy`
};
export default ___CSS_LOADER_EXPORT___;
