import classes from './style.module.scss';

interface SectionTitleProps {
  title: string;
}
const SectionTitle = ({ title }: SectionTitleProps) => {
  return <h4 className={classes.title}>{title}</h4>;
};

export default SectionTitle;
