// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_notification__o1jpK {
  position: fixed;
  top: 15px;
  left: 50%;
  transform: translateX(-50%);
  padding: 8px;
  border-radius: 4px;
  z-index: 1000;
  width: 84vw;
  border-radius: 12px;
  font-size: 1.4rem;
  display: flex;
  text-align: left;
  gap: 6px;
}

.style_notification__success__rqp9p {
  background-color: rgb(57, 178, 131);
  color: white;
}

.style_notification__error__Xd\\+Fe {
  background-color: rgb(231, 50, 50);
  color: white;
}`, "",{"version":3,"sources":["webpack://./src/components/notification/style.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,SAAA;EACA,SAAA;EACA,2BAAA;EACA,YAAA;EACA,kBAAA;EACA,aAAA;EACA,WAAA;EACA,mBAAA;EACA,iBAAA;EACA,aAAA;EACA,gBAAA;EACA,QAAA;AACF;;AAEA;EACE,mCAAA;EACA,YAAA;AACF;;AAEA;EACE,kCAAA;EACA,YAAA;AACF","sourcesContent":[".notification {\n  position: fixed;\n  top: 15px;\n  left: 50%;\n  transform: translateX(-50%);\n  padding: 8px;\n  border-radius: 4px;\n  z-index: 1000;\n  width: 84vw;\n  border-radius: 12px;\n  font-size: 1.4rem;\n  display: flex;\n  text-align: left;\n  gap: 6px;\n}\n\n.notification__success {\n  background-color: rgba(57, 178, 131, 1);\n  color: white;\n}\n\n.notification__error {\n  background-color: rgba(231, 50, 50, 1);\n  color: white;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"notification": `style_notification__o1jpK`,
	"notification__success": `style_notification__success__rqp9p`,
	"notification__error": `style_notification__error__Xd+Fe`
};
export default ___CSS_LOADER_EXPORT___;
