import { Clan, ClanWarResult } from '../../features/clans-war/api/types';
import Ribbon from '../../features/clans/components/ribbon';
import { User } from '../../services/user/types';
import { useSeenCWResultMutation } from '../../services/user/userApi';
import { formatCoins } from '../../utils/utils';
import Button, { ButtonSize, ButtonType, ButtonVariant } from '../button';
import classes from './style.module.scss';

interface CWRewardProps {
  cwResult: ClanWarResult;
  onClose: () => void;
  user: User;
}

const CWReward = ({ cwResult, onClose, user }: CWRewardProps) => {
  const order: { [key: number]: number } = { 2: 1, 1: 2, 3: 3 };
  const [seenResult, { isLoading }] = useSeenCWResultMutation();

  const sortedClans = [...cwResult.top3clans].sort((a: Clan, b: Clan) => {
    return order[a.position] - order[b.position];
  });

  const onHandleAction = async () => {
    try {
      await seenResult().unwrap();
      onClose();
    } catch (error) {
      console.error('Failed to mark result as seen:', error);
    }
  };

  return (
    <div className={classes.content}>
      <div className={classes.banner}>
        <Ribbon title="CLAN WARS" />

        <h3 className={classes.banner__title}>WEEK {cwResult.week}</h3>
        <h2 className={classes.banner__subtitle}>WINNERS</h2>
        <div className={classes.leaderboard}>
          <div className={classes.banner__sunshine}>
            <img
              src={`${process.env.PUBLIC_URL}/assets/images/reward/rays.svg`}
            />
          </div>
          {sortedClans.map((topClan: Clan) => (
            <div className={classes.item__wrapper} key={topClan.position}>
              <div
                className={classes.item}
                style={{ backgroundColor: topClan.colorSchemaHEX }}
              >
                <div className={classes.item__trophy}>
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/prizes/prize-place-${topClan.position}.png`}
                    alt="Trophy"
                    className={classes.special}
                  />
                </div>
                <h4 className={classes.item__title}>
                  {topClan.position}
                  {topClan.position == 1 && 'st'}
                  {topClan.position == 2 && 'nd'}
                  {topClan.position == 3 && 'rd'}
                </h4>

                <img
                  className={classes.item__logo}
                  src={
                    process.env.REACT_APP_API_BASE_URL +
                    '/images/clans/logos/' +
                    topClan.logoFilename
                  }
                  alt=""
                />
                <div className={classes.item__name}>
                  <p className={classes.item__name__prefix}>Clan</p>
                  <p className={classes.item__name__content}>{topClan.name}</p>
                </div>
                <div className={classes.item__bottom__angle}></div>
              </div>
            </div>
          ))}
        </div>

        {cwResult.rewardCoins ? (
          <div className={classes.reward}>
            <div className={classes.reward__amount}>
              <h3 className={classes.reward__amount__title}>YOUR REWARD</h3>

              <div className={classes.reward__amount__content}>
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/coin-m.svg`}
                  alt="coin icon"
                />
                {formatCoins(cwResult.rewardCoins)}
              </div>
            </div>
            <p className={classes.reward__description}>
              {cwResult.rewardExplanation
                ? cwResult.rewardExplanation
                : 'Congratulations'}
            </p>
          </div>
        ) : (
          <div className={classes.empty}>
            <h3 className={classes.empty__title}>Dumn...</h3>
            <p className={classes.empty__description}>
              {user.clan.clanId ? (
                <>
                  You have missed clan rewards this week because you did not
                  earn any points, buddy.
                </>
              ) : (
                <>
                  You have missed clan rewards this week because you did not
                  enter any clan, buddy.
                </>
              )}
            </p>
          </div>
        )}
      </div>

      <div className={classes.action__wrapper}>
        <div className={classes.action}>
          <Button
            isLoading={isLoading}
            type={ButtonType.PRIMARY}
            variant={ButtonVariant.ORANGE}
            size={ButtonSize.LARGE}
            title={cwResult.rewardCoins ? 'CLAIM REWARD' : 'CONTINUE'}
            onClick={onHandleAction}
          />
        </div>
      </div>
    </div>
  );
};

export default CWReward;
