import { useState } from 'react';
import { TaskStories } from '../../../../../services/tasks/tasksApi';
import classes from './style.module.scss';
import {
  GtagEventType,
  trackGtagEvent,
} from '../../../../../utils/gtagEventHandler';

interface CuberiumStoriesStepperProps {
  task: TaskStories;
  onFinish: () => void;
}

const CuberiumStoriesStepper = ({
  task,
  onFinish,
}: CuberiumStoriesStepperProps) => {
  const [currentStep, setCurrentStep] = useState(0);

  const toggleStep = (event: React.MouseEvent<HTMLDivElement>) => {
    const isRightSide = event.clientX > window.innerWidth / 2;

    if (isRightSide) {
      if (currentStep === task.data.length - 1) {
        trackGtagEvent(GtagEventType.STORY_TASK);
        onFinish();
      } else {
        setCurrentStep(currentStep + 1);
      }
    } else {
      if (currentStep > 0) {
        setCurrentStep(currentStep - 1);
      }
    }
  };

  return (
    <div
      className={classes.steps__container}
      style={{
        height: '100vh',
        width: '100vw',
        backgroundSize: '100%',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      }}
      onClick={toggleStep}
    >
      <div
        style={{
          backgroundImage: `url(${process.env.REACT_APP_API_BASE_URL + '/images/' + task.data[currentStep].image})`,
          height: '100%',
          width: '100%',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
        }}
        className={classes.stepper__background}
      >
        <div className={classes.stepper}>
          {task.data.map((step, index) => (
            <div
              key={index}
              className={`${classes.step} ${index <= currentStep ? classes.active : ''}`}
            ></div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CuberiumStoriesStepper;
