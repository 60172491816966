// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_wrapper__Bu6ab {
  padding: 8px 16px;
  width: 100%;
  border: 2px solid rgb(117, 83, 240);
  background: #edebfe;
  border-radius: 20px;
  margin-top: 16px;
  margin-bottom: 16px;
}`, "",{"version":3,"sources":["webpack://./src/pages/earn/tasks/tasks-wrapper/style.module.scss"],"names":[],"mappings":"AAEA;EACE,iBAAA;EACA,WAAA;EACA,mCAAA;EACA,mBAAA;EACA,mBAAA;EACA,gBAAA;EACA,mBAAA;AADF","sourcesContent":["@import 'public/assets/colors';\n\n.wrapper {\n  padding: 8px 16px;\n  width: 100%;\n  border: 2px solid $main;\n  background: #edebfe;\n  border-radius: 20px;\n  margin-top: 16px;\n  margin-bottom: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `style_wrapper__Bu6ab`
};
export default ___CSS_LOADER_EXPORT___;
