// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_item__-RV\\+n {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
}
.style_item__part__K2edO {
  font-weight: 400;
  font-size: 1.4rem;
  color: rgb(27, 27, 27);
  flex: 1 1;
  justify-content: flex-start;
  display: flex;
  gap: 8px;
}
.style_item__part__right__dHMsY {
  display: flex;
  flex: 1 1;
  justify-content: flex-end;
  color: rgb(27, 27, 27);
}`, "",{"version":3,"sources":["webpack://./src/components/statistics/statistics-item/style.module.scss","webpack://./public/assets/colors.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,YAAA;AADF;AAEE;EACE,gBAAA;EACA,iBAAA;EACA,sBCRU;EDSV,SAAA;EACA,2BAAA;EACA,aAAA;EACA,QAAA;AAAJ;AACI;EACE,aAAA;EACA,SAAA;EACA,yBAAA;EACA,sBCjBQ;ADkBd","sourcesContent":["@import 'public/assets/colors';\n\n.item {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  padding: 8px;\n  &__part {\n    font-weight: 400;\n    font-size: 1.4rem;\n    color: $black-extra;\n    flex: 1;\n    justify-content: flex-start;\n    display: flex;\n    gap: 8px;\n    &__right {\n      display: flex;\n      flex: 1;\n      justify-content: flex-end;\n      color: $black-extra;\n    }\n  }\n}\n","$main: rgba(117, 83, 240, 1);\n$light-main: rgba(237, 235, 254, 1);\n$black-extra: rgba(27, 27, 27, 1);\n$dark-grey: rgba(135, 135, 135, 1);\n$light-background: rgba(247, 246, 255, 1);\n$white: rgba(255, 255, 255, 1);\n$light-grey: rgba(213, 212, 220, 1);\n$yellow: rgba(255, 199, 1, 1);\n$grey: rgba(158, 158, 158, 1);\n$light-green: #39b283;\n$green: #1f6b4e;\n$dark-green: #1f4c3a;\n$danger: #c10000;\n$red: #e73232;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"item": `style_item__-RV+n`,
	"item__part": `style_item__part__K2edO`,
	"item__part__right": `style_item__part__right__dHMsY`
};
export default ___CSS_LOADER_EXPORT___;
