// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_description__3pzOn {
  text-align: center;
  color: rgb(135, 135, 135);
  font-size: 14px;
  font-weight: 400;
  display: flex;
  gap: 4px;
}
.style_description__3pzOn img {
  width: 14px;
  height: 14px;
}`, "",{"version":3,"sources":["webpack://./src/components/page-description/style.module.scss","webpack://./public/assets/colors.scss"],"names":[],"mappings":"AAEA;EACE,kBAAA;EACA,yBCDU;EDEV,eAAA;EACA,gBAAA;EACA,aAAA;EACA,QAAA;AADF;AAEE;EACE,WAAA;EACA,YAAA;AAAJ","sourcesContent":["@import 'public/assets/colors';\n\n.description {\n  text-align: center;\n  color: $dark-grey;\n  font-size: 14px;\n  font-weight: 400;\n  display: flex;\n  gap: 4px;\n  img {\n    width: 14px;\n    height: 14px;\n  }\n}\n","$main: rgba(117, 83, 240, 1);\n$light-main: rgba(237, 235, 254, 1);\n$black-extra: rgba(27, 27, 27, 1);\n$dark-grey: rgba(135, 135, 135, 1);\n$light-background: rgba(247, 246, 255, 1);\n$white: rgba(255, 255, 255, 1);\n$light-grey: rgba(213, 212, 220, 1);\n$yellow: rgba(255, 199, 1, 1);\n$grey: rgba(158, 158, 158, 1);\n$light-green: #39b283;\n$green: #1f6b4e;\n$dark-green: #1f4c3a;\n$danger: #c10000;\n$red: #e73232;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"description": `style_description__3pzOn`
};
export default ___CSS_LOADER_EXPORT___;
