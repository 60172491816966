import React, { useEffect, useRef } from 'react';
import gsap from 'gsap';

const Coin = ({ image, index }: any) => {
  const coinRef = useRef(null);

  useEffect(() => {
    const screenHeight = window.innerHeight;
    const startTopPosition = -50;

    gsap.set(coinRef.current, {
      y: startTopPosition,
    });

    gsap.to(coinRef.current, {
      y: screenHeight + 50,
      ease: 'power1.out',
      repeat: -1,
      duration: Math.random() * 2 + 2,
      delay: index * 0.1,
    });
  }, [index]);

  return (
    <div
      ref={coinRef}
      style={{
        position: 'absolute',
        left: Math.random() * window.innerWidth,
      }}
    >
      <img src={image} alt="coin" style={{ height: '25px', width: '25px' }} />
    </div>
  );
};

export default Coin;
