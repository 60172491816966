import classes from './style.module.scss';
import { ClanWar, DefaultClan, TopClan } from '../../api/types';
import SectionDivider from '../../../../components/section-divider';
import SectionTitle from '../../../../components/section-title';
import ClanWarLeader from './clan-war-leader';
import { formatCoins } from '../../../../utils/utils';
import { User } from '../../../../services/user/types';
import clsx from 'clsx';

interface ClanWarLeaderboardProps {
  user: User;
  clanWar: ClanWar;
}

const ClanWarLeaderboard = ({ clanWar, user }: ClanWarLeaderboardProps) => {
  return (
    <div className={classes.wrapper}>
      <SectionDivider>
        <SectionTitle title="LEADERBOARD" />
      </SectionDivider>
      <div className={classes.leaderboard}>
        {clanWar.top3clans.map((topClan: TopClan) => (
          <ClanWarLeader topClan={topClan} key={topClan.clan.position} />
        ))}
      </div>
      {clanWar.otherClans.length > 0 && (
        <div className={classes.positions}>
          {clanWar.otherClans.map((clan: DefaultClan) => (
            <div
              key={clan.clan.clanId}
              className={clsx(classes.clan, {
                [classes.clan__active]:
                  user.clan.clanId && clan.clan.clanId === user.clan.clanId,
              })}
            >
              <div className={classes.clan__left}>
                <h4
                  className={clsx(classes.clan__position, {
                    [classes.clan__position__active]:
                      user.clan.clanId && clan.clan.clanId === user.clan.clanId,
                  })}
                >
                  {clan.clan.position}th
                </h4>
                <div className={classes.clan__content}>
                  <img
                    className={classes.clan__content__image}
                    src={
                      process.env.REACT_APP_API_BASE_URL +
                      '/images/clans/logos/' +
                      clan.clan.logoFilename
                    }
                  ></img>
                  <div className={classes.clan__content__text}>
                    <h3
                      className={clsx(classes.clan__content__title, {
                        [classes.clan__content__title__active]:
                          user.clan.clanId &&
                          clan.clan.clanId === user.clan.clanId,
                      })}
                    >
                      {clan.clan.name}
                    </h3>
                    <p
                      className={clsx(classes.clan__members, {
                        [classes.clan__members__active]:
                          user.clan.clanId &&
                          clan.clan.clanId === user.clan.clanId,
                      })}
                    >
                      <i className="icon-person"></i>
                      {clan.members}
                    </p>
                  </div>
                </div>
              </div>
              <div
                className={clsx(classes.clan__right, {
                  [classes.clan__right__active]:
                    user.clan.clanId && clan.clan.clanId === user.clan.clanId,
                })}
              >
                {formatCoins(clan.clan.points)}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ClanWarLeaderboard;
