// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_input__container__dcz-G {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
}

.style_input__field__BdKtH {
  width: 100%;
  padding: 10px;
  border: 2px solid rgb(117, 83, 240);
  border-radius: 12px;
  outline: none;
  transition: border-color 0.3s;
  color: rgba(28, 25, 46, 0.5);
  font-size: 1.6rem;
  font-weight: 500;
  background: rgb(237, 235, 254);
}

.style_input__field__BdKtH:focus {
  border-color: rgb(117, 83, 240);
  color: rgb(27, 27, 27);
}

.style_input__field__BdKtH.style_input__error__KWwQZ {
  border-color: rgb(231, 50, 50);
}

.style_error__message__\\+8iEi {
  color: rgb(231, 50, 50);
  font-size: 0.8rem;
  padding-top: 5px;
}`, "",{"version":3,"sources":["webpack://./src/components/input/style.module.scss","webpack://./public/assets/colors.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,sBAAA;EACA,WAAA;EACA,uBAAA;AADF;;AAIA;EACE,WAAA;EACA,aAAA;EACA,mCAAA;EACA,mBAAA;EACA,aAAA;EACA,6BAAA;EACA,4BAAA;EACA,iBAAA;EACA,gBAAA;EACA,8BAAA;AADF;;AAIA;EACE,+BCvBK;EDwBL,sBCtBY;ADqBd;;AAIA;EACE,8BAAA;AADF;;AAIA;EACE,uBAAA;EACA,iBAAA;EACA,gBAAA;AADF","sourcesContent":["@import 'public/assets/colors';\n\n.input__container {\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  align-items: flex-start;\n}\n\n.input__field {\n  width: 100%;\n  padding: 10px;\n  border: 2px solid $main; // Default border color\n  border-radius: 12px;\n  outline: none;\n  transition: border-color 0.3s;\n  color: rgba(28, 25, 46, 0.5);\n  font-size: 1.6rem;\n  font-weight: 500;\n  background: rgba(237, 235, 254, 1);\n}\n\n.input__field:focus {\n  border-color: $main; // Focus color\n  color: $black-extra;\n}\n\n.input__field.input__error {\n  border-color: rgba(231, 50, 50, 1); // Error color when there is an error\n}\n\n.error__message {\n  color: rgba(231, 50, 50, 1);\n  font-size: 0.8rem;\n  padding-top: 5px;\n}\n","$main: rgba(117, 83, 240, 1);\n$light-main: rgba(237, 235, 254, 1);\n$black-extra: rgba(27, 27, 27, 1);\n$dark-grey: rgba(135, 135, 135, 1);\n$light-background: rgba(247, 246, 255, 1);\n$white: rgba(255, 255, 255, 1);\n$light-grey: rgba(213, 212, 220, 1);\n$yellow: rgba(255, 199, 1, 1);\n$grey: rgba(158, 158, 158, 1);\n$light-green: #39b283;\n$green: #1f6b4e;\n$dark-green: #1f4c3a;\n$danger: #c10000;\n$red: #e73232;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input__container": `style_input__container__dcz-G`,
	"input__field": `style_input__field__BdKtH`,
	"input__error": `style_input__error__KWwQZ`,
	"error__message": `style_error__message__+8iEi`
};
export default ___CSS_LOADER_EXPORT___;
