import * as THREE from 'three';

export interface Monster extends THREE.Object3D {
  isExploding?: boolean;
  phaseShift?: number;
}

export const initializeCamera = (ratio: number) => {
  const camera = new THREE.PerspectiveCamera(
    7.5,
    window.innerWidth / window.innerHeight,
    1,
    1000,
  );
  if (ratio < 0.58) {
    camera.position.set(-130, 100, 1);
  } else {
    camera.position.set(-120, 70, 1);
  }
  camera.lookAt(new THREE.Vector3(0, 0, 0));
  return camera;
};

export const updateMaterialOpacity = (
  object: THREE.Object3D,
  progress: number,
) => {
  object.traverse((child: THREE.Object3D) => {
    if (child instanceof THREE.Mesh) {
      if (child.material) {
        child.material.opacity = 1 - progress;
        child.material.transparent = true;
        child.material.needsUpdate = true;
      }
    }
  });
  if (object instanceof THREE.Sprite && object.material) {
    object.material.opacity = 1 - progress;
    object.material.transparent = true;
    object.material.needsUpdate = true;
  }
};

export const createSunlight = (x: number, y: number, z: number) => {
  const sunlight = new THREE.DirectionalLight(0xffffff, 1.5);
  sunlight.position.set(x, y, z);
  sunlight.castShadow = false;
  sunlight.shadow.mapSize.width = 512;
  sunlight.shadow.mapSize.height = 512;
  sunlight.shadow.camera.near = 0.5;
  sunlight.shadow.camera.far = 500;
  sunlight.shadow.camera.left = -50;
  sunlight.shadow.camera.right = 50;
  sunlight.shadow.camera.top = 50;
  sunlight.shadow.camera.bottom = -50;

  return sunlight;
};

export const killMonster = (
  monsters: any[],
  tower: THREE.Object3D | undefined,
  shootWeapon: (target: THREE.Vector3) => void,
  animateMonsterExplosion: (monster: any) => void,
) => {
  const visibleMonsters = monsters.filter((monster) => monster.visible);
  if (visibleMonsters.length > 0) {
    let closestMonster = visibleMonsters[0];
    let minDistance = closestMonster.position.distanceTo(
      tower?.position || new THREE.Vector3(),
    );

    visibleMonsters.forEach((monster) => {
      const distance = monster.position.distanceTo(
        tower?.position || new THREE.Vector3(),
      );
      if (distance < minDistance) {
        closestMonster = monster;
        minDistance = distance;
      }
    });

    shootWeapon(closestMonster.position);

    setTimeout(() => {
      animateMonsterExplosion(closestMonster);
    }, 500);
  }
};

export const playAudio = (src: string): Promise<void> => {
  return new Promise((resolve, reject) => {
    const audio = new Audio(src);
    audio
      .play()
      .then(() => {
        resolve();
      })
      .catch((error) => {
        console.log('Audio playback failed:', error);
        reject(error);
      });
  });
};
export const calculateAndroidGraphic = (value: number) => {
  const percentageValue = value * 0.7;
  const roundedValue = Math.round(percentageValue * 100) / 100;

  return roundedValue;
};

export const showCoin = (
  scene: THREE.Scene,
  position: THREE.Vector3,
  amount: number,
  coinModel: THREE.Object3D | undefined,
) => {
  if (coinModel) {
    const coin = coinModel.clone();
    coin.position.copy(position);

    coin.traverse((child) => {
      if (child instanceof THREE.Mesh) {
        child.material = child.material.clone();
        child.material.transparent = true;
        child.material.opacity = 1;
        child.material.needsUpdate = true;
      }
    });

    scene.add(coin);

    // Create +1 text sprite
    const createTextSprite = (message: string) => {
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      if (!context) {
        throw new Error('Failed to get 2D context');
      }

      // Set canvas size and font
      canvas.width = 256;
      canvas.height = 128;
      context.font = 'Bold 36px Arial';
      context.fillStyle = 'white';
      context.textAlign = 'center';
      context.textBaseline = 'middle';
      context.fillText(message, canvas.width / 2, canvas.height / 2);

      const texture = new THREE.CanvasTexture(canvas);
      const spriteMaterial = new THREE.SpriteMaterial({
        map: texture,
        transparent: true,
        opacity: 1,
      });
      const sprite = new THREE.Sprite(spriteMaterial);
      sprite.scale.set(5, 2.5, 1.0);

      return sprite;
    };

    const textSprite = createTextSprite(`+${amount}`);

    textSprite.position.copy(position);
    textSprite.position.x += 0.5;
    textSprite.position.z -= amount < 10 ? 1 : 1.5;
    scene.add(textSprite);

    const riseDuration = 1000;
    const riseHeight = 1;
    const startTime = performance.now();

    const animateCoin = () => {
      const elapsedTime = performance.now() - startTime;
      const progress = elapsedTime / riseDuration;
      const delta = riseHeight * progress;

      if (progress < 1) {
        coin.position.y = position.y + delta;
        textSprite.position.y = position.y + delta;
        coin.rotation.y += 0.05;

        updateMaterialOpacity(coin, progress);
        updateMaterialOpacity(textSprite, progress);

        requestAnimationFrame(animateCoin);
      } else {
        scene.remove(coin);
        scene.remove(textSprite);

        coin.traverse((child) => {
          if (child instanceof THREE.Mesh && child.geometry) {
            child.geometry.dispose();
          }
          if (child instanceof THREE.Mesh && child.material) {
            child.material.dispose();
          }
        });
        if (textSprite.material) {
          textSprite.material.map?.dispose();
          textSprite.material.dispose();
        }
      }
    };

    requestAnimationFrame(animateCoin);
  }
};
