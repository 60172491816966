import { createSlice } from '@reduxjs/toolkit';
import ROUTES from '../../utils/routes';

const initialState = {
  route: ROUTES.HOME,
};

const navigationSlice = createSlice({
  name: 'navigation',
  initialState,
  reducers: {
    setNavigation: (state, action) => {
      state.route = action.payload;
    },
  },
});

export const { setNavigation } = navigationSlice.actions;
export default navigationSlice.reducer;
