import classes from './style.module.scss';
import CoinBadgeImage from '../../assets/images/coin-m.svg';
import { formatCoins, roundAmount } from '../../utils/utils';

interface SmallBalanceProps {
  amount: number;
  isWhite?: boolean;
}

const SmallBalance = ({ amount, isWhite }: SmallBalanceProps) => {
  const roundedAmount = roundAmount(amount);

  return (
    <div className={classes.balance}>
      <div className={classes.balance__amount}>
        <div className={classes.balance__icon}>
          <img src={CoinBadgeImage} alt="coin icon" />
        </div>
        <h4
          className={`${classes.balance__value} ${isWhite && classes.balance__value__white}`}
        >
          {formatCoins(roundedAmount)}
        </h4>
      </div>
    </div>
  );
};

export default SmallBalance;
