import { ReactNode } from 'react';
import PageTitle from '../../page-title';
import classes from './style.module.scss';

interface PageWrapperProps {
  title: string;
  children: ReactNode;
}
const Page = ({ children, title }: PageWrapperProps) => {
  return (
    <div className={classes.page}>
      <PageTitle title={title} />
      {children}
    </div>
  );
};

export default Page;
