import clsx from 'clsx';
import classes from './style.module.scss';
import { ReactNode } from 'react';

export enum ButtonType {
  PRIMARY = 'primary',
  OUTLINED = 'outlined',
  TEXT = 'text',
}

export enum ButtonVariant {
  ORANGE = 'orange',
  GREY = 'grey',
  PURPLE = 'purple',
  BLACK = 'black',
  GREEN = 'green',
}

export enum ButtonSize {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
}

interface ButtonAltProps {
  type: ButtonType;
  variant: ButtonVariant;
  title: string;
  size: ButtonSize;
  icon?: string;
  onClick?: () => void;
  isSubmit?: boolean;
  isLoading?: boolean;
  isDisabled?: boolean;
  children?: ReactNode;
}

const Button = ({
  type,
  variant,
  title,
  size,
  icon,
  onClick,
  isSubmit,
  isLoading,
  isDisabled,
  children,
}: ButtonAltProps) => {
  return (
    <button
      className={clsx(
        classes.button,
        classes[`button-${type}`],
        classes[`button-${variant}`],
        classes[`button-${size}`],
        { [classes.loading]: isLoading },
        { [classes.button__disabled]: isDisabled },
      )}
      type={isSubmit ? 'submit' : 'button'}
      onClick={onClick}
      disabled={isLoading}
    >
      {isLoading ? (
        <div className={classes.spinner}></div>
      ) : (
        <>
          {icon && <i className={`${classes.button__icon} icon-${icon}`} />}
          {children ? children : <span>{title}</span>}
        </>
      )}
    </button>
  );
};

export default Button;
