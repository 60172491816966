import { useNavigate } from 'react-router-dom';
import { User } from '../../services/user/types';
import { getColorForInitial, getInitial } from '../../utils/utils';
import classes from './style.module.scss';
import ROUTES from '../../utils/routes';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useDispatch } from 'react-redux';
import {
  NotificationType,
  showNotification,
} from '../../services/notification/notificationSlice';

interface UserInfoBadgeProps {
  user: User;
}

const UserInfoBadge = ({ user }: UserInfoBadgeProps) => {
  const dispatch = useDispatch();
  const handleCopy = () => {
    dispatch(
      showNotification({
        message: 'Copied!',
        type: NotificationType.SUCCESS,
      }),
    );
  };

  const navigate = useNavigate();
  return (
    <div className={classes.wrapper}>
      <div className={classes.avatar} onClick={() => navigate(ROUTES.PROFILE)}>
        <p
          style={{
            color: getColorForInitial(user.telegramUsername),
            fontSize: '26px',
            fontWeight: 600,
          }}
        >
          {getInitial(user.telegramUsername)}
        </p>
      </div>

      <h4 className={classes.username}>{user.telegramUsername}</h4>
      <CopyToClipboard text={user.uid} onCopy={handleCopy}>
        <h5 className={classes.uniqueId}>
          id: {user.uid}
          <i className="icon-copy" />
        </h5>
      </CopyToClipboard>

      <p className={classes.level}>LEVEL {user.level}</p>
    </div>
  );
};

export default UserInfoBadge;
