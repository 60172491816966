import classes from './style.module.scss';

interface TaskLabelProps {
  label: string;
  icon: string;
}

const TaskLabel = ({ label, icon }: TaskLabelProps) => {
  return (
    <div className={classes.label}>
      <i className={`icon-${icon}`} aria-hidden="true"></i>
      <span title={label}>{label}</span>
    </div>
  );
};

export default TaskLabel;
