// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_confirmation__image__bKcSD {
  width: 100px;
  height: 100px;
}
.style_confirmation__image__bKcSD img {
  width: 100%;
  height: 100%;
}

.style_confirmation__price__KVSdl {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
  margin-bottom: 8px;
}
.style_confirmation__price__KVSdl p {
  color: rgb(255, 255, 255);
  font-size: 20px;
  font-weight: 600;
  font-size: 2rem;
}
.style_confirmation__price__KVSdl img {
  width: 20px;
  height: 20px;
}

.style_confirmation__actions__RjLUL {
  display: flex;
  gap: 8px;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/features/boosts/components/boost-item/boost-confirmation/style.module.scss","webpack://./public/assets/colors.scss"],"names":[],"mappings":"AAEA;EACE,YAAA;EACA,aAAA;AADF;AAEE;EACE,WAAA;EACA,YAAA;AAAJ;;AAIA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,QAAA;EACA,kBAAA;AADF;AAEE;EACE,yBCbI;EDcJ,eAAA;EACA,gBAAA;EACA,eAAA;AAAJ;AAGE;EACE,WAAA;EACA,YAAA;AADJ;;AAKA;EACE,aAAA;EACA,QAAA;EACA,WAAA;AAFF","sourcesContent":["@import '../../../../../../public/assets/colors';\n\n.confirmation__image {\n  width: 100px;\n  height: 100px;\n  img {\n    width: 100%;\n    height: 100%;\n  }\n}\n\n.confirmation__price {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  gap: 6px;\n  margin-bottom: 8px;\n  p {\n    color: $white;\n    font-size: 20px;\n    font-weight: 600;\n    font-size: 2rem;\n  }\n\n  img {\n    width: 20px;\n    height: 20px;\n  }\n}\n\n.confirmation__actions {\n  display: flex;\n  gap: 8px;\n  width: 100%;\n}\n","$main: rgba(117, 83, 240, 1);\n$light-main: rgba(237, 235, 254, 1);\n$black-extra: rgba(27, 27, 27, 1);\n$dark-grey: rgba(135, 135, 135, 1);\n$light-background: rgba(247, 246, 255, 1);\n$white: rgba(255, 255, 255, 1);\n$light-grey: rgba(213, 212, 220, 1);\n$yellow: rgba(255, 199, 1, 1);\n$grey: rgba(158, 158, 158, 1);\n$light-green: #39b283;\n$green: #1f6b4e;\n$dark-green: #1f4c3a;\n$danger: #c10000;\n$red: #e73232;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"confirmation__image": `style_confirmation__image__bKcSD`,
	"confirmation__price": `style_confirmation__price__KVSdl`,
	"confirmation__actions": `style_confirmation__actions__RjLUL`
};
export default ___CSS_LOADER_EXPORT___;
