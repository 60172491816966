import classes from './style.module.scss';

interface PageDescriptionProps {
  description: string;
  withCoin?: boolean;
}

const PageDescription = ({ description, withCoin }: PageDescriptionProps) => {
  return (
    <h3 className={classes.description}>
      {withCoin && (
        <img src={`${process.env.PUBLIC_URL}/assets/images/coin-m.svg`} />
      )}
      {description}
    </h3>
  );
};

export default PageDescription;
