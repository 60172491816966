import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReauth } from '../../../services/baseQuery';
import { Boom, GameEvents } from './types';

const BASE_URL = '/game';

export const gameApi = createApi({
  reducerPath: 'gameApi',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['user', 'tasks', 'gameEvents'],
  endpoints: (builder) => ({
    claimVault: builder.mutation<void, void>({
      query: () => ({
        url: `${BASE_URL}/vault_claim`,
        method: 'PATCH',
      }),
      invalidatesTags: ['user', 'tasks'],
    }),

    getGameEvents: builder.query<GameEvents, void>({
      query: () => ({
        url: `${BASE_URL}/events/today_count`,
      }),
      providesTags: ['gameEvents'],
    }),

    boom: builder.mutation<Boom, void>({
      query: () => ({
        url: `${BASE_URL}/events/boom`,
        method: 'PATCH',
      }),
      invalidatesTags: ['gameEvents'],
    }),

    killBoss: builder.mutation<Boom, void>({
      query: () => ({
        url: `${BASE_URL}/events/kill_boss`,
        method: 'PATCH',
      }),
      invalidatesTags: ['gameEvents'],
    }),

    startTapGame: builder.mutation<void, void>({
      query: () => ({
        url: `${BASE_URL}/events/start_tap_game`,
        method: 'PATCH',
      }),
      invalidatesTags: ['gameEvents'],
    }),
    claimTapGame: builder.mutation<void, { streak: number }>({
      query: (payload) => ({
        url: `${BASE_URL}/events/claim_tap_game`,
        body: {
          streak: payload.streak,
        },
        method: 'PATCH',
      }),
      invalidatesTags: ['gameEvents'],
    }),
  }),
});

export const {
  useClaimVaultMutation,
  useGetGameEventsQuery,
  useBoomMutation,
  useKillBossMutation,
  useStartTapGameMutation,
  useClaimTapGameMutation,
} = gameApi;
