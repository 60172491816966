import classes from './style.module.scss';

interface StatisticsTextProps {
  title: string;
  icon?: string;
}

const StatisticsText = ({ title, icon }: StatisticsTextProps) => {
  return (
    <p className={classes.item}>
      {icon && <i className={`icon-${icon}`}></i>}
      {title}
    </p>
  );
};

export default StatisticsText;
