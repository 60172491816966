import classes from './style.module.scss';

interface PaginationProps {
  page: number;
  totalPages: number;
  hasPrevPage: boolean;
  hasNextPage: boolean;
  onPageChange: (value: number) => void;
}
const Pagination = ({
  page,
  totalPages,
  hasPrevPage,
  hasNextPage,
  onPageChange,
}: PaginationProps) => {
  return (
    <div className={classes.pagination}>
      <button
        className={`${classes.pagination__button} ${!hasPrevPage && classes.pagination__button__disabled}`}
        onClick={() => onPageChange(page - 1)}
        disabled={!hasPrevPage}
      >
        <i className="icon-prev"></i>
      </button>
      <p className={classes.pagination__text}>
        PAGE {page} OF {totalPages}
      </p>
      <button
        className={`${classes.pagination__button} ${!hasNextPage && classes.pagination__button__disabled}`}
        onClick={() => onPageChange(page + 1)}
        disabled={!hasNextPage}
      >
        <i className="icon-next"></i>
      </button>
    </div>
  );
};

export default Pagination;
