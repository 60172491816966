import React from 'react';
import Coin from '../coin';

const coins = [
  `${process.env.PUBLIC_URL}/assets/images/coins/rotated_coin.svg`,
  `${process.env.PUBLIC_URL}/assets/images/coins/rotated_coin_2.svg`,
  `${process.env.PUBLIC_URL}/assets/images/coins/rotated_coin_3.svg`,
  `${process.env.PUBLIC_URL}/assets/images/coins/rotated_coin_4.svg`,
  `${process.env.PUBLIC_URL}/assets/images/coins/rotated_coin_5.svg`,
];

const CoinRain = () => {
  return (
    <div>
      {Array.from({ length: 30 }).map((_, index) => (
        <Coin
          key={index}
          index={index}
          image={coins[Math.floor(Math.random() * coins.length)]}
        />
      ))}
    </div>
  );
};

export default CoinRain;
