import Button, {
  ButtonSize,
  ButtonType,
  ButtonVariant,
} from '../../../../components/button';
import {
  Task,
  TaskActionType,
  TaskSocialNetworkType,
  useClaimTaskMutation,
  useGetTasksInfoQuery,
} from '../../../../services/tasks/tasksApi';
import { TaskDetails } from '../../completed-tasks';
import { useDispatch } from 'react-redux';
import { showAchievement } from '../../../../services/achievement/achievementSlice';
import { useGetUserQuery } from '../../../../services/user/userApi';
import TaskItem from '../task-item';
import { showAchievementReward } from '../../../../services/achievement-reward/achievementRewardSlice';
import { User } from '../../../../services/user/types';
import { Howl } from 'howler';
import { trackGtagEvent } from '../../../../utils/gtagEventHandler';

interface CuberiumInAppProps {
  user: User;
  task: Task;
  isCompleted: boolean;
  isDisabled?: boolean;
  details: TaskDetails;
  referralLink: string;
  isPartner?: boolean;
}

const CuberiumInApp = ({
  user,
  task,
  isCompleted,
  details,
  isDisabled,
  referralLink,
  isPartner,
}: CuberiumInAppProps) => {
  const dispatch = useDispatch();
  const [claimTask, { isLoading: claimLoading }] = useClaimTaskMutation();
  const { refetch } = useGetUserQuery();
  const { refetch: refetchTasks } = useGetTasksInfoQuery();

  const handleClaim = async (task: Task) => {
    if (task.socialNetworkType === TaskSocialNetworkType.IN_APP) {
      try {
        await claimTask(task._id).unwrap();
        if (task.actionType === TaskActionType.LEVEL_UP) {
          dispatch(
            showAchievementReward({
              taskId: task._id,
              title: `LEVEL ${task.title}`,
              subtitle: 'Reached',
              shareMessage: `🏅 Cuberium New Achievement! \nLEVEL ${task.title} done 🏁\nNow more coins can be farmed  🤑🤑🤑\n\ntap-tap... tap-tap...\n\nStart farming with me, Dudes! \nJoin @Cuberium now and get 1000 coins 💰\n`,
              shareLink: referralLink,
              reward: task.coinsReward,
            }),
          );
        } else if (task.actionType === TaskActionType.REFERRAL_QUANTITY) {
          dispatch(
            showAchievementReward({
              taskId: task._id,
              title: `Referrals ${task.title}`,
              subtitle: 'Reached',
              shareMessage: `🏅 Cuberium New Achievement! \nReached ${task.title} referrals at Cuberium Defence! 🥳\nMe + My folks = 🤑🤑🤑\n\ntap-tap... tap-tap...\n\nStart earning coins with me, Dudes!\nJoin @Cuberium now and get 1000 coins 💰`,
              shareLink: referralLink,
              reward: task.coinsReward,
            }),
          );
        } else {
          if (user.soundEnabled) {
            const claimSound = new Howl({
              src: [`${process.env.PUBLIC_URL}/assets/audio/claim.mp3`],
            });
            claimSound.play();
          }
          dispatch(
            showAchievement({
              title: 'REWARD',
              amount: task.coinsReward,
            }),
          );
        }
        if (task.trackId) {
          await trackGtagEvent(task.trackId);
        }
        await refetch();
        await refetchTasks();
      } catch (error) {
        console.error('Sorry', error);
      }
    }
  };

  return (
    <TaskItem
      title={details.title}
      icon={details.icon}
      reward={task.coinsReward}
      isCompleted={isCompleted}
      isPartner={isPartner}
    >
      {isCompleted ? (
        <i
          className={`icon-check`}
          style={{ color: '#39b283', fontSize: '20px' }}
        ></i>
      ) : (
        <Button
          type={ButtonType.PRIMARY}
          variant={ButtonVariant.ORANGE}
          size={ButtonSize.SMALL}
          isLoading={claimLoading}
          title={'CLAIM'}
          onClick={() => {
            !isDisabled && handleClaim(task);
          }}
          isDisabled={isDisabled}
        />
      )}
    </TaskItem>
  );
};

export default CuberiumInApp;
