import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface PopoverState {
  isVisible: boolean;
  title: string;
  description: string;
}

const initialState: PopoverState = {
  isVisible: false,
  title: '',
  description: '',
};

const popoverSlice = createSlice({
  name: 'popover',
  initialState,
  reducers: {
    showPopover: (
      state,
      action: PayloadAction<{ title: string; description: string }>,
    ) => {
      state.isVisible = true;
      state.title = action.payload.title;
      state.description = action.payload.description;
    },
    hidePopover: (state) => {
      state.isVisible = false;
    },
  },
});

export const { showPopover, hidePopover } = popoverSlice.actions;
export default popoverSlice.reducer;
