import PageDescription from '../../../../components/page-description';
import Pagination from '../../../../components/pagination';
import { UserReferrals } from '../../api/types';
import { formatCoins, getInitial } from '../../../../utils/utils';
import TasksWrapper from '../../../../pages/earn/tasks/tasks-wrapper';
import classes from './style.module.scss';

interface UsersProps {
  data: UserReferrals;
  onChange: (value: number) => void;
}
const Users = ({ data, onChange }: UsersProps) => {
  return (
    <TasksWrapper>
      {data.referrals.map((referral) => (
        <li key={referral._id} className={classes.item}>
          <div className={classes.item__part}>
            <div className={classes.item__avatar}>
              <p>{getInitial(referral.username)}</p>
            </div>
            <p className={classes.item__level}>{referral.level} lvl.</p>
            <p className={classes.item__username}>{referral.username}</p>
          </div>
          <div className={classes.item__part__right}>
            <div className={classes.balance}>
              <div className={classes.balance__amount}>
                <div className={classes.balance__icon}>
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/coin-m.svg`}
                  />
                </div>
                <h4 className={classes.balance__value}>
                  {formatCoins(referral.coinsEarnedFromReferral)}
                </h4>
              </div>
            </div>
          </div>
        </li>
      ))}
      {data.referrals.length === 0 && (
        <div className={classes.empty__referrals}>
          <PageDescription description="You have no referrals"></PageDescription>
        </div>
      )}
      <Pagination
        page={data.page}
        totalPages={data.totalPages}
        hasPrevPage={data.hasPrevPage}
        hasNextPage={data.hasNextPage}
        onPageChange={onChange}
      />
    </TasksWrapper>
  );
};

export default Users;
