import classes from './style.module.scss';
import SectionDivider from '../../../../../components/section-divider';
import SectionTitle from '../../../../../components/section-title';
import { Leaderboard as UsersLeaderboard } from '../../../../clans-war/api/types';
import { useState } from 'react';
import LeaderboardUsers from './leaderboard-users';

interface LeaderboardProps {
  mainColor: string;
  currentLeaderboard: UsersLeaderboard;
  allTimeLeaderboard: UsersLeaderboard;
}

enum ViewMode {
  ALL = 'ALL Time',
  CURRENT = 'TOP OF THE WEEK',
}

const Leaderboard = ({
  mainColor,
  currentLeaderboard,
  allTimeLeaderboard,
}: LeaderboardProps) => {
  const [viewMode, setViewMode] = useState<ViewMode>(ViewMode.CURRENT);

  const activeStyle = {
    backgroundColor: mainColor,
    color: 'white',
  };

  return (
    <div className={classes.leaderboard}>
      <SectionDivider color={mainColor}>
        <SectionTitle title="CLAN LEADERBOARD" />
      </SectionDivider>

      <div className={classes.toggle}>
        <button
          className={classes.toggle__option}
          style={viewMode === ViewMode.CURRENT ? activeStyle : {}}
          onClick={() => setViewMode(ViewMode.CURRENT)}
        >
          {ViewMode.CURRENT}
        </button>
        <button
          className={classes.toggle__option}
          style={viewMode === ViewMode.ALL ? activeStyle : {}}
          onClick={() => setViewMode(ViewMode.ALL)}
        >
          {ViewMode.ALL}
        </button>
      </div>
      {viewMode === ViewMode.CURRENT && (
        <LeaderboardUsers data={currentLeaderboard} mainColor={mainColor} />
      )}
      {viewMode === ViewMode.ALL && (
        <LeaderboardUsers data={allTimeLeaderboard} mainColor={mainColor} />
      )}
    </div>
  );
};

export default Leaderboard;
