import ROUTES from '../../utils/routes';

export interface FooterItem {
  icon: string;
  text: string;
  path: string;
  hasIcon: boolean;
  isActive: boolean;
  subPath: string;
}

export const footerItems: Array<FooterItem> = [
  {
    icon: 'refers',
    text: 'REFERS',
    path: ROUTES.REFERRAL,
    hasIcon: false,
    isActive: true,
    subPath: ROUTES.REFERRAL_ALL,
  },
  {
    icon: 'coins',
    text: 'EARN',
    path: ROUTES.EARN,
    isActive: true,
    hasIcon: true,
    subPath: ROUTES.EARN,
  },
  {
    icon: 'tower',
    text: 'TOWER',
    path: ROUTES.HOME,
    isActive: true,
    hasIcon: false,
    subPath: ROUTES.HOME,
  },
  {
    icon: 'boosts',
    text: 'BOOSTS',
    path: ROUTES.BOOSTS,
    isActive: true,
    hasIcon: false,
    subPath: ROUTES.BOOSTS,
  },
  {
    icon: 'swords',
    text: 'WARS',
    path: ROUTES.CLAN_WARS,
    isActive: true,
    hasIcon: false,
    subPath: ROUTES.CLAN_WARS,
  },
];
