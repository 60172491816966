import classes from './style.module.scss';
import ClanWarsBanner from './components/clan-wars-banner';
import { useGetClanQuery, useGetWarLeaderboardQuery } from './api/clansWarApi';
import ClanWarsRewards from './components/clan-wars-rewards';
import { useGetUserQuery } from '../../services/user/userApi';
import Footer from '../../components/footer';
import ClanWarLeaderboard from './components/clan-war-leaderboard';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../services/store';
import { useEffect } from 'react';
import { hideLoader } from '../../services/loader/loaderSlice';

const ClanWars = () => {
  const dispatch = useDispatch();
  const loading = useSelector((state: RootState) => state.loader.loading);

  useEffect(() => {
    if (loading) {
      setTimeout(() => {
        console.log('loader');
        dispatch(hideLoader());
      }, 1500);
    }
  }, [loading, dispatch]);

  const { data: clanWar, isError: clanWarError } = useGetWarLeaderboardQuery(
    undefined,
    {
      refetchOnMountOrArgChange: true,
    },
  );

  const { data: user } = useGetUserQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });

  const clanId = user?.clan?.clanId;

  const { data: clan } = useGetClanQuery(clanId!, {
    skip: !clanId || !clanWarError,
    refetchOnMountOrArgChange: true,
  });

  return (
    <div className={classes.page}>
      {clanWar && !clanWarError && user && (
        <ClanWarsBanner
          initialIsEnded={clanWar.timeLeftMS <= 0}
          week={clanWar.week}
          timesLeft={clanWar.timeLeftMS}
        />
      )}
      {clanWar && !clanWarError && user && (
        <ClanWarLeaderboard clanWar={clanWar} user={user} />
      )}
      {clanWar && !clanWarError && user && <ClanWarsRewards />}
      {clanWarError && clan && (
        <div
          className={classes.section}
          style={{ backgroundColor: clan.coloringSchemaHEX }}
        >
          <div className={classes.content}>
            <img
              src={`${process.env.PUBLIC_URL}/assets/images/clan/sword-left.svg`}
            />
            <h3 className={classes.content__title}>COMING SOON</h3>
            <img
              src={`${process.env.PUBLIC_URL}/assets/images/clan/sword-right.svg`}
            />
          </div>
          <p className={classes.content__description}>
            Clan wars are coming soon!
          </p>
        </div>
      )}
      {clanWarError && !clanId && (
        <div
          className={classes.section}
          style={{ backgroundColor: 'rgba(117, 83, 240, 1)' }}
        >
          <div className={classes.content}>
            <img
              src={`${process.env.PUBLIC_URL}/assets/images/clan/sword-left.svg`}
            />
            <h3 className={classes.content__title}>COMING SOON</h3>
            <img
              src={`${process.env.PUBLIC_URL}/assets/images/clan/sword-right.svg`}
            />
          </div>
          <p className={classes.content__description}>
            Clan wars are coming soon!
          </p>
        </div>
      )}
      {user && <Footer />}
    </div>
  );
};

export default ClanWars;
