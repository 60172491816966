import {
  ExternalResource,
  PartnerTask,
  Task,
  TaskActionType,
} from '../../../services/tasks/tasksApi';
import { User } from '../../../services/user/types';
import { getIcon } from '../../../utils/utils';
import { TaskDetails } from '../completed-tasks';

export const getTaskDetails = (
  task: Task | ExternalResource,
  user: User,
): TaskDetails => {
  switch (task.actionType) {
    case TaskActionType.LEVEL_UP:
      return {
        title: `Reach level ${task.title}`,
        icon: 'logo',
      };
    case TaskActionType.EMAIL:
      return {
        title: `Connect Email`,
        icon: 'letter',
      };
    case TaskActionType.REFERRAL_QUANTITY:
      return {
        title: `Invite ${task.title} Friends (${user.referralsAmount}/${task.title})`,
        icon: 'ref',
      };
    case TaskActionType.DISCORD:
      return {
        title: `Connect Discord`,
        icon: 'discord',
      };
    case TaskActionType.VIEW_STORY:
      return {
        title: task.title,
        icon: 'logo',
      };
    case TaskActionType.SURVEY:
      return {
        title: task.title,
        icon: 'logo',
      };
    case TaskActionType.VISIT_WEBSITE:
      if ('data' in task) {
        return {
          title: task.title,
          icon: getIcon(task.data.resourceName),
        };
      } else {
        return {
          title: task.title,
          icon: 'web',
        };
      }
    case TaskActionType.NO_INFO:
      return {
        title: task.title,
        icon: 'x',
      };
    case TaskActionType.JOIN_GUILD:
      return {
        title: task.title,
        icon: 'discord',
      };
    case TaskActionType.JOIN_CHANNEL:
      return {
        title: task.title,
        icon: 'telegram',
      };
    case TaskActionType.EXPLODE_BOMBS:
      return {
        title: `Explode ${task.title} bombs`,
        icon: 'bomb',
      };
    case TaskActionType.KILL_BOSSES:
      return {
        title: `Defeat ${task.title} bosses`,
        icon: 'boss',
      };
    case TaskActionType.MUSHROOMS_COLLECT:
      return {
        title: `Collect ${task.title} mushrooms`,
        icon: 'mushroom',
      };
    case TaskActionType.MUSHROOMS_STREAK:
      return {
        title: `Streak x${task.title} mushrooms`,
        icon: 'mushroom',
      };
    default:
      return { title: task.title, icon: 'logo' };
  }
};

export const getPartnerTaskDetails = (task: PartnerTask): TaskDetails => {
  return { title: task.title, icon: task.data.logoFilename };
};
