import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export enum NotificationType {
  SUCCESS = 'success',
  ERROR = 'error',
  NONE = '',
}

export interface Notification {
  message: string;
  type: NotificationType;
}

const initialState: Notification = {
  message: '',
  type: NotificationType.NONE,
};

const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    showNotification: (state, action: PayloadAction<Notification>) => {
      state.message = action.payload.message;
      state.type = action.payload.type;
    },
    clearNotification: (state) => {
      state.message = '';
      state.type = NotificationType.NONE;
    },
  },
});

export const { showNotification, clearNotification } =
  notificationSlice.actions;
export default notificationSlice.reducer;
