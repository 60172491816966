import classes from './style.module.scss';

interface PopoverDescriptionProps {
  description: string;
}
const PopoverDescription = ({ description }: PopoverDescriptionProps) => {
  return <p className={classes.description}>{description}</p>;
};

export default PopoverDescription;
