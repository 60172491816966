// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_pagination__0EFP9 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.style_pagination__text__kvZtX {
  color: rgb(27, 27, 27);
  font-size: 1.6rem;
  font-weight: 700;
}
.style_pagination__button__Bot8G {
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgb(117, 83, 240);
  border: 2px solid rgb(255, 255, 255);
  border-radius: 12px;
}
.style_pagination__button__disabled__4jKki {
  opacity: 0.3;
}`, "",{"version":3,"sources":["webpack://./src/components/pagination/style.module.scss","webpack://./public/assets/colors.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;AADF;AAGE;EACE,sBCNU;EDOV,iBAAA;EACA,gBAAA;AADJ;AAIE;EACE,YAAA;EACA,WAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,6BCnBG;EDoBH,oCAAA;EACA,mBAAA;AAFJ;AAGI;EACE,YAAA;AADN","sourcesContent":["@import 'public/assets/colors';\n\n.pagination {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n\n  &__text {\n    color: $black-extra;\n    font-size: 1.6rem;\n    font-weight: 700;\n  }\n\n  &__button {\n    height: 50px;\n    width: 50px;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    background: $main;\n    border: 2px solid $white;\n    border-radius: 12px;\n    &__disabled {\n      opacity: 0.3;\n    }\n  }\n}\n","$main: rgba(117, 83, 240, 1);\n$light-main: rgba(237, 235, 254, 1);\n$black-extra: rgba(27, 27, 27, 1);\n$dark-grey: rgba(135, 135, 135, 1);\n$light-background: rgba(247, 246, 255, 1);\n$white: rgba(255, 255, 255, 1);\n$light-grey: rgba(213, 212, 220, 1);\n$yellow: rgba(255, 199, 1, 1);\n$grey: rgba(158, 158, 158, 1);\n$light-green: #39b283;\n$green: #1f6b4e;\n$dark-green: #1f4c3a;\n$danger: #c10000;\n$red: #e73232;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pagination": `style_pagination__0EFP9`,
	"pagination__text": `style_pagination__text__kvZtX`,
	"pagination__button": `style_pagination__button__Bot8G`,
	"pagination__button__disabled": `style_pagination__button__disabled__4jKki`
};
export default ___CSS_LOADER_EXPORT___;
