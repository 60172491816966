// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_balance__pczbT {
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 6px;
}
.style_balance__amount__Xw6KV {
  display: flex;
  align-items: center;
  gap: 6px;
}
.style_balance__icon__7Z6Fb {
  width: 18px;
  height: 18px;
  border-radius: 100px;
  background: #fdc902;
}
.style_balance__icon__7Z6Fb img {
  width: 100%;
  height: 100%;
}
.style_balance__value__PGMp1 {
  font-size: 1.6rem;
  color: rgb(27, 27, 27);
  font-weight: 700;
}
.style_balance__value__white__U8hbn {
  color: white;
}`, "",{"version":3,"sources":["webpack://./src/components/small-balance/style.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,uBAAA;EACA,sBAAA;EACA,QAAA;AACF;AACE;EACE,aAAA;EACA,mBAAA;EACA,QAAA;AACJ;AACE;EACE,WAAA;EACA,YAAA;EACA,oBAAA;EACA,mBAAA;AACJ;AAAI;EACE,WAAA;EACA,YAAA;AAEN;AACE;EACE,iBAAA;EACA,sBAAA;EACA,gBAAA;AACJ;AAAI;EACE,YAAA;AAEN","sourcesContent":[".balance {\n  display: flex;\n  justify-content: center;\n  flex-direction: column;\n  gap: 6px;\n\n  &__amount {\n    display: flex;\n    align-items: center;\n    gap: 6px;\n  }\n  &__icon {\n    width: 18px;\n    height: 18px;\n    border-radius: 100px;\n    background: #fdc902;\n    img {\n      width: 100%;\n      height: 100%;\n    }\n  }\n  &__value {\n    font-size: 1.6rem;\n    color: rgba(27, 27, 27, 1);\n    font-weight: 700;\n    &__white {\n      color: white;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"balance": `style_balance__pczbT`,
	"balance__amount": `style_balance__amount__Xw6KV`,
	"balance__icon": `style_balance__icon__7Z6Fb`,
	"balance__value": `style_balance__value__PGMp1`,
	"balance__value__white": `style_balance__value__white__U8hbn`
};
export default ___CSS_LOADER_EXPORT___;
