import Button, {
  ButtonSize,
  ButtonType,
  ButtonVariant,
} from '../../../../components/button';
import { ClanShortInfo } from '../../api/types';
import classes from './style.module.scss';

interface ClanItemProps {
  clan: ClanShortInfo;
  onSelect: (clan: ClanShortInfo) => void;
  isLoading: boolean;
}
const ClanItem = ({ clan, onSelect, isLoading }: ClanItemProps) => {
  return (
    <div
      className={classes.clan}
      key={clan._id}
      style={{ backgroundColor: `${clan.coloringSchemaHEX}` }}
    >
      <div className={classes.clan__left}>
        <div className={classes.clan__avatar}>
          <img
            src={
              process.env.REACT_APP_API_BASE_URL +
              '/images/clans/logos/' +
              clan.logoFilename
            }
            alt=""
          />
        </div>
        <div className={classes.clan__info}>
          <h3 className={classes.clan__info__title}>{clan.name}</h3>
          <p className={classes.clan__info__members}>
            <i className="icon-person"></i>
            {clan.members}
          </p>
        </div>
      </div>
      <div className={classes.clan__action}>
        <Button
          type={ButtonType.PRIMARY}
          variant={ButtonVariant.ORANGE}
          size={ButtonSize.SMALL}
          isLoading={isLoading}
          title="JOIN"
          onClick={() => {
            onSelect(clan);
          }}
        />
      </div>
    </div>
  );
};

export default ClanItem;
