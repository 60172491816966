import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';
import classes from './style.module.scss';
import { RootState } from '../../services/store';
import {
  NotificationType,
  clearNotification,
} from '../../services/notification/notificationSlice';

const Notification = () => {
  const { message, type } = useSelector(
    (state: RootState) => state.notification,
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (message) {
      const timer = setTimeout(() => {
        dispatch(clearNotification());
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [message, dispatch]);

  if (!message) return null;

  const notificationClass = `${classes.notification} ${
    type === 'success'
      ? classes.notification__success
      : classes.notification__error
  }`;

  return (
    <p className={notificationClass}>
      <i
        className={
          type === NotificationType.SUCCESS ? 'icon-check' : 'icon-warning'
        }
      ></i>
      {message}
    </p>
  );
};

export default Notification;
