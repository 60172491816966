// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_balance__K6cf6 {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  margin-top: 4px;
}
.style_balance__title__spqCT {
  font-size: 1.4rem;
  font-weight: 500;
  color: rgb(213, 212, 220);
}
.style_balance__title__dark__vFhjF {
  color: #1c192e;
}
.style_balance__icon__EcwkZ {
  width: 24px;
  height: 24px;
}
.style_balance__icon__EcwkZ img {
  width: 100%;
  height: 100%;
}
.style_balance__amount__hbCZj {
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: 500;
}
.style_balance__value__OVH87 {
  font-size: 2.8rem;
  font-weight: 700;
  color: rgb(255, 255, 255);
}
.style_balance__value__black__uSCSA {
  color: rgb(27, 27, 27);
}`, "",{"version":3,"sources":["webpack://./src/components/balance/style.module.scss","webpack://./public/assets/colors.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,uBAAA;EACA,sBAAA;EACA,mBAAA;EACA,QAAA;EACA,eAAA;AADF;AAGE;EACE,iBAAA;EACA,gBAAA;EACA,yBCPS;ADMb;AAEI;EACE,cAAA;AAAN;AAIE;EACE,WAAA;EACA,YAAA;AAFJ;AAGI;EACE,WAAA;EACA,YAAA;AADN;AAKE;EACE,aAAA;EACA,mBAAA;EACA,QAAA;EACA,gBAAA;AAHJ;AAME;EACE,iBAAA;EACA,gBAAA;EACA,yBCjCI;AD6BR;AAKI;EACE,sBCtCQ;ADmCd","sourcesContent":["@import 'public/assets/colors';\n\n.balance {\n  display: flex;\n  justify-content: center;\n  flex-direction: column;\n  align-items: center;\n  gap: 8px;\n  margin-top: 4px;\n\n  &__title {\n    font-size: 1.4rem;\n    font-weight: 500;\n    color: $light-grey;\n    &__dark {\n      color: #1c192e;\n    }\n  }\n\n  &__icon {\n    width: 24px;\n    height: 24px;\n    img {\n      width: 100%;\n      height: 100%;\n    }\n  }\n\n  &__amount {\n    display: flex;\n    align-items: center;\n    gap: 8px;\n    font-weight: 500;\n  }\n\n  &__value {\n    font-size: 2.8rem;\n    font-weight: 700;\n    color: $white;\n    &__black {\n      color: $black-extra;\n    }\n  }\n}\n","$main: rgba(117, 83, 240, 1);\n$light-main: rgba(237, 235, 254, 1);\n$black-extra: rgba(27, 27, 27, 1);\n$dark-grey: rgba(135, 135, 135, 1);\n$light-background: rgba(247, 246, 255, 1);\n$white: rgba(255, 255, 255, 1);\n$light-grey: rgba(213, 212, 220, 1);\n$yellow: rgba(255, 199, 1, 1);\n$grey: rgba(158, 158, 158, 1);\n$light-green: #39b283;\n$green: #1f6b4e;\n$dark-green: #1f4c3a;\n$danger: #c10000;\n$red: #e73232;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"balance": `style_balance__K6cf6`,
	"balance__title": `style_balance__title__spqCT`,
	"balance__title__dark": `style_balance__title__dark__vFhjF`,
	"balance__icon": `style_balance__icon__EcwkZ`,
	"balance__amount": `style_balance__amount__hbCZj`,
	"balance__value": `style_balance__value__OVH87`,
	"balance__value__black": `style_balance__value__black__uSCSA`
};
export default ___CSS_LOADER_EXPORT___;
