import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const TelegramBackButtonHandler = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname !== '/') {
      window.Telegram.WebApp.BackButton.show();
    } else {
      window.Telegram.WebApp.BackButton.hide();
    }

    const handleBackClick = () => {
      if (
        document.referrer &&
        new URL(document.referrer).origin !== window.location.origin
      ) {
        window.location.href =
          process.env.REACT_APP_FRONTEND_BASE_URL + '/profile';
      } else {
        navigate(-1);
      }
    };

    window.Telegram.WebApp.BackButton.onClick(handleBackClick);

    return () => {
      window.Telegram.WebApp.BackButton.offClick(handleBackClick);
    };
  }, [location, navigate]);

  return null;
};

export default TelegramBackButtonHandler;
