import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { User } from './types';

interface State {
  user: User;
  vault: number;
  firstRender: boolean;
  footerHintFirstLoad: boolean;
  showFooterHint: boolean;
}

const initialState: State = {
  user: {
    _id: '',
    uid: '',
    lastOnboardingPhase: 0,
    referralsAmount: 0,
    telegramId: '',
    telegramUsername: '',
    avatarId: '',
    role: '',
    email: '',
    referralCode: '',
    level: 0,
    mobsDefeated: 0,
    coinsBalance: 0,
    totalXp: 0,
    totalClaimedAmountFromReferrals: 0,
    towerGame: {
      gameJoinedTimestamp: '',
      lastClaimTimestamp: '',
      lastLevelupTimestamp: '',
      mapLevel: 0,
      storageLevel: 0,
      todayClaimedChestsCount: 0,
      towerLevel: 0,
      vaultBalance: 0,
    },
    completedTasks: [],
    connectedSocials: [],
    createdAt: '',
    updatedAt: '',
    emailVerified: false,
    soundEnabled: true,
    bombsExploded: 0,
    bossesDefeated: 0,
    tapGame: {
      maxStreak: 0,
      totalTaps: 0,
    },
    clan: {
      clanId: null,
      lastJoinedTimestamp: null,
    },
    hasSeenCWresults: false,
  },
  vault: 0,
  firstRender: true,
  footerHintFirstLoad: true,
  showFooterHint: false,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser(state, action: PayloadAction<User>) {
      state.user = action.payload;
    },
    setVault(state, action: PayloadAction<number>) {
      state.firstRender = false;
      state.user.towerGame.vaultBalance = action.payload;
    },
    incrementVault(state) {
      state.user.towerGame.vaultBalance += 1;
    },
    toggleFirstRender(state) {
      state.firstRender = false;
    },
    increaseStorageLevel(state) {
      if (
        state.user.towerGame &&
        state.user.towerGame.storageLevel !== undefined
      ) {
        state.user.towerGame.storageLevel += 1;
      }
    },
    increaseTowerLevel(state) {
      state.user.towerGame.towerLevel += 1;
    },

    displayFooterHint(state, action: PayloadAction<boolean>) {
      state.showFooterHint = action.payload;
    },
    toggleFooterFirstHint(state, action: PayloadAction<boolean>) {
      state.footerHintFirstLoad = action.payload;
    },
  },
});

export const {
  setUser,
  setVault,
  incrementVault,
  increaseStorageLevel,
  increaseTowerLevel,
  displayFooterHint,
  toggleFooterFirstHint,
} = userSlice.actions;
export default userSlice.reducer;
