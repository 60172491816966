import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReauth } from '../../../services/baseQuery';

export const emailApi = createApi({
  reducerPath: 'emailApi',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['user', 'tasks'],
  endpoints: (builder) => ({
    sendCode: builder.mutation<{ message: string }, { email: string }>({
      query: (emailData) => ({
        url: 'user/email/send_code',
        method: 'PATCH',
        body: {
          email: emailData.email,
        },
      }),
      invalidatesTags: ['user', 'tasks'],
    }),
    verifyCode: builder.mutation<{ message: string }, { code: string }>({
      query: (verificationData) => ({
        url: 'user/email/confirm',
        method: 'PATCH',
        body: {
          code: verificationData.code,
        },
      }),
      invalidatesTags: ['user', 'tasks'],
    }),
  }),
});

export const { useSendCodeMutation, useVerifyCodeMutation } = emailApi;
