// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_fame__S0i5K {
  width: 100%;
  overflow: hidden;
  padding: 16px 14px;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 12px;
}
.style_fame__title__xBWXo {
  font-weight: 700;
  font-size: 2rem;
  line-height: 20px;
  text-align: center;
  text-transform: uppercase;
}
.style_fame__subtitle__slZzQ {
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 17px;
  color: rgba(255, 255, 255, 0.75);
}

.style_fame__list__zy93M {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: repeat(auto-fill, 75px);
  gap: 2px;
  overflow-x: auto;
  overflow-y: hidden;
  width: 100%;
  white-space: nowrap;
}

.style_item__oiZQm {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.style_item__oiZQm img {
  width: 70px;
  height: 70px;
}`, "",{"version":3,"sources":["webpack://./src/features/clans/clan/components/hall-of-fame/style.module.scss"],"names":[],"mappings":"AAEA;EACE,WAAA;EACA,gBAAA;EACA,kBAAA;EACA,aAAA;EACA,mBAAA;EACA,sBAAA;EACA,SAAA;AADF;AAEE;EACE,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,kBAAA;EACA,yBAAA;AAAJ;AAEE;EACE,gBAAA;EACA,iBAAA;EACA,iBAAA;EACA,gCAAA;AAAJ;;AAIA;EACE,aAAA;EACA,sBAAA;EACA,8CAAA;EACA,QAAA;EACA,gBAAA;EACA,kBAAA;EACA,WAAA;EACA,mBAAA;AADF;;AAIA;EACE,kBAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;AADF;AAEE;EACE,WAAA;EACA,YAAA;AAAJ","sourcesContent":["@import 'public/assets/colors';\n\n.fame {\n  width: 100%;\n  overflow: hidden;\n  padding: 16px 14px;\n  display: flex;\n  align-items: center;\n  flex-direction: column;\n  gap: 12px;\n  &__title {\n    font-weight: 700;\n    font-size: 2rem;\n    line-height: 20px;\n    text-align: center;\n    text-transform: uppercase;\n  }\n  &__subtitle {\n    font-weight: 500;\n    font-size: 1.4rem;\n    line-height: 17px;\n    color: rgba(255, 255, 255, 0.75);\n  }\n}\n\n.fame__list {\n  display: grid;\n  grid-auto-flow: column;\n  grid-template-columns: repeat(auto-fill, 75px);\n  gap: 2px;\n  overflow-x: auto;\n  overflow-y: hidden;\n  width: 100%;\n  white-space: nowrap;\n}\n\n.item {\n  text-align: center;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  img {\n    width: 70px;\n    height: 70px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fame": `style_fame__S0i5K`,
	"fame__title": `style_fame__title__xBWXo`,
	"fame__subtitle": `style_fame__subtitle__slZzQ`,
	"fame__list": `style_fame__list__zy93M`,
	"item": `style_item__oiZQm`
};
export default ___CSS_LOADER_EXPORT___;
