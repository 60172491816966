import {
  formatTotalCoins,
  getColorForInitial,
  getInitial,
  maskUsername,
} from '../../../../../utils/utils';
import { TopClan } from '../../../api/types';
import classes from './style.module.scss';

interface ClanWarLeaderProps {
  topClan: TopClan;
}

const ClanWarLeader = ({ topClan }: ClanWarLeaderProps) => {
  return (
    <div className={classes.item__wrapper}>
      <div
        className={classes.item}
        style={{ backgroundColor: topClan.clan.colorSchemaHEX }}
      >
        <h4 className={classes.item__title}>
          {topClan.clan.position}
          {topClan.clan.position == 1 && 'st'}
          {topClan.clan.position == 2 && 'nd'}
          {topClan.clan.position == 3 && 'rd'}
        </h4>
        <img
          className={classes.item__prize}
          src={`${process.env.PUBLIC_URL}/assets/images/prizes/prize-placeholder.svg`}
          alt=""
        />
        <img
          className={classes.item__logo}
          src={
            process.env.REACT_APP_API_BASE_URL +
            '/images/clans/logos/' +
            topClan.clan.logoFilename
          }
          alt=""
        />
        <div className={classes.item__name}>
          <p className={classes.item__name__prefix}>Clan</p>
          <p className={classes.item__name__content}>{topClan.clan.name}</p>
        </div>
        <div className={classes.item__leaders}>
          <p className={classes.item__name__prefix}>Top Members</p>
          <div className={classes.item__leaders__list}>
            {topClan.topMembers.map((item, index) => (
              <div className={classes.member} key={`${item}-${index}`}>
                <div className={classes.member__icon}>
                  <p
                    style={{
                      color: getColorForInitial(item),
                      fontWeight: 600,
                    }}
                  >
                    {getInitial(item)}
                  </p>
                </div>
                <p className={classes.member__title}>{maskUsername(item)}</p>
              </div>
            ))}
          </div>
        </div>
        <div className={classes.total}>
          <h4 className={classes.total__title}>
            {formatTotalCoins(topClan.clan.points)}
          </h4>
          <p className={classes.item__name__prefix}>Points</p>
        </div>
        <div className={classes.item__bottom__angle}></div>
      </div>
    </div>
  );
};

export default ClanWarLeader;
