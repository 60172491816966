import React, { useState, useEffect } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import SectionDivider from '../../components/section-divider';
import SectionTitle from '../../components/section-title';
import UserInfoBadge from '../../components/user-info-badge';
import { useGetUserQuery } from '../../services/user/userApi';
import { useSendCodeMutation, useVerifyCodeMutation } from './api/emailApi';
import classes from './style.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
  NotificationType,
  showNotification,
} from '../../services/notification/notificationSlice';
import Input from '../../components/input';
import { useNavigate } from 'react-router-dom';
import Button, {
  ButtonSize,
  ButtonType,
  ButtonVariant,
} from '../../components/button';
import { RootState } from '../../services/store';
import { GtagEventType, trackGtagEvent } from '../../utils/gtagEventHandler';
import Footer from '../../components/footer';
import { hideLoader } from '../../services/loader/loaderSlice';

type EmailFormValues = {
  email: string;
};

type CodeFormValues = {
  code: string;
};

const emailSchema = yup.object({
  email: yup
    .string()
    .email('Invalid email format')
    .required('Email is required'),
});

const codeSchema = yup.object({
  code: yup.string().required('Code is required'),
});

const Email = () => {
  const { data: user, refetch } = useGetUserQuery();
  const [isCodeSent, setIsCodeSent] = useState(false);
  const [currentEmail, setCurrentEmail] = useState('');
  const navigate = useNavigate();

  const navigationState = useSelector(
    (state: RootState) => state.navigation.route,
  );

  const {
    register: registerEmail,
    handleSubmit: handleSubmitEmail,
    watch,
    formState: { errors: errorsEmail },
  } = useForm<EmailFormValues>({
    resolver: yupResolver(emailSchema),
    defaultValues: { email: '' },
  });

  const {
    register: registerCode,
    handleSubmit: handleSubmitCode,
    formState: { errors: errorsCode },
  } = useForm<CodeFormValues>({
    resolver: yupResolver(codeSchema),
    defaultValues: { code: '' },
  });

  const emailWatch = watch('email');
  useEffect(() => {
    if (emailWatch) {
      setCurrentEmail(emailWatch);
    }
  }, [emailWatch]);

  const dispatch = useDispatch();
  const [sendCode, { isLoading: isCodeSending }] = useSendCodeMutation();
  const [verifyCode, { isLoading: isCodeVerifying }] = useVerifyCodeMutation();

  const loading = useSelector((state: RootState) => state.loader.loading);

  useEffect(() => {
    if (loading) {
      setTimeout(() => {
        dispatch(hideLoader());
      }, 1500);
    }
  }, []);

  const onSubmitEmail: SubmitHandler<EmailFormValues> = async (data) => {
    try {
      await sendCode({ email: data.email }).unwrap();
      setIsCodeSent(true);
      startCountdown();
      dispatch(
        showNotification({
          message: 'Email successfully sent',
          type: NotificationType.SUCCESS,
        }),
      );
    } catch (error) {
      dispatch(
        showNotification({
          message: 'This email is already exist',
          type: NotificationType.ERROR,
        }),
      );
    }
  };

  const onSubmitCode: SubmitHandler<CodeFormValues> = async (data) => {
    try {
      await verifyCode({ code: data.code }).unwrap();
      trackGtagEvent(GtagEventType.LINK_EMAIL);
      dispatch(
        showNotification({
          message: 'Profile has been linked successfully',
          type: NotificationType.SUCCESS,
        }),
      );

      navigate(navigationState);
      refetch();
    } catch (error) {
      dispatch(
        showNotification({
          message: 'Error sending code',
          type: NotificationType.ERROR,
        }),
      );
    }
  };

  const handleResendCode = async () => {
    try {
      await sendCode({ email: currentEmail }).unwrap();
      dispatch(
        showNotification({
          message: 'Code resent successfully',
          type: NotificationType.SUCCESS,
        }),
      );
      startCountdown();
    } catch (error) {
      dispatch(
        showNotification({
          message: 'Error resending code',
          type: NotificationType.ERROR,
        }),
      );
    }
  };

  const [countdown, setCountdown] = useState(60);
  const [isResendDisabled, setIsResendDisabled] = useState(false);

  const startCountdown = () => {
    setCountdown(60);
    setIsResendDisabled(true);
    const interval = setInterval(() => {
      setCountdown((prevCountdown) => {
        if (prevCountdown === 1) {
          clearInterval(interval);
          setIsResendDisabled(false);
          return 0;
        }
        return prevCountdown - 1;
      });
    }, 1000);
  };

  return (
    <div className={classes.email}>
      {user && (
        <div className={classes.email__section}>
          <UserInfoBadge user={user} />
          <SectionDivider>
            <SectionTitle title="CONNECT EMAIL" />
          </SectionDivider>
          <form
            onSubmit={
              isCodeSent
                ? handleSubmitCode(onSubmitCode)
                : handleSubmitEmail(onSubmitEmail)
            }
            className={classes.form}
          >
            <Input
              name="email"
              placeholder="Enter your Email"
              type="email"
              register={registerEmail}
              error={errorsEmail.email?.message}
            />
            {isCodeSent && (
              <Input
                name="code"
                placeholder="Enter Code here"
                type="text"
                register={registerCode}
                error={errorsCode.code?.message}
              />
            )}
            <p className={classes.form__description}>
              Your profile is not visible to other users
            </p>
            {isCodeSent && (
              <button
                className={classes.resend__button}
                onClick={handleResendCode}
                disabled={isResendDisabled}
              >
                {isResendDisabled
                  ? `RESEND IN ${countdown} SEC`
                  : 'RESEND CODE'}
              </button>
            )}
            <Button
              type={ButtonType.PRIMARY}
              variant={ButtonVariant.ORANGE}
              size={ButtonSize.LARGE}
              title={isCodeSent ? 'CONNECT' : 'CONTINUE'}
              isSubmit
              isLoading={isCodeSending || isCodeVerifying}
            />
          </form>
        </div>
      )}
      {user && <Footer />}
    </div>
  );
};

export default Email;
