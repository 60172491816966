import Progress from '../../../components/progress';
import classes from './style.module.scss';
import { User } from '../../../services/user/types';
import { getColorForInitial, getInitial } from '../../../utils/utils';
import { useNavigate } from 'react-router-dom';
import ROUTES from '../../../utils/routes';
import { useGetClansQuery } from '../../clans/api/clansApi';

interface UserBadgeProps {
  user: User;
  onClick: () => void;
  onSoundToggle: () => Promise<void>;
}

export const UserBadge = ({ user, onClick, onSoundToggle }: UserBadgeProps) => {
  const navigate = useNavigate();
  const { data: clans } = useGetClansQuery();

  // Find the clan with the same id as user.clan.clanId
  const userClan = clans?.clans.find((clan) => clan._id === user.clan.clanId);

  const handleSound = (e: any) => {
    e.stopPropagation();
    onSoundToggle();
  };

  const onNavigate = (event: any) => {
    event.stopPropagation();
    if (user.clan.clanId) {
      navigate(`${ROUTES.CLAN}/${user.clan.clanId}`);
    } else {
      navigate(ROUTES.JOIN_CLAN);
    }
  };

  return (
    <div className={classes.badge} onClick={onClick}>
      <div className={classes.badge__part}>
        <div className={classes.badge__icon}>
          <p
            style={{
              color: getColorForInitial(user.telegramUsername),
              fontSize: '24px',
              fontWeight: 600,
            }}
          >
            {getInitial(user.telegramUsername)}
          </p>
        </div>
      </div>
      <div className={classes.badge__content}>
        <div className={classes.badge__content__head}>
          <h3 className={classes.badge__content__head__title}>
            {user.telegramUsername}
          </h3>
          <div className={classes.badge__content__head__part}>
            <div className={classes.toggle} onClick={handleSound}>
              <i
                className={`icon-${user.soundEnabled ? 'sound_on' : 'sound_off'}`}
              ></i>
            </div>
            <h4 className={classes.badge__content__head__subtitle}>
              Level {user.level}
            </h4>
          </div>
        </div>
        <Progress level={user.level} currentXP={user.totalXp} />
      </div>
      <div className={classes.badge__flag__divider}></div>
      <div className={classes.badge__flag} onClick={onNavigate}>
        <img
          src={
            userClan
              ? process.env.REACT_APP_API_BASE_URL +
                '/images/clans/banners/' +
                userClan.bannerFilename
              : `${process.env.PUBLIC_URL}/assets/images/flag.svg`
          }
          className={classes.badge__flag__image}
        ></img>
      </div>
    </div>
  );
};

export default UserBadge;
