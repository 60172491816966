import Button, {
  ButtonSize,
  ButtonType,
  ButtonVariant,
} from '../../../../components/button';
import {
  Task,
  TaskStories,
  useClaimTaskMutation,
  useCompleteTaskMutation,
  useGetTasksInfoQuery,
} from '../../../../services/tasks/tasksApi';
import { TaskDetails } from '../../completed-tasks';
import { useState } from 'react';
import CuberiumStoriesStepper from './cuberium-stories-stepper';
import { useDispatch } from 'react-redux';
import {
  NotificationType,
  showNotification,
} from '../../../../services/notification/notificationSlice';
import { showAchievement } from '../../../../services/achievement/achievementSlice';
import { useGetUserQuery } from '../../../../services/user/userApi';
import TaskItem from '../task-item';

interface CuberiumStoriesProps {
  task: Task;
  readyForClaiming: boolean;
  details: TaskDetails;
}
const CuberiumStoriesTask = ({
  task,
  readyForClaiming,
  details,
}: CuberiumStoriesProps) => {
  const [viewStories, setViewStories] = useState(false);
  const dispatch = useDispatch();
  const [claimTask, { isLoading: claimLoading }] = useClaimTaskMutation();
  const [completeTask, { isLoading: completeLoading }] =
    useCompleteTaskMutation();
  const { refetch } = useGetUserQuery();
  const { refetch: refetchTasks } = useGetTasksInfoQuery();

  const onHandleClaim = async () => {
    try {
      await claimTask(task._id).unwrap();
      dispatch(
        showAchievement({
          title: 'REWARD',
          amount: task.coinsReward,
        }),
      );
      await refetch();
      await refetchTasks();
    } catch (error) {
      dispatch(
        showNotification({
          message: 'Sorry, something went wrong!',
          type: NotificationType.ERROR,
        }),
      );
    }
  };

  const handleStories = () => {
    setViewStories(true);
  };

  const completeStories = async () => {
    try {
      await completeTask(task._id).unwrap();
      await refetch();
      await refetchTasks();
      setViewStories(false);
    } catch (error) {
      setViewStories(false);
      dispatch(
        showNotification({
          message: 'Sorry, something went wrong!',
          type: NotificationType.ERROR,
        }),
      );
    }
  };

  return (
    <TaskItem
      title={details.title}
      icon={details.icon}
      reward={task.coinsReward}
    >
      {!readyForClaiming ? (
        <Button
          type={ButtonType.PRIMARY}
          variant={ButtonVariant.PURPLE}
          size={ButtonSize.SMALL}
          isLoading={completeLoading}
          title={'OPEN'}
          onClick={handleStories}
        />
      ) : (
        <Button
          type={ButtonType.PRIMARY}
          variant={ButtonVariant.ORANGE}
          size={ButtonSize.SMALL}
          isLoading={claimLoading}
          title={'CLAIM'}
          onClick={() => {
            readyForClaiming && onHandleClaim();
          }}
        />
      )}
      {viewStories && (
        <CuberiumStoriesStepper
          task={task as TaskStories}
          onFinish={completeStories}
        />
      )}
    </TaskItem>
  );
};

export default CuberiumStoriesTask;
