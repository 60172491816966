// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_divider__NsFwK {
  height: 1px;
  width: 100%;
  margin-top: 6px;
  margin-bottom: 4px;
  background: rgba(33, 33, 33, 0.1);
}

.style_daily__xE6NO {
  margin: 16px 0;
}
.style_daily__requirements__QwXVP {
  display: flex;
  flex-direction: column;
  gap: 2px;
}
.style_daily__requirements__title__IKGbh {
  font-size: 1.2rem;
  font-weight: 700;
  color: rgb(27, 27, 27);
}
.style_daily__requirements__time__uAdRF {
  font-size: 1.4rem;
  font-weight: 400;
  display: flex;
  gap: 6px;
  width: 120px;
  height: 35px;
  justify-content: flex-end;
  color: rgb(255, 255, 255);
}`, "",{"version":3,"sources":["webpack://./src/pages/earn/cuberium-tasks/style.module.scss","webpack://./public/assets/colors.scss"],"names":[],"mappings":"AAEA;EACE,WAAA;EACA,WAAA;EACA,eAAA;EACA,kBAAA;EACA,iCAAA;AADF;;AAIA;EACE,cAAA;AADF;AAGE;EACE,aAAA;EACA,sBAAA;EACA,QAAA;AADJ;AAEI;EACE,iBAAA;EACA,gBAAA;EACA,sBClBQ;ADkBd;AAGI;EACE,iBAAA;EACA,gBAAA;EACA,aAAA;EACA,QAAA;EACA,YAAA;EACA,YAAA;EACA,yBAAA;EACA,yBC1BE;ADyBR","sourcesContent":["@import 'public/assets/colors';\n\n.divider {\n  height: 1px;\n  width: 100%;\n  margin-top: 6px;\n  margin-bottom: 4px;\n  background: rgba(33, 33, 33, 0.1);\n}\n\n.daily {\n  margin: 16px 0;\n\n  &__requirements {\n    display: flex;\n    flex-direction: column;\n    gap: 2px;\n    &__title {\n      font-size: 1.2rem;\n      font-weight: 700;\n      color: $black-extra;\n    }\n\n    &__time {\n      font-size: 1.4rem;\n      font-weight: 400;\n      display: flex;\n      gap: 6px;\n      width: 120px;\n      height: 35px;\n      justify-content: flex-end;\n      color: $white;\n    }\n  }\n}\n","$main: rgba(117, 83, 240, 1);\n$light-main: rgba(237, 235, 254, 1);\n$black-extra: rgba(27, 27, 27, 1);\n$dark-grey: rgba(135, 135, 135, 1);\n$light-background: rgba(247, 246, 255, 1);\n$white: rgba(255, 255, 255, 1);\n$light-grey: rgba(213, 212, 220, 1);\n$yellow: rgba(255, 199, 1, 1);\n$grey: rgba(158, 158, 158, 1);\n$light-green: #39b283;\n$green: #1f6b4e;\n$dark-green: #1f4c3a;\n$danger: #c10000;\n$red: #e73232;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"divider": `style_divider__NsFwK`,
	"daily": `style_daily__xE6NO`,
	"daily__requirements": `style_daily__requirements__QwXVP`,
	"daily__requirements__title": `style_daily__requirements__title__IKGbh`,
	"daily__requirements__time": `style_daily__requirements__time__uAdRF`
};
export default ___CSS_LOADER_EXPORT___;
