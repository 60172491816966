import classes from './style.module.scss';

const ExtraRewards = () => {
  return (
    <div className={classes.extra}>
      <h3 className={classes.extra__title}>Extra rewards</h3>
      <h4 className={classes.extra__subtitle}>
        For members of the top 3 clans
      </h4>
      <div className={classes.top}>
        <div className={classes.top__item}>
          <div className={classes.top__item__part}>
            <p className={classes.top__item__title}>Tier 1</p>
            <p>Top 2% of members</p>
          </div>
          <div className={classes.top__item__part}>
            <p className={classes.top__item__title}>+50%</p>
            <img src={`${process.env.PUBLIC_URL}/assets/images/coin-m.svg`} />
          </div>
        </div>
        <div className={classes.top__item}>
          <div className={classes.top__item__part}>
            <p className={classes.top__item__title}>Tier 2</p>
            <p>Top 5% of members</p>
          </div>
          <div className={classes.top__item__part}>
            <p className={classes.top__item__title}>+30%</p>
            <img src={`${process.env.PUBLIC_URL}/assets/images/coin-m.svg`} />
          </div>
        </div>
        <div className={classes.top__item}>
          <div className={classes.top__item__part}>
            <p className={classes.top__item__title}>Tier 3</p>
            <p>Top 10% of members</p>
          </div>
          <div className={classes.top__item__part}>
            <p className={classes.top__item__title}>+15%</p>
            <img src={`${process.env.PUBLIC_URL}/assets/images/coin-m.svg`} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExtraRewards;
