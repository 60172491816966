import classes from './style.module.scss';

const TelegramOnly = () => {
  return (
    <div className={classes.error}>
      <p className={classes.error__emodji}>:)</p>
      <h1>Something went wrong</h1>
      <h2>Please reload the app</h2>
    </div>
  );
};

export default TelegramOnly;
