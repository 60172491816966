export enum BOOSTS_TYPE {
  TOWER_POWER = 'TOWER_POWER',
  VAULT_SIZE = 'VAULT_SIZE',
  TREASURE_MAP = 'TREASURE_MAP',
}

export interface BoostLevel {
  level: number;
  cost: number;
  coinFrequency?: number;
  storageCapacity?: number;
  numberOfChests?: number;
}

export interface TOWER_POWER {
  boostType: BOOSTS_TYPE.TOWER_POWER;
  current: BoostLevel & { coinFrequency: number };
  next: (BoostLevel & { coinFrequency: number }) | null;
  maxLevel: number;
}

export interface VAULT_SIZE {
  boostType: BOOSTS_TYPE.VAULT_SIZE;
  current: BoostLevel & { storageCapacity: number };
  next: (BoostLevel & { storageCapacity: number }) | null;
  maxLevel: number;
}

export interface TREASURE_MAP {
  boostType: BOOSTS_TYPE.TREASURE_MAP;
  current: BoostLevel & { numberOfChests: number };
  next: (BoostLevel & { numberOfChests: number }) | null;
  maxLevel: number;
}

export interface Boosts {
  goldenChest: TREASURE_MAP;
  towerPower: TOWER_POWER;
  vaultSize: VAULT_SIZE;
}
