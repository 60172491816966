import classes from './style.module.scss';

interface PopoverTitleProps {
  title: string;
}
const PopoverTitle = ({ title }: PopoverTitleProps) => {
  return <h2 className={classes.title}>{title}</h2>;
};

export default PopoverTitle;
