import classes from './style.module.scss';

const RestrictedDevice = () => {
  return (
    <div
      className={classes.restricted}
      style={{
        backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/restricted.png)`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      }}
    ></div>
  );
};

export default RestrictedDevice;
