// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_progress__container__wLrhT {
  width: 100%;
  background-color: rgb(213, 212, 220);
  border-radius: 10px;
}

.style_progress__bar__58l0x {
  min-width: 5px;
  height: 10px;
  background-color: rgb(255, 199, 1);
  border-radius: 10px;
}`, "",{"version":3,"sources":["webpack://./src/components/progress/style.module.scss","webpack://./public/assets/colors.scss"],"names":[],"mappings":"AAEA;EACE,WAAA;EACA,oCCEW;EDDX,mBAAA;AADF;;AAIA;EACE,cAAA;EACA,YAAA;EACA,kCCJO;EDKP,mBAAA;AADF","sourcesContent":["@import 'public/assets/colors';\n\n.progress__container {\n  width: 100%;\n  background-color: $light-grey;\n  border-radius: 10px;\n}\n\n.progress__bar {\n  min-width: 5px;\n  height: 10px;\n  background-color: $yellow;\n  border-radius: 10px;\n}\n","$main: rgba(117, 83, 240, 1);\n$light-main: rgba(237, 235, 254, 1);\n$black-extra: rgba(27, 27, 27, 1);\n$dark-grey: rgba(135, 135, 135, 1);\n$light-background: rgba(247, 246, 255, 1);\n$white: rgba(255, 255, 255, 1);\n$light-grey: rgba(213, 212, 220, 1);\n$yellow: rgba(255, 199, 1, 1);\n$grey: rgba(158, 158, 158, 1);\n$light-green: #39b283;\n$green: #1f6b4e;\n$dark-green: #1f4c3a;\n$danger: #c10000;\n$red: #e73232;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"progress__container": `style_progress__container__wLrhT`,
	"progress__bar": `style_progress__bar__58l0x`
};
export default ___CSS_LOADER_EXPORT___;
