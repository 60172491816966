// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_wrapper__0SJYX {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.style_avatar__Na5A0 {
  width: 60px;
  height: 60px;
  border: 2px solid rgb(255, 199, 1);
  border-radius: 100px;
  background: rgb(255, 255, 255);
  display: flex;
  justify-content: center;
  align-items: center;
}
.style_avatar__Na5A0 p {
  font-size: 2.4rem;
  color: rgb(213, 212, 220);
}

.style_username__yIfTx {
  font-size: 2.4rem;
  color: rgb(27, 27, 27);
}

.style_level__3iEhC {
  font-size: 1.4rem;
  color: rgb(117, 83, 240);
  font-weight: 600;
}

.style_uniqueId__ORZG8 {
  display: flex;
  gap: 6px;
  color: rgb(135, 135, 135);
  font-weight: 500;
  font-size: 1.4rem;
}
.style_uniqueId__ORZG8 i {
  font-size: 1.4rem;
  color: rgb(135, 135, 135);
}`, "",{"version":3,"sources":["webpack://./src/components/user-info-badge/style.module.scss","webpack://./public/assets/colors.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;EACA,QAAA;AADF;;AAIA;EACE,WAAA;EACA,YAAA;EACA,kCAAA;EACA,oBAAA;EACA,8BCVM;EDWN,aAAA;EACA,uBAAA;EACA,mBAAA;AADF;AAEE;EACE,iBAAA;EACA,yBCfS;ADeb;;AAIA;EACE,iBAAA;EACA,sBCzBY;ADwBd;;AAIA;EACE,iBAAA;EACA,wBChCK;EDiCL,gBAAA;AADF;;AAIA;EACE,aAAA;EACA,QAAA;EACA,yBCpCU;EDqCV,gBAAA;EACA,iBAAA;AADF;AAEE;EACE,iBAAA;EACA,yBCzCQ;ADyCZ","sourcesContent":["@import 'public/assets/colors';\n\n.wrapper {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  gap: 8px;\n}\n\n.avatar {\n  width: 60px;\n  height: 60px;\n  border: 2px solid $yellow;\n  border-radius: 100px;\n  background: $white;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  p {\n    font-size: 2.4rem;\n    color: $light-grey;\n  }\n}\n\n.username {\n  font-size: 2.4rem;\n  color: $black-extra;\n}\n\n.level {\n  font-size: 1.4rem;\n  color: $main;\n  font-weight: 600;\n}\n\n.uniqueId {\n  display: flex;\n  gap: 6px;\n  color: $dark-grey;\n  font-weight: 500;\n  font-size: 1.4rem;\n  i {\n    font-size: 1.4rem;\n    color: $dark-grey;\n  }\n}\n","$main: rgba(117, 83, 240, 1);\n$light-main: rgba(237, 235, 254, 1);\n$black-extra: rgba(27, 27, 27, 1);\n$dark-grey: rgba(135, 135, 135, 1);\n$light-background: rgba(247, 246, 255, 1);\n$white: rgba(255, 255, 255, 1);\n$light-grey: rgba(213, 212, 220, 1);\n$yellow: rgba(255, 199, 1, 1);\n$grey: rgba(158, 158, 158, 1);\n$light-green: #39b283;\n$green: #1f6b4e;\n$dark-green: #1f4c3a;\n$danger: #c10000;\n$red: #e73232;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `style_wrapper__0SJYX`,
	"avatar": `style_avatar__Na5A0`,
	"username": `style_username__yIfTx`,
	"level": `style_level__3iEhC`,
	"uniqueId": `style_uniqueId__ORZG8`
};
export default ___CSS_LOADER_EXPORT___;
