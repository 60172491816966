import React from 'react';
import classes from './style.module.scss';

interface OnboardingStepsProps {
  steps: Array<string>;
  currentStep: number;
  backgrounds: Array<string>;
  onFinish: () => void;
  changeOnboardingStep: (step: number) => void;
}

const OnboardingSteps: React.FC<OnboardingStepsProps> = ({
  steps,
  currentStep,
  backgrounds,
  onFinish,
  changeOnboardingStep,
}) => {
  const toggleStep = (event: React.MouseEvent<HTMLDivElement>) => {
    const isRightSide = event.clientX > window.innerWidth / 2;

    if (isRightSide) {
      if (currentStep === steps.length - 1) {
        onFinish();
      } else {
        changeOnboardingStep(currentStep + 1);
      }
    } else {
      if (currentStep > 0) {
        changeOnboardingStep(currentStep - 1);
      }
    }
  };

  return (
    <div
      className={classes.steps__container}
      style={{
        height: '100vh',
        width: '100vw',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      }}
      onClick={toggleStep}
    >
      <div
        style={{
          backgroundImage: `url(${backgrounds[currentStep]})`,
          height: '100%',
          width: '100%',
          backgroundSize: '100%',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
        }}
      >
        <div className={classes.stepper}>
          {steps.map((step, index) => (
            <div
              key={index}
              className={`${classes.step} ${index <= currentStep ? classes.active : ''}`}
            ></div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default OnboardingSteps;
