// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_steps__container__T3p7h {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: rgb(255, 255, 255);
  overflow: hidden;
}

.style_background__4vQdl {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  transition: opacity 0.5s ease-in-out;
}

.style_stepper__aaguq {
  position: relative;
  display: flex;
  justify-content: center;
  width: 90%;
  margin: 8px auto;
  gap: 20px;
}

.style_step__JTQJF {
  flex: 1 1;
  background-color: rgb(213, 212, 220);
  border-radius: 2px;
  height: 4px;
  transition: background-color 0.3s;
}

.style_active__CbRM3 {
  background-color: rgb(117, 83, 240);
}`, "",{"version":3,"sources":["webpack://./src/components/onboarding-steps/style.module.scss","webpack://./public/assets/colors.scss"],"names":[],"mappings":"AAEA;EACE,eAAA;EACA,MAAA;EACA,OAAA;EACA,YAAA;EACA,aAAA;EACA,aAAA;EACA,aAAA;EACA,sBAAA;EACA,8BAAA;EACA,oCCPM;EDQN,gBAAA;AADF;;AAIA;EACE,kBAAA;EACA,MAAA;EACA,OAAA;EACA,WAAA;EACA,YAAA;EACA,sBAAA;EACA,2BAAA;EACA,4BAAA;EACA,oCAAA;AADF;;AAIA;EACE,kBAAA;EACA,aAAA;EACA,uBAAA;EACA,UAAA;EACA,gBAAA;EACA,SAAA;AADF;;AAIA;EACE,SAAA;EACA,oCCjCW;EDkCX,kBAAA;EACA,WAAA;EACA,iCAAA;AADF;;AAIA;EACE,mCC9CK;AD6CP","sourcesContent":["@import 'public/assets/colors';\n\n.steps__container {\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100vw;\n  height: 100vh;\n  z-index: 9999;\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  background-color: $white;\n  overflow: hidden;\n}\n\n.background {\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background-size: cover;\n  background-position: center;\n  background-repeat: no-repeat;\n  transition: opacity 0.5s ease-in-out;\n}\n\n.stepper {\n  position: relative;\n  display: flex;\n  justify-content: center;\n  width: 90%;\n  margin: 8px auto;\n  gap: 20px;\n}\n\n.step {\n  flex: 1;\n  background-color: $light-grey;\n  border-radius: 2px;\n  height: 4px;\n  transition: background-color 0.3s;\n}\n\n.active {\n  background-color: $main;\n}\n","$main: rgba(117, 83, 240, 1);\n$light-main: rgba(237, 235, 254, 1);\n$black-extra: rgba(27, 27, 27, 1);\n$dark-grey: rgba(135, 135, 135, 1);\n$light-background: rgba(247, 246, 255, 1);\n$white: rgba(255, 255, 255, 1);\n$light-grey: rgba(213, 212, 220, 1);\n$yellow: rgba(255, 199, 1, 1);\n$grey: rgba(158, 158, 158, 1);\n$light-green: #39b283;\n$green: #1f6b4e;\n$dark-green: #1f4c3a;\n$danger: #c10000;\n$red: #e73232;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"steps__container": `style_steps__container__T3p7h`,
	"background": `style_background__4vQdl`,
	"stepper": `style_stepper__aaguq`,
	"step": `style_step__JTQJF`,
	"active": `style_active__CbRM3`
};
export default ___CSS_LOADER_EXPORT___;
