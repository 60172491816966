import {
  BOOSTS_TYPE,
  TOWER_POWER,
  VAULT_SIZE,
  TREASURE_MAP,
} from '../../api/types';
import { formatReward } from '../../../../utils/utils';
import classes from './style.module.scss';
import BoostItemCurrent from './boost-item-current';
import BoostUpgrade from './boost-upgrade';
import clsx from 'clsx';
import { useState } from 'react';
import BoostItemConfirmation from './boost-confirmation';
import { useUpgradeBoostMutation } from '../../api/boostsApi';
import { useDispatch } from 'react-redux';
import {
  NotificationType,
  showNotification,
} from '../../../../services/notification/notificationSlice';
import { useGetUserQuery } from '../../../../services/user/userApi';
import { User } from '../../../../services/user/types';
import Confetti from 'react-confetti';

interface BoostItemProps {
  user: User;
  boost: TOWER_POWER | VAULT_SIZE | TREASURE_MAP;
  maxLevel: number;
}

const BoostItem = ({ boost, user, maxLevel }: BoostItemProps) => {
  const [upgradeConfirmation, setUpgradeConfirmation] =
    useState<boolean>(false);
  const [showConfetti, setShowConfetti] = useState<boolean>(false);
  const dispatch = useDispatch();

  const [upgradeBoost, { isLoading: upgradeLoading }] =
    useUpgradeBoostMutation();
  const { refetch } = useGetUserQuery();

  const isFinal = !boost.next;

  const onUpgrade = async () => {
    try {
      setUpgradeConfirmation(false);
      await upgradeBoost(boost.boostType);
      await refetch();
      dispatch(
        showNotification({
          message: 'Level successfully upgraded',
          type: NotificationType.SUCCESS,
        }),
      );
      setShowConfetti(true);
      setTimeout(() => setShowConfetti(false), 5000); // Hide confetti after 5 seconds
    } catch (error) {
      console.log('Error', error);
    }
  };

  const renderBoostMessage = () =>
    boost.next && user.level < boost.next.level ? (
      <p className={classes.danger}>Level {user.level + 1} profile required</p>
    ) : null;

  const renderBoostUpgrade = (icon: string, value: string) =>
    boost.current.level !== maxLevel && boost.next ? (
      <BoostUpgrade
        icon={icon}
        boost={boost}
        value={value}
        isDisabled={
          user.level < boost.next.level || user.coinsBalance < boost.next.cost
        }
        isLoading={upgradeConfirmation || upgradeLoading}
        onUpgrade={() => setUpgradeConfirmation(true)}
      />
    ) : null;

  const renderBoostDetails = (
    name: string,
    icon: string,
    currentValue: string,
    title: string,
    image: string,
  ) => (
    <>
      <BoostItemCurrent
        name={name}
        boost={boost}
        maxLevel={maxLevel}
        icon={icon}
        title={currentValue}
        image={image}
      />
      {boost.next && (
        <>
          {renderBoostMessage()}
          {renderBoostUpgrade(icon, title)}
        </>
      )}
    </>
  );

  const renderBoost = () => {
    switch (boost.boostType) {
      case BOOSTS_TYPE.TOWER_POWER:
        return renderBoostDetails(
          'Tower',
          'bow',
          `${boost.current.coinFrequency} sec.`,
          boost.next
            ? `${boost.next.coinFrequency} sec.`
            : `${boost.current.coinFrequency} sec.`,
          'tower',
        );

      case BOOSTS_TYPE.VAULT_SIZE:
        return renderBoostDetails(
          'Vault size',
          'bag',
          `${boost.current.storageCapacity}`,
          boost.next
            ? formatReward(boost.next.storageCapacity)
            : `${boost.current.storageCapacity}`,
          'vault',
        );

      default:
        return null;
    }
  };

  return (
    <div className={clsx(classes.boost, { [classes.boost__final]: isFinal })}>
      {renderBoost()}
      {upgradeConfirmation && (
        <BoostItemConfirmation
          isVisible={upgradeConfirmation}
          boost={boost}
          onClose={() => setUpgradeConfirmation(false)}
          onConfirm={onUpgrade}
        />
      )}
      {showConfetti && (
        <Confetti
          width={window.innerWidth}
          height={window.innerHeight}
          numberOfPieces={800}
          recycle={false}
          gravity={0.4}
          friction={0.99}
          initialVelocityX={10}
          initialVelocityY={20}
          tweenDuration={4000}
          style={{ position: 'fixed', zIndex: 9999 }}
        />
      )}
    </div>
  );
};

export default BoostItem;
