import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { store } from './services/store';
import Popover from './components/popovers/popover';
import Notification from './components/notification';
import Loader from './components/loader';
import Confirmation from './components/confirmation';
import Achievement from './components/achievement';
import AchievementReward from './components/achievement-reward';
import Game from './pages/game';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
      <Popover />
      <Notification />
      <Loader />
      <AchievementReward />
      <Confirmation />
      <Achievement />
      <Game />
    </Provider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
