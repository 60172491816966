import { useParams } from 'react-router-dom';
import { useGetUserQuery } from '../../../services/user/userApi';
import {
  useGetAllTimeLeaderboardQuery,
  useGetClanQuery,
  useGetCurrentLeaderboardQuery,
} from '../../clans-war/api/clansWarApi';
import classes from './style.module.scss';
import Footer from '../../../components/footer';
import LeaveClan from './components/leave-clan';
import { formatTotalCoins } from '../../../utils/utils';
import Rules from './components/rules';
import HallOfFame from './components/hall-of-fame';
import Leaderboard from './components/leaderboard';

const Clan = () => {
  const { id } = useParams();
  const { data: user } = useGetUserQuery();
  const { data: clan } = useGetClanQuery(id!, {
    skip: !id,
    refetchOnMountOrArgChange: true,
  });

  const { data: currentLeaderboard } = useGetCurrentLeaderboardQuery(id!, {
    skip: !id,
    refetchOnMountOrArgChange: true,
  });

  const { data: allTimeLeaderboard } = useGetAllTimeLeaderboardQuery(id!, {
    skip: !id,
    refetchOnMountOrArgChange: true,
  });

  const hasClanData =
    clan &&
    clan.totalPoints !== undefined &&
    clan.timeLeftMS !== undefined &&
    clan.week !== undefined &&
    clan.hallOfFame !== undefined;

  return (
    <div className={classes.page}>
      {clan && (
        <>
          {hasClanData ? (
            <>
              <div className={classes.clan}>
                <div className={classes.clan__image}>
                  <img
                    src={
                      process.env.REACT_APP_API_BASE_URL +
                      '/images/clans/logos/' +
                      clan.logoFilename
                    }
                    alt="clan logo"
                  />
                </div>
                <h2 className={classes.clan__title}>
                  {clan.name.toUpperCase()} CLAN
                </h2>
                <div className={classes.divider}></div>
                <p className={classes.clan__members}>
                  <i className="icon-person"></i>
                  {clan.members}
                </p>
                <div className={classes.divider}></div>
              </div>
              <div
                className={classes.section}
                style={{ backgroundColor: clan.coloringSchemaHEX }}
              >
                <div className={classes.content}>
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/clan/sword-left.svg`}
                  />
                  <div className={classes.content__text}>
                    <h3 className={classes.content__text__week}>
                      Week {clan.week}
                    </h3>
                    <div className={classes.content__text__block}>
                      <p>Current place</p>
                      <h4>
                        {clan.place}
                        {clan.place == 1 && 'st'}
                        {clan.place == 2 && 'nd'}
                        {clan.place == 3 && 'rd'}
                      </h4>
                    </div>
                    <div className={classes.content__text__block}>
                      <p>Points</p>
                      <h4>{formatTotalCoins(clan.totalPoints ?? 0)}</h4>
                    </div>
                  </div>
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/clan/sword-right.svg`}
                  />
                </div>
                <Rules timeLeft={clan.timeLeftMS ?? 0} week={clan.week ?? 0} />
              </div>
              <div
                className={classes.section}
                style={{ backgroundColor: clan.coloringSchemaHEX }}
              >
                <HallOfFame data={clan.hallOfFame ?? []} clanName={clan.name} />
              </div>
              {user && currentLeaderboard && allTimeLeaderboard && (
                <Leaderboard
                  mainColor={clan.coloringSchemaHEX}
                  currentLeaderboard={currentLeaderboard}
                  allTimeLeaderboard={allTimeLeaderboard}
                />
              )}
            </>
          ) : (
            <>
              <div className={classes.clan}>
                <div className={classes.clan__image}>
                  <img
                    src={
                      process.env.REACT_APP_API_BASE_URL +
                      '/images/clans/logos/' +
                      clan.logoFilename
                    }
                    alt="clan logo"
                  />
                </div>
                <h2 className={classes.clan__title}>
                  {clan.name.toUpperCase()} CLAN
                </h2>
                <div className={classes.divider}></div>
                <p className={classes.clan__members}>
                  <i className="icon-person"></i>
                  {clan.members}
                </p>
                <div className={classes.divider}></div>
              </div>
              <div
                className={classes.section}
                style={{ backgroundColor: clan.coloringSchemaHEX }}
              >
                <div className={classes.content}>
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/clan/sword-left.svg`}
                  />
                  <h3 className={classes.content__title}>COMING SOON</h3>
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/clan/sword-right.svg`}
                  />
                </div>
                <p className={classes.content__description}>
                  Clan wars are coming soon!
                </p>
              </div>
            </>
          )}
        </>
      )}
      {user && clan && (
        <>
          <LeaveClan user={user} userClan={clan} />
          <Footer />
        </>
      )}
    </div>
  );
};

export default Clan;
