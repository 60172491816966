import clsx from 'clsx';
import { useDispatch } from 'react-redux';
import Button, {
  ButtonSize,
  ButtonType,
  ButtonVariant,
} from '../../../../components/button';
import {
  DailyStreak,
  DailyTask,
  TaskDay,
  useClaimDailyTaskMutation,
  useGetDailyTaskQuery,
} from '../../../../services/tasks/tasksApi';
import classes from './style.module.scss';
import {
  GtagEventType,
  trackGtagEvent,
} from '../../../../utils/gtagEventHandler';
import { showAchievement } from '../../../../services/achievement/achievementSlice';
import {
  NotificationType,
  showNotification,
} from '../../../../services/notification/notificationSlice';
import { useEffect, useState } from 'react';
import { calculateTimeToNextDayUTC } from '../../../../utils/utils';
import CuberiumDailyTask from './cuberium-daily-task';
import { User } from '../../../../services/user/types';
import { Howl } from 'howler';

interface CuberiumDailyStreakProps {
  user: User;
  dailyStreak: DailyStreak;
}
const CuberiumDailyStreak = ({
  user,
  dailyStreak,
}: CuberiumDailyStreakProps) => {
  const dispatch = useDispatch();
  const [claimDailyTask, { isLoading: claimDailyLoading }] =
    useClaimDailyTaskMutation();

  const todayReward = dailyStreak.tasks.find(
    (task) => task.day === TaskDay.TODAY,
  );

  const { refetch: refetchDailyTask } = useGetDailyTaskQuery();

  const [timeToNextDay, setTimeToNextDay] = useState(
    calculateTimeToNextDayUTC(),
  );

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeToNextDay(calculateTimeToNextDayUTC());
      if (timeToNextDay === '00:00:00') {
        refetchDailyTask();
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [timeToNextDay, refetchDailyTask]);

  useEffect(() => {
    if (timeToNextDay === '00:00:00') {
      refetchDailyTask();
    }
  }, [timeToNextDay, refetchDailyTask]);

  const claimDaily = async () => {
    if (dailyStreak.canClaimToday) {
      try {
        await claimDailyTask().unwrap();
        await trackGtagEvent(GtagEventType.CHECKIN);
        if (user.soundEnabled) {
          const claimSound = new Howl({
            src: [`${process.env.PUBLIC_URL}/assets/audio/claim.mp3`],
          });
          claimSound.play();
        }
        dispatch(
          showAchievement({
            title: 'REWARD',
            amount: todayReward?.coinsReward || 1000,
            xp: todayReward?.xpReward || 1000,
          }),
        );
      } catch (error) {
        dispatch(
          showNotification({
            message: 'Something went wrong',
            type: NotificationType.ERROR,
          }),
        );
      }
    }
  };
  return (
    <div className={classes.daily}>
      <div className={classes.daily__head}>
        <div className={classes.daily__head__part}>
          <i className={`icon-task-list ${classes.daily__head__icon}`}></i>
          <div className={classes.daily__head__content}>
            <h3>{dailyStreak.title}</h3>
            <p>{dailyStreak.subtitle}</p>
          </div>
        </div>
        <div className={classes.daily__head__button}>
          {dailyStreak.dailyCheckInStreak >= 6 && dailyStreak.canClaimToday && (
            <div className={classes.daily__head__sunshine}>
              <img
                src={`${process.env.PUBLIC_URL}/assets/images/reward/sunshine.svg`}
              />
            </div>
          )}
          <div
            className={clsx({
              [classes.daily__head__action]: dailyStreak.canClaimToday,
              [classes.daily__head__timer]: !dailyStreak.canClaimToday,
            })}
          >
            {dailyStreak.canClaimToday ? (
              <Button
                type={ButtonType.PRIMARY}
                variant={
                  dailyStreak.canClaimToday
                    ? ButtonVariant.ORANGE
                    : ButtonVariant.GREY
                }
                size={ButtonSize.SMALL}
                isLoading={claimDailyLoading}
                title={'CLAIM'}
                onClick={claimDaily}
                isDisabled={false}
              />
            ) : (
              <div className={classes.daily__requirements}>
                <p className={classes.daily__requirements__title}>Next in</p>
                <p className={classes.daily__requirements__time}>
                  {timeToNextDay}
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className={classes.daily__divider} />
      <div className={classes.daily__tasks}>
        {dailyStreak.tasks.map((task: DailyTask) => {
          return (
            <CuberiumDailyTask
              key={task.title}
              task={task}
              canClaim={dailyStreak.canClaimToday}
              streak={dailyStreak.dailyCheckInStreak}
            />
          );
        })}
      </div>
      <div className={classes.daily__description}>
        <p className={classes.daily__description__text}>
          {dailyStreak.dailyCheckInStreak > 6
            ? 'Сheck-in master! Grab 50 000 coins daily.'
            : 'Check-in 7 days in a row and grab 155 000 coins.'}
        </p>
        <p className={classes.daily__description__text}>
          If you miss a day, you will have to start over.
        </p>
      </div>
    </div>
  );
};

export default CuberiumDailyStreak;
