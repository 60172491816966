import { configureStore } from '@reduxjs/toolkit';
import { authApi } from './auth/authApi';
import authReducer from './auth/authSlice';
import userReducer from './user/userSlice';
import { userApi } from './user/userApi';
import { referralApi } from '../features/referrals/api/referralApi';
import popoverReducer from './popover/popoverSlice';
import notificationReducer from './notification/notificationSlice';
import loaderReducer from './loader/loaderSlice';
import { emailApi } from '../features/email/api/emailApi';
import { tasksApi } from './tasks/tasksApi';
import rewardReducer from './reward/rewardSlice';
import confirmationReducer from './confirmation/confirmationSlice';
import achievementSlice from './achievement/achievementSlice';
import achievementRewardSlice from './achievement-reward/achievementRewardSlice';
import { boostsApi } from '../features/boosts/api/boostsApi';
import navigationSlice from './navigation/navigationSlice';
import { gameApi } from '../features/tower/api/gameApi';
import { clansApi } from '../features/clans/api/clansApi';
import { clansWarApi } from '../features/clans-war/api/clansWarApi';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    user: userReducer,
    popover: popoverReducer,
    notification: notificationReducer,
    loader: loaderReducer,
    reward: rewardReducer,
    confirmation: confirmationReducer,
    achievement: achievementSlice,
    achievementReward: achievementRewardSlice,
    navigation: navigationSlice,
    [authApi.reducerPath]: authApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [referralApi.reducerPath]: referralApi.reducer,
    [emailApi.reducerPath]: emailApi.reducer,
    [tasksApi.reducerPath]: tasksApi.reducer,
    [boostsApi.reducerPath]: boostsApi.reducer,
    [gameApi.reducerPath]: gameApi.reducer,
    [clansApi.reducerPath]: clansApi.reducer,
    [clansWarApi.reducerPath]: clansWarApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(authApi.middleware)
      .concat(userApi.middleware)
      .concat(emailApi.middleware)
      .concat(tasksApi.middleware)
      .concat(referralApi.middleware)
      .concat(boostsApi.middleware)
      .concat(clansApi.middleware)
      .concat(gameApi.middleware)
      .concat(clansWarApi.middleware),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
