import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReauth } from '../../../services/baseQuery';
import { ClansResponse, MessageResponse } from './types';

const BASE_URL = '/clans';

export const clansApi = createApi({
  reducerPath: 'clansApi',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['clans'],
  endpoints: (builder) => ({
    getClans: builder.query<ClansResponse, void>({
      query: () => ({
        url: `${BASE_URL}`,
      }),
      providesTags: ['clans'],
    }),

    joinClan: builder.mutation<MessageResponse, string>({
      query: (id) => ({
        url: `${BASE_URL}/join/${id}`,
        method: 'PATCH',
      }),
      invalidatesTags: ['clans'],
    }),
    leaveClan: builder.mutation<MessageResponse, void>({
      query: () => ({
        url: `${BASE_URL}/leave`,
        method: 'PATCH',
      }),
      invalidatesTags: ['clans'],
    }),
  }),
});

export const { useGetClansQuery, useJoinClanMutation, useLeaveClanMutation } =
  clansApi;
