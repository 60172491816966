import classes from './style.module.scss';
import { useSelector } from 'react-redux';
import { RootState } from '../../services/store';

const Loader = () => {
  const loading = useSelector((state: RootState) => state.loader.loading);

  if (!loading) {
    return null;
  }

  return (
    <div className={classes.loader}>
      <img
        src={`${process.env.PUBLIC_URL}/assets/images/logo.svg`}
        alt="Logo"
        className={classes.loader__image}
      />
      <img
        src={`${process.env.PUBLIC_URL}/assets/images/logo-text.svg`}
        alt="Logo text"
        className={classes.loader__image__text}
      />
    </div>
  );
};

export default Loader;
