// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_rules__uCVaL {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px 0;
  gap: 12px;
}
.style_rules__description__GW-P5 {
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 17px;
  color: rgba(255, 255, 255, 0.75);
  text-align: center;
  max-width: 300px;
}
.style_rules__reward__Gjm0a {
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 18px;
}
.style_rules__finished__title__WIzOm {
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 19.5px;
  text-align: center;
  color: rgb(255, 255, 255);
}
.style_rules__finished__subtitle__nT5wW {
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 16.8px;
  text-align: center;
  color: rgb(255, 255, 255);
}`, "",{"version":3,"sources":["webpack://./src/features/clans/clan/components/rules/style.module.scss","webpack://./public/assets/colors.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,eAAA;EACA,SAAA;AADF;AAEE;EACE,iBAAA;EACA,gBAAA;EACA,iBAAA;EACA,gCAAA;EACA,kBAAA;EACA,gBAAA;AAAJ;AAGE;EACE,iBAAA;EACA,gBAAA;EACA,iBAAA;AADJ;AAKI;EACE,iBAAA;EACA,gBAAA;EACA,mBAAA;EACA,kBAAA;EACA,yBCxBE;ADqBR;AAMI;EACE,iBAAA;EACA,gBAAA;EACA,mBAAA;EACA,kBAAA;EACA,yBChCE;AD4BR","sourcesContent":["@import 'public/assets/colors';\n\n.rules {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  padding: 16px 0;\n  gap: 12px;\n  &__description {\n    font-size: 1.4rem;\n    font-weight: 500;\n    line-height: 17px;\n    color: rgba(255, 255, 255, 0.75);\n    text-align: center;\n    max-width: 300px;\n  }\n\n  &__reward {\n    font-size: 1.8rem;\n    font-weight: 700;\n    line-height: 18px;\n  }\n\n  &__finished {\n    &__title {\n      font-size: 1.6rem;\n      font-weight: 600;\n      line-height: 19.5px;\n      text-align: center;\n      color: $white;\n    }\n\n    &__subtitle {\n      font-size: 1.4rem;\n      font-weight: 500;\n      line-height: 16.8px;\n      text-align: center;\n      color: $white;\n    }\n  }\n}\n","$main: rgba(117, 83, 240, 1);\n$light-main: rgba(237, 235, 254, 1);\n$black-extra: rgba(27, 27, 27, 1);\n$dark-grey: rgba(135, 135, 135, 1);\n$light-background: rgba(247, 246, 255, 1);\n$white: rgba(255, 255, 255, 1);\n$light-grey: rgba(213, 212, 220, 1);\n$yellow: rgba(255, 199, 1, 1);\n$grey: rgba(158, 158, 158, 1);\n$light-green: #39b283;\n$green: #1f6b4e;\n$dark-green: #1f4c3a;\n$danger: #c10000;\n$red: #e73232;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"rules": `style_rules__uCVaL`,
	"rules__description": `style_rules__description__GW-P5`,
	"rules__reward": `style_rules__reward__Gjm0a`,
	"rules__finished__title": `style_rules__finished__title__WIzOm`,
	"rules__finished__subtitle": `style_rules__finished__subtitle__nT5wW`
};
export default ___CSS_LOADER_EXPORT___;
