import classes from './style.module.scss';

interface RibbonProps {
  title: string;
  subtitle: string;
}

const Ribbon = ({ title, subtitle }: RibbonProps) => {
  return (
    <div className={classes.ribbon}>
      <div className={classes.ribbon__left}></div>
      <div className={classes.ribbon__content}>
        <p className={classes.ribbon__content__title}>{title}</p>
        <p className={classes.ribbon__content__subtitle}>{subtitle}</p>
      </div>
      <div className={classes.ribbon__right}></div>
    </div>
  );
};

export default Ribbon;
