import clsx from 'clsx';
import classes from './style.module.scss';

export enum ShareButtonVariant {
  TELEGRAM = 'Telegram',
  X = 'X',
}

interface ShareButtonProps {
  variant: ShareButtonVariant;
  onClick: () => void;
}
const ShareButton = ({ variant, onClick }: ShareButtonProps) => {
  return (
    <button
      className={clsx(
        classes.button,
        classes[`button-${variant.toLowerCase()}`],
      )}
      type="button"
      onClick={onClick}
    >
      <div className={classes.button__content}>
        <i
          className={`${classes.button__icon} icon-${variant.toLowerCase()}`}
        />
        <span>Share and get extra</span>
        <img src={`${process.env.PUBLIC_URL}/assets/images/coin-m.svg`} />
        <span>10 000</span>
      </div>
    </button>
  );
};

export default ShareButton;
