import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReauth } from '../../../services/baseQuery';
import { ClanFullInfo, ClanWar, ClanWarResult, Leaderboard } from './types';

const BASE_URL = '/clan';
export const clansWarApi = createApi({
  reducerPath: 'clansWarApi',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['wars'],
  endpoints: (builder) => ({
    getWarLeaderboard: builder.query<ClanWar, void>({
      query: () => ({
        url: `${BASE_URL}/wars/clans_leaderboard`,
      }),
    }),
    getClan: builder.query<ClanFullInfo, string>({
      query: (id) => ({
        url: `/clan/wars/${id}`,
      }),
      providesTags: ['wars'],
    }),
    getCurrentLeaderboard: builder.query<Leaderboard, string>({
      query: (id) => ({
        url: `/clan/wars/user_leaderboard/${id}`,
      }),
      providesTags: ['wars'],
    }),
    getAllTimeLeaderboard: builder.query<Leaderboard, string>({
      query: (id) => ({
        url: `/clan/wars/user_leaderboard_all/${id}`,
      }),
      providesTags: ['wars'],
    }),

    getClanWarsResults: builder.query<ClanWarResult, void>({
      query: () => ({
        url: `/clan/wars/results`,
      }),
      providesTags: ['wars'],
    }),
  }),
});

export const {
  useGetWarLeaderboardQuery,
  useGetClanQuery,
  useGetCurrentLeaderboardQuery,
  useGetAllTimeLeaderboardQuery,
  useLazyGetClanWarsResultsQuery,
} = clansWarApi;
