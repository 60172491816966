import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReauth } from '../baseQuery';

const BASE_URL = '/tasks';
export enum TaskSocialNetworkType {
  IN_APP = 'InApp',
  ExternalResource = 'External Resource',
  TWITTER = 'Twitter',
  TELEGRAM = 'Telegram',
  DISCORD = 'Discord',
}

export enum TaskActionType {
  EMAIL = 'email',
  DISCORD = 'link_discord',
  LEVEL_UP = 'level_up',
  REFERRAL_QUANTITY = 'referral_quantity',
  VIEW_STORY = 'view_story',
  SURVEY = 'survey',
  VISIT_WEBSITE = 'visit_website',
  NO_INFO = 'no_info',
  JOIN_GUILD = 'join_guild',
  JOIN_CHANNEL = 'join_channel',
  KILL_BOSSES = 'kill_bosses',
  EXPLODE_BOMBS = 'explode_bombs',
  MUSHROOMS_COLLECT = 'collect_mushrooms',
  MUSHROOMS_STREAK = 'get_mushrooms_streak',
}

interface Resource {
  resourceName: string;
}

export interface Task {
  _id: string;
  coinsReward: number;
  xpReward: number;
  isActive: boolean;
  socialNetworkType: TaskSocialNetworkType;
  actionType: TaskActionType;
  title: string;
  link: string;
  createdAt: string;
  partnerSection: string;
  updatedAt: string;
  trackId?: string;
}

interface Story {
  image: string;
  title: string;
}

export interface ExternalResource extends Task {
  data: Resource;
}
export interface PartnerTask extends Task {
  data: {
    logoFilename: string;
    resourceName: string;
  };
}

export interface TaskStories extends Task {
  data: Array<Story>;
}

export interface SurveyAnswer {
  answer: string;
  image: string;
  replyCount: number;
}
export interface SurveyQuestion {
  title: string;
  question: string;
  answers: Array<SurveyAnswer>;
}

export interface TaskSurvey extends Task {
  data: SurveyQuestion;
}

export interface ClaimTaskResponse {
  completedTasks: Array<any>;
  coinsBalance: number;
  totalXp: number;
  updatedAt: string;
}

export interface DailyTask {
  coinsReward: number;
  xpReward: number;
  isActive: boolean;
  title: string;
  canClaimToday: boolean;
}

export enum TaskDay {
  YESTERDAY = 'yesterday',
  TODAY = 'today',
  TOMORROW = 'tomorrow',
  DAY_AFTER_TOMORROW = 'day-after-tomorrow',
}

export enum TaskStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  CLAIMED = 'claimed',
}

export interface DailyTask {
  day: TaskDay;
  coinsReward: number;
  xpReward: number;
  title: string;
  status: TaskStatus;
}

export interface DailyStreak {
  canClaimToday: boolean;
  description: string;
  isShinyCheckInButton: boolean;
  dailyCheckInStreak: number;
  title: string;
  subtitle: string;
  tasks: Array<DailyTask>;
}

export const tasksApi = createApi({
  reducerPath: 'tasksApi',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['tasks', 'user', 'partnerTasks'],
  endpoints: (builder) => ({
    getTasksInfo: builder.query<Array<Task>, void>({
      query: () => ({
        url: `${BASE_URL}/active`,
      }),
      providesTags: ['tasks'],
    }),
    getPartnersTasks: builder.query<Array<PartnerTask>, void>({
      query: () => ({
        url: `${BASE_URL}/partner`,
      }),
      providesTags: ['partnerTasks'],
    }),
    claimTask: builder.mutation<ClaimTaskResponse, string>({
      query: (id) => ({
        url: `${BASE_URL}/claim/${id}`,
        method: 'PATCH',
      }),
      invalidatesTags: ['user'],
    }),
    completeTask: builder.mutation<any, string>({
      query: (id) => ({
        url: `${BASE_URL}/add_completed/${id}`,
        method: 'PATCH',
      }),
      invalidatesTags: ['user'],
    }),
    completeTaskQuiz: builder.mutation<
      any,
      { id: string; answers: Array<string> }
    >({
      query: (payload) => ({
        url: `${BASE_URL}/add_completed/${payload.id}`,
        method: 'PATCH',
        body: {
          answers: payload.answers,
        },
      }),
      invalidatesTags: ['user'],
    }),
    claimAchievementTask: builder.mutation<void, string>({
      query: (id) => ({
        url: `${BASE_URL}/claim_share_achievement/${id}`,
        method: 'PATCH',
      }),
      invalidatesTags: ['user'],
    }),
    getDailyTask: builder.query<DailyStreak, void>({
      query: () => ({
        url: `${BASE_URL}/daily`,
      }),
      providesTags: ['tasks'],
    }),

    claimDailyTask: builder.mutation<void, void>({
      query: () => ({
        url: `${BASE_URL}/claim_daily`,
        method: 'PATCH',
      }),
      invalidatesTags: ['tasks'],
    }),
  }),
});

export const {
  useGetTasksInfoQuery,
  useGetPartnersTasksQuery,
  useClaimTaskMutation,
  useCompleteTaskMutation,
  useCompleteTaskQuizMutation,
  useClaimAchievementTaskMutation,
  useGetDailyTaskQuery,
  useClaimDailyTaskMutation,
} = tasksApi;
