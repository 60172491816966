// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_restricted__VFMqV {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-size: 1.6rem;
  background: rgb(247, 246, 255);
  width: 100vw;
  height: 100vh;
}`, "",{"version":3,"sources":["webpack://./src/pages/restricted-device/style.module.scss"],"names":[],"mappings":"AAAA;EACE,SAAA;EACA,UAAA;EACA,sBAAA;EACA,iBAAA;EACA,8BAAA;EACA,YAAA;EACA,aAAA;AACF","sourcesContent":[".restricted {\n  margin: 0;\n  padding: 0;\n  box-sizing: border-box;\n  font-size: 1.6rem;\n  background: rgba(247, 246, 255, 1);\n  width: 100vw;\n  height: 100vh;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"restricted": `style_restricted__VFMqV`
};
export default ___CSS_LOADER_EXPORT___;
