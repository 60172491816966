import Button, {
  ButtonSize,
  ButtonType,
  ButtonVariant,
} from '../../../../../components/button';
import PopoverAnimatedWrapper from '../../../../../components/popovers/components/popover-animated-wrapper';
import PopoverDescription from '../../../../../components/popovers/components/popover-description';
import PopoverTitle from '../../../../../components/popovers/components/popover-title';
import {
  BOOSTS_TYPE,
  TOWER_POWER,
  TREASURE_MAP,
  VAULT_SIZE,
} from '../../../api/types';
import { formatReward } from '../../../../../utils/utils';
import classes from './style.module.scss';
import {
  boostConfirmationContent,
  isTowerPower,
  isTreasureMap,
  isVaultSize,
} from './utils';

interface BoostItemConfirmationProps {
  isVisible: boolean;
  boost: TOWER_POWER | TREASURE_MAP | VAULT_SIZE;
  onClose: () => void;
  onConfirm: () => void;
}

const BoostItemConfirmation = ({
  isVisible,
  boost,
  onClose,
  onConfirm,
}: BoostItemConfirmationProps) => {
  if (!isVisible) return null;

  let content;
  let description;

  if (isTowerPower(boost)) {
    content = boostConfirmationContent[BOOSTS_TYPE.TOWER_POWER];
    description = content.description(boost);
  } else if (isVaultSize(boost)) {
    content = boostConfirmationContent[BOOSTS_TYPE.VAULT_SIZE];
    description = content.description(boost);
  } else if (isTreasureMap(boost)) {
    content = boostConfirmationContent[BOOSTS_TYPE.TREASURE_MAP];
    description = content.description(boost);
  } else {
    return null;
  }

  return (
    <PopoverAnimatedWrapper isVisible={isVisible}>
      <div className={classes.confirmation__image}>
        <img
          src={`${process.env.PUBLIC_URL}/assets/images/boosts/${content.image}.svg`}
          alt="Unlink"
        />
      </div>
      <PopoverTitle title={content.title} />
      <PopoverDescription description={description} />
      <div className={classes.confirmation__price}>
        <p>Price: </p>
        <img
          className={classes.confirmation__image}
          src={`${process.env.PUBLIC_URL}/assets/images/boosts/coin-m.svg`}
          alt={`coin icon`}
        />
        <p>{formatReward(boost.next!.cost)}</p>
      </div>
      <div className={classes.confirmation__actions}>
        <Button
          type={ButtonType.PRIMARY}
          variant={ButtonVariant.GREY}
          size={ButtonSize.SMALL}
          title="CANCEL"
          onClick={onClose}
        />
        <Button
          type={ButtonType.PRIMARY}
          variant={ButtonVariant.GREEN}
          size={ButtonSize.SMALL}
          title="UPGRADE"
          onClick={onConfirm}
        />
      </div>
    </PopoverAnimatedWrapper>
  );
};

export default BoostItemConfirmation;
