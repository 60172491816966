export const getInitial = (name: string): string => {
  return name.charAt(0).toUpperCase();
};

export const roundAmount = (amount: number): number => {
  return Math.floor(amount);
};

export const calculateTimeSpent = (time: string): string => {
  const startTime = new Date(time);
  const currentTime = new Date();
  const totalMinutes = Math.floor(
    (currentTime.getTime() - startTime.getTime()) / (1000 * 60),
  );
  const days = Math.floor(totalMinutes / (60 * 24));
  const hours = Math.floor((totalMinutes % (60 * 24)) / 60);

  let result = '';
  if (days > 0) {
    result += `${days}d `;
  }
  result += `${hours}h`;

  return result.trim();
};

export const colors = [
  '#21B7C8',
  '#136CD1',
  '#BC517C',
  '#345E47',
  '#B3D618',
  '#13C377',
  '#602F6A',
  '#4D8B1E',
  '#3A21AA',
  '#0C6C8F',
  '#0F3F62',
  '#7553F0',
  '#929C00',
  '#AB5600',
  '#C954A2',
  '#C70000',
  '#FBC000',
  '#8C8C8C',
];

export const getColorForInitial = (initial: string): string => {
  const upperInitial = initial.toUpperCase();
  const index = upperInitial.charCodeAt(0) - 'A'.charCodeAt(0);
  if (index >= 0 && index < 26) {
    return colors[index % colors.length];
  }
  return colors[colors.length - 1];
};

export const formatReward = (value: number) => {
  if (value >= 10000) {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  }
  return value.toString();
};

export const formatCoins = (num: number): string => {
  return Math.floor(num)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
};

export const calculateTimeToNextDayUTC = () => {
  const now = new Date();

  const nextDay = new Date(
    Date.UTC(
      now.getUTCFullYear(),
      now.getUTCMonth(),
      now.getUTCDate() + 1,
      0,
      0,
      0,
    ),
  );

  const diff = nextDay.getTime() - now.getTime();

  const hours = Math.floor((diff / (1000 * 60 * 60)) % 24);
  const minutes = Math.floor((diff / (1000 * 60)) % 60);
  const seconds = Math.floor((diff / 1000) % 60);

  return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
};

type IconType =
  | 'timer'
  | 'swords'
  | 'boss'
  | 'mushroom'
  | 'ref'
  | 'bomb'
  | 'outline-flag'
  | 'logout'
  | 'sound_off'
  | 'sound_on'
  | 'crown'
  | 'task-list'
  | 'bow'
  | 'chest'
  | 'bag'
  | 'boosts'
  | 'telegram'
  | 'medium'
  | 'notification'
  | 'letter'
  | 'logo'
  | 'coins'
  | 'discord'
  | 'close'
  | 'next'
  | 'prev'
  | 'x'
  | 'email'
  | 'warning'
  | 'check'
  | 'invite'
  | 'copy'
  | 'person'
  | 'tower'
  | 'lock'
  | 'linkedin'
  | 'refers';

export const getIcon = (icon: string): string => {
  const availableIcons: IconType[] = [
    'timer',
    'swords',
    'boss',
    'mushroom',
    'ref',
    'bomb',
    'outline-flag',
    'logout',
    'sound_off',
    'sound_on',
    'crown',
    'task-list',
    'bow',
    'chest',
    'bag',
    'boosts',
    'telegram',
    'medium',
    'notification',
    'letter',
    'logo',
    'coins',
    'discord',
    'close',
    'next',
    'prev',
    'x',
    'email',
    'warning',
    'check',
    'invite',
    'copy',
    'person',
    'tower',
    'lock',
    'refers',
    'linkedin',
  ];

  return availableIcons.includes(icon as IconType) ? `${icon}` : 'web';
};

export const convertMSToReadableTime = (milliseconds: number): string => {
  const millisecondsInOneSecond = 1000;
  const millisecondsInOneMinute = 60 * millisecondsInOneSecond;
  const millisecondsInOneHour = 60 * millisecondsInOneMinute;
  const millisecondsInOneDay = 24 * millisecondsInOneHour;

  const days = Math.floor(milliseconds / millisecondsInOneDay);
  milliseconds %= millisecondsInOneDay;

  const hours = Math.floor(milliseconds / millisecondsInOneHour);
  milliseconds %= millisecondsInOneHour;

  const minutes = Math.floor(milliseconds / millisecondsInOneMinute);
  milliseconds %= millisecondsInOneMinute;

  const seconds = Math.floor(milliseconds / millisecondsInOneSecond);

  const parts: string[] = [];

  if (days > 0) {
    parts.push(`${days}days`);
  }
  if (hours > 0) {
    parts.push(`${hours}h`);
  }
  if (minutes > 0) {
    parts.push(`${minutes}m`);
  }

  if (parts.length === 0) {
    parts.push(`${seconds} second${seconds > 1 ? 's' : ''}`);
  }

  return parts.join(' ');
};

export const formatTotalCoins = (
  num: number,
  hideDivider?: boolean,
): string => {
  if (num < 1000) {
    return num.toString();
  }

  const suffixes = ['', 'K', 'M', 'B', 'T'];
  const tier = Math.floor(Math.log10(num) / 3);
  const suffix = suffixes[tier];
  const scale = Math.pow(10, tier * 3);

  const scaled = num / scale;

  let formatted: string;
  if (scaled < 10) {
    formatted = scaled.toFixed(2);
  } else if (scaled < 100) {
    formatted = scaled.toFixed(2);
  } else {
    formatted = scaled.toFixed(2);
  }

  formatted = formatted.replace('.', ',');

  if (formatted.endsWith(',00')) {
    formatted = formatted.split(',')[0];
  } else if (formatted.endsWith('0')) {
    formatted = formatted.slice(0, -1);
  }

  return `${formatted}${hideDivider ? '' : ' '}${suffix}`;
};

export const maskUsername = (input: string): string => {
  if (input.length <= 3) {
    return input + '*****';
  }

  const firstThreeLetters = input.slice(0, 3);
  return firstThreeLetters + '*****';
};

export const levels = [
  0, 100000, 350000, 850000, 1850000, 3850000, 6850000, 10850000, 15850000,
  21850000,
];
