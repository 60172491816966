import { useSelector, useDispatch } from 'react-redux';
import classes from './style.module.scss';
import { RootState } from '../../services/store';
import { hideConfirmation } from '../../services/confirmation/confirmationSlice';
import Button, { ButtonSize, ButtonType, ButtonVariant } from '../button';
import PopoverAnimatedWrapper from '../popovers/components/popover-animated-wrapper';
import PopoverDescription from '../popovers/components/popover-description';
import PopoverTitle from '../popovers/components/popover-title';

const confirmationDescription =
  'By unlinking your account you will not be able to receive bonuses and participate in new activities';

const Confirmation = () => {
  const dispatch = useDispatch();
  const { isVisible, mainAction } = useSelector(
    (state: RootState) => state.confirmation,
  );

  const unlink = () => {
    mainAction();
    handleClose();
  };

  const handleClose = () => {
    setTimeout(() => dispatch(hideConfirmation()), 100);
  };

  if (!isVisible) return null;

  return (
    <PopoverAnimatedWrapper isVisible={isVisible}>
      <div className={classes.confirmation__image}>
        <img
          src={`${process.env.PUBLIC_URL}/assets/images/unlink.svg`}
          alt="Unlink"
        />
      </div>
      <PopoverTitle title="UNLINK PROFILE" />
      <PopoverDescription description={confirmationDescription} />
      <div className={classes.confirmation__actions}>
        <Button
          type={ButtonType.PRIMARY}
          variant={ButtonVariant.GREY}
          size={ButtonSize.LARGE}
          title="UNLINK"
          onClick={unlink}
        />
        <Button
          type={ButtonType.PRIMARY}
          variant={ButtonVariant.ORANGE}
          size={ButtonSize.LARGE}
          title="CANCEL"
          onClick={handleClose}
        />
      </div>
    </PopoverAnimatedWrapper>
  );
};

export default Confirmation;
