// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_page__EH9Ds {
  padding: 12px 16px 100px 16px;
  width: 100vw;
  min-height: 100vh;
  background: rgb(247, 246, 255);
  text-align: center;
  color: rgb(255, 255, 255);
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}`, "",{"version":3,"sources":["webpack://./src/components/layouts/page/style.module.scss","webpack://./public/assets/colors.scss"],"names":[],"mappings":"AAEA;EACE,6BAAA;EACA,YAAA;EACA,iBAAA;EACA,8BAAA;EACA,kBAAA;EACA,yBCHM;EDIN,SAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,SAAA;AADF","sourcesContent":["@import 'public/assets/colors';\n\n.page {\n  padding: 12px 16px 100px 16px;\n  width: 100vw;\n  min-height: 100vh;\n  background: rgba(247, 246, 255, 1);\n  text-align: center;\n  color: $white;\n  margin: 0;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  gap: 16px;\n}\n","$main: rgba(117, 83, 240, 1);\n$light-main: rgba(237, 235, 254, 1);\n$black-extra: rgba(27, 27, 27, 1);\n$dark-grey: rgba(135, 135, 135, 1);\n$light-background: rgba(247, 246, 255, 1);\n$white: rgba(255, 255, 255, 1);\n$light-grey: rgba(213, 212, 220, 1);\n$yellow: rgba(255, 199, 1, 1);\n$grey: rgba(158, 158, 158, 1);\n$light-green: #39b283;\n$green: #1f6b4e;\n$dark-green: #1f4c3a;\n$danger: #c10000;\n$red: #e73232;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"page": `style_page__EH9Ds`
};
export default ___CSS_LOADER_EXPORT___;
