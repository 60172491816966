import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../services/store';

interface AuthProtectedRouteProps {
  element: JSX.Element;
}

const AuthProtectedRoute: React.FC<AuthProtectedRouteProps> = ({ element }) => {
  const token = useSelector((state: RootState) => state.auth.token);

  return token ? element : null;
};

export default AuthProtectedRoute;
