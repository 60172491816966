import { useEffect, useRef, useState } from 'react';
import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { GameEvents } from '../api/types';
import { bombReward, bossReward } from '../../../pages/game/constants';
import { Howl } from 'howler';
import classes from '../style.module.scss';
import {
  calculateAndroidGraphic,
  createSunlight,
  initializeCamera,
  killMonster,
  Monster,
  showCoin,
} from './utils';
import { ClanShortInfo } from '../../clans/api/types';
// import MiniTapGame from '../mini-tap-game';

interface TowerGameProps {
  towerLevel: number;
  sound: boolean;
  level: number;
  gameEvents: GameEvents;
  onBombExplode: () => Promise<void>;
  onBossExplode: () => Promise<void>;
  clan: ClanShortInfo | null;
  onTapCollect: (streak: number) => Promise<void>;
  onTapGameStart: () => Promise<void>;
}

const TowerGame: React.FC<TowerGameProps> = ({
  towerLevel,
  sound,
  level,
  gameEvents,
  onBombExplode,
  onBossExplode,
  clan,
  onTapCollect,
  onTapGameStart,
}: TowerGameProps) => {
  // Initialize hooks at the top
  const mountRef = useRef<HTMLDivElement>(null);
  const sceneRef = useRef<THREE.Scene | null>(null);
  const cameraRef = useRef<THREE.PerspectiveCamera | null>(null);
  const rendererRef = useRef<THREE.WebGLRenderer | null>(null);
  const currentTowerRef = useRef<THREE.Object3D | undefined>(undefined);
  const towerModelsRef = useRef<THREE.Object3D[]>([]);
  const bombWaveRef = useRef<THREE.Object3D | undefined>(undefined);
  const bombExplodedTodayRef = useRef(gameEvents.bomb.todayCount);
  const bossExplodedTodayRef = useRef(gameEvents.boss.todayCount);

  const flowerModelRef = useRef<THREE.Object3D | null>(null);
  const flowerRef = useRef<THREE.Object3D | null>(null);
  const flowerTimeoutRef = useRef<number | null>(null);
  const flowerClickedRef = useRef<boolean>(false);
  const disableGenerationRef = useRef<boolean>(false);
  const streakRef = useRef<number>(0);
  const lastFlowerPositionRef = useRef<THREE.Vector3 | null>(null);
  const [streak, setStreak] = useState<number>(0);

  const monsterRadius = 2;
  const baseKillInterval = 2900;
  const killInterval = Math.max(500, baseKillInterval - (towerLevel - 1) * 500);
  const baseMoveSpeed = 0.004;
  const moveSpeed = baseMoveSpeed + (towerLevel - 1) * 0.0001;
  const groundLevel = 0;
  const ratio = window.innerWidth / window.innerHeight;

  const bombRewardAmount = bombReward[level] || 250;
  const bombMaxEvents = gameEvents.bomb.maxCount;
  const bossRewardAmount = bossReward[level] || 100;
  const bossMaxEvents = gameEvents.boss.maxCount;

  useEffect(() => {
    bombExplodedTodayRef.current = gameEvents.bomb.todayCount;
  }, [gameEvents.bomb.todayCount]);

  useEffect(() => {
    bossExplodedTodayRef.current = gameEvents.boss.todayCount;
  }, [gameEvents.boss.todayCount]);

  useEffect(() => {
    const resumeAudio = () => {
      if (Howler.ctx && Howler.ctx.state === 'suspended') {
        Howler.ctx.resume();
      }
    };

    window.addEventListener('click', resumeAudio);
    window.addEventListener('touchstart', resumeAudio);

    return () => {
      window.removeEventListener('click', resumeAudio);
      window.removeEventListener('touchstart', resumeAudio);
    };
  }, []);

  useEffect(() => {
    Howler.mute(!sound);
  }, [sound]);

  const platform = window.Telegram.WebApp.platform;

  useEffect(() => {
    const scene = new THREE.Scene();
    sceneRef.current = scene;

    const camera = initializeCamera(ratio);
    cameraRef.current = camera;

    const renderer = new THREE.WebGLRenderer({ alpha: true, antialias: false });
    renderer.setSize(window.innerWidth, window.innerHeight);
    renderer.shadowMap.enabled = false;
    renderer.setClearColor(0xffffff, 0);

    renderer.setPixelRatio(
      platform === 'android'
        ? calculateAndroidGraphic(window.devicePixelRatio)
        : window.devicePixelRatio,
    );

    mountRef.current?.appendChild(renderer.domElement);
    rendererRef.current = renderer;

    const ambientLight = new THREE.AmbientLight(0xffffff, 0.4);
    scene.add(ambientLight);

    const mainSun = createSunlight(50, 100, 50);
    const additionalSun = createSunlight(-50, 70, -50);
    scene.add(mainSun);
    scene.add(additionalSun);

    const loader = new GLTFLoader();
    const towerModels: THREE.Object3D[] = [];
    let banner: THREE.Object3D | undefined;
    let coinModel: THREE.Object3D | undefined;
    let weaponModel: THREE.Object3D | undefined;
    let monsterModels: THREE.Object3D[] = [];
    let bomb: THREE.Object3D | undefined;
    let boss: THREE.Object3D | undefined;
    let bombWave: THREE.Object3D | undefined;

    const textureLoader = new THREE.TextureLoader();

    let handTexture: THREE.Texture;
    let handTouchTexture: THREE.Texture;
    let hitTexture: THREE.Texture;

    const loadModels = async () => {
      const [
        backgroundGltf,
        towerGltf1,
        towerGltf2,
        towerGltf3,
        towerGltf4,
        towerGltf5,
        bannerGltf,
        coinGltf,
        weaponGltf,
        monsterGltf,
        pepeGltf,
        bonkGltf,
        brettGltf,
        bossGltf,
        bombGltf,
        bombWaveGltf,
        flowerGltf, // Load the flower model
        loadedHandTexture,
        loadedTouchTexture,
        loadedHitTexture,
      ] = await Promise.all([
        loader.loadAsync('/assets/models/background.glb'),
        loader.loadAsync('/assets/models/tower_1.glb'),
        loader.loadAsync('/assets/models/tower_2.glb'),
        loader.loadAsync('/assets/models/tower_3.glb'),
        loader.loadAsync('/assets/models/tower_4.glb'),
        loader.loadAsync('/assets/models/tower_5.glb'),
        loader.loadAsync('/assets/models/banner.glb'),
        loader.loadAsync('/assets/models/coin.glb'),
        loader.loadAsync('/assets/models/ball.glb'),
        loader.loadAsync('/assets/models/hamster_s.glb'),
        loader.loadAsync('/assets/models/pepe_s.glb'),
        loader.loadAsync('/assets/models/bonk_s.glb'),
        loader.loadAsync('/assets/models/brett_s.glb'),
        loader.loadAsync('/assets/models/boss_s.glb'),
        loader.loadAsync('/assets/models/bomb_s.glb'),
        loader.loadAsync('/assets/models/bomb_wave.glb'),
        loader.loadAsync('/assets/models/M_Red.glb'),
        textureLoader.loadAsync('/assets/images/hand.svg'),
        textureLoader.loadAsync('/assets/images/lines.svg'),
        textureLoader.loadAsync('/assets/images/hit.svg'),
      ]);

      const allMonsterModels = [
        { model: monsterGltf.scene, name: 'hamster' },
        { model: pepeGltf.scene, name: 'Pepe' },
        { model: bonkGltf.scene, name: 'Bonk' },
        { model: brettGltf.scene, name: 'Brett' },
      ];

      if (clan) {
        monsterModels = allMonsterModels
          .filter(({ name }) => name !== clan.name)
          .map(({ model }) => model);
      } else {
        monsterModels = allMonsterModels.map(({ model }) => model);
      }

      handTexture = loadedHandTexture;
      handTouchTexture = loadedTouchTexture;
      hitTexture = loadedHitTexture;
      const background = backgroundGltf.scene;
      background.position.set(0, groundLevel, 0);
      background.scale.set(1, 1, 1);
      scene.add(background);

      flowerModelRef.current = flowerGltf.scene;

      towerModels.push(
        towerGltf1.scene,
        towerGltf2.scene,
        towerGltf3.scene,
        towerGltf4.scene,
        towerGltf5.scene,
      );

      towerModels.forEach((tower) => {
        tower.position.set(0, groundLevel, 0);
        tower.rotation.y = THREE.MathUtils.degToRad(45);
        tower.scale.set(1, 1, 1);
        tower.castShadow = true;
      });

      banner = bannerGltf.scene;
      banner.position.set(0, groundLevel, 0);
      banner.rotation.y = THREE.MathUtils.degToRad(45);
      banner.scale.set(1, 1, 1);
      banner.castShadow = true;
      scene.add(banner);

      coinModel = coinGltf.scene;
      weaponModel = weaponGltf.scene;
      weaponModel.scale.set(0.8, 0.8, 0.8);
      weaponModel.castShadow = true;

      bomb = bombGltf.scene;
      boss = bossGltf.scene;
      bombWave = bombWaveGltf.scene;

      if (bomb) {
        bomb.scale.set(1.1, 1.1, 1.1);
        bomb.castShadow = true;
      }

      if (boss) {
        boss.scale.set(1.1, 1.1, 1.1);
        boss.castShadow = true;
      }

      if (bombWave) {
        bombWaveRef.current = bombWave;
      }

      for (let i = 0; i < 10; i++) {
        generateMonster();
      }

      towerModelsRef.current = towerModels;

      const initialTower = level <= 5 ? towerModels[level - 1] : towerModels[4];
      if (initialTower) {
        scene.add(initialTower);
        currentTowerRef.current = initialTower;
      }
    };

    loadModels();

    const monsters: Monster[] = [];
    const coins: THREE.Object3D[] = [];
    const monsterPool: Monster[] = [];

    const frustum = new THREE.Frustum();
    const projScreenMatrix = new THREE.Matrix4();

    const generateMonsterPosition = () => {
      let position: any, isTooClose;
      do {
        isTooClose = false;
        const angle = Math.random() * Math.PI * 2;
        const radius = 10 + Math.random() * 15;
        position = new THREE.Vector3(
          Math.cos(angle) * radius,
          groundLevel + 0.1,
          Math.sin(angle) * radius,
        );

        monsters.forEach((monster) => {
          const distance = position.distanceTo(monster.position);
          if (distance < 2 * monsterRadius) {
            isTooClose = true;
          }
        });

        camera.updateMatrixWorld();
        projScreenMatrix.multiplyMatrices(
          camera.projectionMatrix,
          camera.matrixWorldInverse,
        );
        frustum.setFromProjectionMatrix(projScreenMatrix);
      } while (isTooClose || !frustum.containsPoint(position));

      return position;
    };

    const createMonster = (): Monster | undefined => {
      if (monsterModels.length > 0) {
        const randomIndex = Math.floor(Math.random() * monsterModels.length);
        const monster = monsterModels[randomIndex].clone() as Monster;
        monster.isExploding = false;
        monster.phaseShift = Math.random() * Math.PI * 2;
        monster.castShadow = false;
        return monster;
      }
    };

    const generateMonster = () => {
      const monster =
        monsterPool.length > 0 ? monsterPool.pop() : createMonster();
      if (monster) {
        const position = generateMonsterPosition();
        monster.position.copy(position);
        monster.lookAt(
          currentTowerRef.current?.position || new THREE.Vector3(),
        );
        monster.scale.set(0, 0, 0);
        monster.visible = true;
        scene.add(monster);
        monsters.push(monster);

        const duration = 500;
        const startTime = performance.now();

        const animateScale = () => {
          const elapsedTime = performance.now() - startTime;
          const progress = elapsedTime / duration;

          if (progress < 1) {
            const scale = progress; // Scale from 0 to 1
            monster.scale.set(scale, scale, scale);
            requestAnimationFrame(animateScale);
          } else {
            monster.scale.set(1, 1, 1);
          }
        };

        requestAnimationFrame(animateScale);
      }
    };

    const clock = new THREE.Clock();

    const animate = () => {
      requestAnimationFrame(animate);

      const delta = clock.getDelta();

      monsters.forEach((monster) => {
        if (monster.visible && !monster.isExploding) {
          const direction = new THREE.Vector3()
            .subVectors(
              currentTowerRef.current?.position || new THREE.Vector3(),
              monster.position,
            )
            .normalize();
          monster.position.addScaledVector(direction, moveSpeed);
        }
      });

      coins.forEach((coin) => {
        coin.rotation.y += delta * Math.PI;
      });

      renderer.render(scene, camera);
    };

    animate();

    const shootWeapon = (target: THREE.Vector3) => {
      if (weaponModel && currentTowerRef.current) {
        const weapon = weaponModel.clone();
        weapon.position.copy(currentTowerRef.current.position);
        scene.add(weapon);

        // Create trail system
        const trailLength = 100;
        const trailCount = 15;
        const trailOffset = 0.07;

        const createTrailLine = (
          offsetX: number,
          offsetY: number,
          offsetZ: number,
        ) => {
          const trailVertices = new Float32Array(trailLength * 3);
          const trailGeometry = new THREE.BufferGeometry();
          trailGeometry.setAttribute(
            'position',
            new THREE.BufferAttribute(trailVertices, 3),
          );

          const trailMaterial = new THREE.LineBasicMaterial({
            color: 0xff0000,
            transparent: true,
            opacity: 0.5,
            blending: THREE.AdditiveBlending,
          });

          const trailLine = new THREE.Line(trailGeometry, trailMaterial);
          trailLine.userData = { offsetX, offsetY, offsetZ };
          return trailLine;
        };

        const trailLines: any = [];
        for (let i = 0; i < trailCount; i++) {
          const angle = (i / trailCount) * Math.PI * 2;
          const offsetX = Math.cos(angle) * trailOffset;
          const offsetY = Math.sin(angle) * trailOffset;
          const offsetZ = 0;
          const trailLine = createTrailLine(offsetX, offsetY, offsetZ);
          trailLines.push(trailLine);
          scene.add(trailLine);
        }

        const shootDuration = 650;
        const shootStartTime = performance.now();

        const startPosition = currentTowerRef.current.position.clone();
        const targetWithOffset = target.clone();
        targetWithOffset.y += 2;

        const shootAnimation = () => {
          const elapsedTime = performance.now() - shootStartTime;
          const progress = elapsedTime / shootDuration;

          if (progress < 1 && currentTowerRef.current) {
            // Interpolate between start position and target with a slight y offset
            weapon.position.lerpVectors(startPosition, target, progress);
            weapon.position.y += 2 * (1 - progress); // Add slight elevation

            // Update trail positions for each line
            trailLines.forEach((trailLine: any) => {
              const trailPositions =
                trailLine.geometry.attributes.position.array;
              for (let i = trailLength - 1; i > 0; i--) {
                trailPositions[i * 3] = trailPositions[(i - 1) * 3];
                trailPositions[i * 3 + 1] = trailPositions[(i - 1) * 3 + 1];
                trailPositions[i * 3 + 2] = trailPositions[(i - 1) * 3 + 2];
              }
              trailPositions[0] =
                weapon.position.x + trailLine.userData.offsetX;
              trailPositions[1] =
                weapon.position.y + trailLine.userData.offsetY;
              trailPositions[2] =
                weapon.position.z + trailLine.userData.offsetZ;
              trailLine.geometry.attributes.position.needsUpdate = true;
            });

            requestAnimationFrame(shootAnimation);
          } else {
            scene.remove(weapon);
            trailLines.forEach((trailLine: any) => scene.remove(trailLine));

            // Dispose of weapon and trail resources
            weapon.traverse((child: any) => {
              if (child instanceof THREE.Mesh && child.geometry) {
                child.geometry.dispose();
              }
              if (child instanceof THREE.Mesh && child.material) {
                child.material.dispose();
              }
            });
            trailLines.forEach((trailLine: any) => {
              trailLine.geometry.dispose();
              trailLine.material.dispose();
            });
          }
        };

        shootAnimation();
      }
    };

    const animateMonsterExplosion = (monster: Monster) => {
      monster.isExploding = true;
      const explosionDuration = 300;
      const startTime = performance.now();

      const explode = () => {
        const elapsedTime = performance.now() - startTime;
        const progress = elapsedTime / explosionDuration;

        if (progress < 1) {
          const scale = 1 + progress * 0.35;
          monster.scale.set(scale, scale, scale);
          requestAnimationFrame(explode);
        } else {
          monster.visible = false;
          scene.remove(monster);
          monsters.splice(monsters.indexOf(monster), 1);
          if (sceneRef.current) {
            showCoin(sceneRef.current, monster.position, 1, coinModel);
          }
          monsterPool.push(monster);
          if (monsters.length < 10) {
            generateMonster();
          }
          monster.isExploding = false;
        }
      };
      explode();
    };

    const animateBombWave = (position: THREE.Vector3) => {
      if (bombWaveRef.current) {
        const bombWave = bombWaveRef.current.clone();
        bombWave.position.copy(position);
        bombWave.position.y = groundLevel - 0.1; // Adjust position slightly below the ground
        bombWave.scale.set(0.1, 0.1, 0.1); // Start with a small scale
        scene.add(bombWave);

        const waveDuration = 1000; // 0.4 second duration
        const startTime = performance.now();

        const scaleWave = () => {
          const elapsedTime = performance.now() - startTime;
          const progress = elapsedTime / waveDuration;

          if (progress < 1) {
            const scale = 0.1 + 100 * progress; // Scale from 0.1 to 10 along z-axis
            bombWave.scale.set(scale, 1, scale); // Only scale along the z-axis
            requestAnimationFrame(scaleWave);
          } else {
            scene.remove(bombWave);
            bombWave.traverse((child) => {
              if (child instanceof THREE.Mesh && child.geometry) {
                child.geometry.dispose();
              }
              if (child instanceof THREE.Mesh && child.material) {
                child.material.dispose();
              }
            });
          }
        };

        scaleWave();
      }
    };

    const killingInterval = setInterval(
      () =>
        killMonster(
          monsters,
          currentTowerRef.current,
          shootWeapon,
          animateMonsterExplosion,
        ),
      killInterval,
    );

    const generateBomb = () => {
      if (!bomb || bombExplodedTodayRef.current >= bombMaxEvents) {
        return;
      }

      bomb.scale.set(0, 0, 0); // Start with scale 0 for animation

      const center = new THREE.Vector3(0, groundLevel, 0);
      const minDistance = 2; // Minimum distance from the center
      const range = 5; // Maximum distance from the center

      // Generate a random angle
      const angle = Math.random() * 2 * Math.PI;

      // Generate a random distance within the specified range but greater than the minimum distance
      const distance = minDistance + Math.random() * (range - minDistance);

      // Calculate the offset from the center
      const offsetX = distance * Math.cos(angle);
      const offsetZ = distance * Math.sin(angle);

      bomb.position.set(center.x + offsetX, center.y + 0.2, center.z + offsetZ);
      bomb.visible = true;
      bomb.renderOrder = 0; // Ensure bomb is rendered first
      scene.add(bomb);

      const scaleUpDuration = 250;
      const scaleTarget = new THREE.Vector3(1.1, 1.1, 1.1); // Final scale
      const startTime = performance.now();

      const scaleUp = () => {
        if (!bomb) return;
        const elapsedTime = performance.now() - startTime;
        const progress = elapsedTime / scaleUpDuration;
        bomb.scale.lerpVectors(
          new THREE.Vector3(0, 0, 0),
          scaleTarget,
          progress,
        );
        if (progress < 1) {
          requestAnimationFrame(scaleUp);
        } else {
          bomb.scale.set(1.1, 1.1, 1.1); // Ensure it ends at scale 1.1
        }
      };

      scaleUp();

      const bombSound = new Howl({
        src: [`${process.env.PUBLIC_URL}/assets/audio/shot.mp3`],
        autoplay: false,
      });

      // Create and position hand sprite
      const handMaterial = new THREE.SpriteMaterial({
        map: handTexture,
        depthTest: false, // Disable depth test to ensure it renders on top
        depthWrite: false, // Disable depth write to ensure it renders on top
      });
      const handSprite = new THREE.Sprite(handMaterial);
      handSprite.scale.set(1.7, 1.7, 1);

      const handTouchMaterial = new THREE.SpriteMaterial({
        map: handTouchTexture,
        depthTest: false,
        depthWrite: false,
        transparent: true,
        opacity: 0,
      });
      const handTouchSprite = new THREE.Sprite(handTouchMaterial);
      handTouchSprite.scale.set(2, 2, 2);

      const handSpriteX = bomb.position.x;
      const handSpriteY = bomb.position.y;
      const handSpriteZ = bomb.position.z + 0.7;

      const handTouchSpriteX = bomb.position.x;
      const handTouchSpriteY = bomb.position.y + 0.8;
      const handTouchSpriteZ = bomb.position.z - 0.15;

      handSprite.position.set(handSpriteX, handSpriteY, handSpriteZ);
      handSprite.renderOrder = 1;

      handTouchSprite.position.set(
        handTouchSpriteX,
        handTouchSpriteY,
        handTouchSpriteZ,
      );
      handTouchSprite.renderOrder = 2;

      scene.add(handSprite);
      scene.add(handTouchSprite);
      let bombClicked = false;
      const handleClick = (event: MouseEvent) => {
        if (!bomb || !bomb.visible || bombClicked) return; // Check if bomb is already clicked

        const mouse = new THREE.Vector2(
          (event.clientX / window.innerWidth) * 2 - 1,
          -(event.clientY / window.innerHeight) * 2 + 1,
        );

        const raycaster = new THREE.Raycaster();
        raycaster.setFromCamera(mouse, camera);

        const intersects = raycaster.intersectObject(bomb, true);

        const explodeBomb = () => {
          if (bomb) {
            onBombExplode();
            killAllMonsters();
            animateBombWave(bomb.position);
            if (sceneRef.current) {
              showCoin(
                sceneRef.current,
                bomb.position,
                bombRewardAmount,
                coinModel,
              );
            }
            bomb.visible = false; // Update bomb visibility status

            // Remove bomb and related elements from the scene
            scene.remove(bomb);
            scene.remove(handSprite);
            scene.remove(handTouchSprite);

            renderer.domElement.removeEventListener('click', handleClick);
          }
        };

        if (intersects.length > 0 && bomb) {
          bombClicked = true; // Set the flag as clicked
          bombSound.play();
          explodeBomb();
        }
      };

      renderer.domElement.addEventListener('click', handleClick);

      const animateHand = () => {
        if (bomb && bomb.visible) {
          requestAnimationFrame(animateHand);
          handSprite.position.set(
            handSpriteX,
            handSpriteY, // Position lower than the bomb
            handSpriteZ,
          );
          handTouchSprite.position.set(
            handTouchSpriteX,
            handTouchSpriteY, // Position lower than the bomb
            handTouchSpriteZ,
          );

          // Add scaling animation
          const speedFactor = 0.01;
          const scale = 1.7 + 0.1 * Math.sin(Date.now() * speedFactor);
          const scaleTouchSprite = 1 + 0.1 * Math.sin(Date.now() * speedFactor);
          handSprite.scale.set(scale, scale, 1);
          handTouchSprite.scale.set(scaleTouchSprite, scaleTouchSprite, 1);

          // Show handTouchSprite when hand is almost the smallest
          if (scale <= 1.65) {
            handTouchSprite.material.opacity = 1;
          } else {
            handTouchSprite.material.opacity = 0;
          }
        }
      };

      animateHand();

      setTimeout(() => {
        if (bomb && bomb.visible) {
          scene.remove(bomb);
          scene.remove(handSprite);
          scene.remove(handTouchSprite);
          bomb.visible = false;
          renderer.domElement.removeEventListener('click', handleClick);
        }
      }, 5000);
    };

    const killAllMonsters = () => {
      monsters.forEach((monster) => {
        if (monster.visible) {
          animateMonsterExplosion(monster);
        }
      });
    };

    const bombInterval = setInterval(
      () => {
        if (bombExplodedTodayRef.current < bombMaxEvents) {
          generateBomb();
        }
      },
      40000 + Math.random() * 20000,
    );

    const generateBoss = () => {
      if (!boss || bossExplodedTodayRef.current >= bossMaxEvents) {
        return;
      }

      if (!boss) return; // Additional guard, redundant but keeps TypeScript happy.

      boss.scale.set(0, 0, 0); // Initialize with scale 0 for animation

      const center = new THREE.Vector3(0, groundLevel, 0);
      const minDistance = 2;
      const range = 5;

      const angle = Math.random() * 2 * Math.PI;
      const distance = minDistance + Math.random() * (range - minDistance);
      const offsetX = distance * Math.cos(angle);
      const offsetZ = distance * Math.sin(angle);

      boss.position.set(center.x + offsetX, center.y + 0.2, center.z + offsetZ);
      boss.lookAt(currentTowerRef.current?.position || new THREE.Vector3());
      boss.visible = true;
      boss.renderOrder = 0;
      scene.add(boss);

      const scaleUpDuration = 250;
      const scaleTarget = new THREE.Vector3(1.1, 1.1, 1.1);
      const startTime = performance.now();

      const scaleUp = () => {
        if (!boss) return; // Ensure boss is still defined
        const elapsedTime = performance.now() - startTime;
        const progress = elapsedTime / scaleUpDuration;
        boss.scale.lerpVectors(
          new THREE.Vector3(0, 0, 0),
          scaleTarget,
          progress,
        );
        if (progress < 1) {
          requestAnimationFrame(scaleUp);
        } else {
          boss.scale.set(1.1, 1.1, 1.1);
        }
      };

      scaleUp();

      const bossSound = new Howl({
        src: [`${process.env.PUBLIC_URL}/assets/audio/mob.mp3`],
        autoplay: false, // Do not auto-play the sound
      });

      // Prepare hand and hit sprites
      const handMaterial = new THREE.SpriteMaterial({
        map: handTexture,
        depthTest: false,
        depthWrite: false,
      });
      const handSprite = new THREE.Sprite(handMaterial);
      handSprite.scale.set(1.7, 1.7, 1);

      const handTouchMaterial = new THREE.SpriteMaterial({
        map: handTouchTexture,
        depthTest: false,
        depthWrite: false,
        transparent: true,
        opacity: 0,
      });
      const handTouchSprite = new THREE.Sprite(handTouchMaterial);
      handTouchSprite.scale.set(1, 1, 1);

      const hitMaterial = new THREE.SpriteMaterial({
        map: hitTexture,
        depthTest: false,
        depthWrite: false,
        transparent: true,
        opacity: 0,
      });
      const hitSprite = new THREE.Sprite(hitMaterial);
      hitSprite.scale.set(4, 4, 1);

      // Positioning sprites
      const handSpriteX = boss.position.x;
      const handSpriteY = boss.position.y;
      const handSpriteZ = boss.position.z + 0.7;
      const handTouchSpriteX = boss.position.x;
      const handTouchSpriteY = boss.position.y + 0.8;
      const handTouchSpriteZ = boss.position.z - 0.15;

      handSprite.position.set(handSpriteX, handSpriteY, handSpriteZ);
      handTouchSprite.position.set(
        handTouchSpriteX,
        handTouchSpriteY,
        handTouchSpriteZ,
      );
      hitSprite.position.set(
        handTouchSpriteX,
        handTouchSpriteY,
        handTouchSpriteZ,
      );

      scene.add(handSprite);
      scene.add(handTouchSprite);
      scene.add(hitSprite);

      let clickCount = 0;
      let hideTimeout: number | null = null;

      const resetHideTimeout = () => {
        if (hideTimeout !== null) clearTimeout(hideTimeout);
        hideTimeout = window.setTimeout(() => {
          if (boss && boss.visible) {
            scene.remove(boss);
            scene.remove(handSprite);
            scene.remove(handTouchSprite);
            scene.remove(hitSprite);
            boss.visible = false;
            renderer.domElement.removeEventListener('click', handleClick);
          }
        }, 5000);
      };

      const handleClick = (event: MouseEvent) => {
        if (!boss || !boss.visible) return;

        const mouse = new THREE.Vector2(
          (event.clientX / window.innerWidth) * 2 - 1,
          -(event.clientY / window.innerHeight) * 2 + 1,
        );

        const raycaster = new THREE.Raycaster();
        raycaster.setFromCamera(mouse, camera);

        const intersects = raycaster.intersectObject(boss, true);

        if (intersects.length > 0) {
          clickCount++;
          hitSprite.material.opacity = 1;
          setTimeout(() => {
            hitSprite.material.opacity = 0;
          }, 200);
          if (clickCount >= 5) {
            bossSound.play();
            onBossExplode();
            scene.remove(boss);
            scene.remove(handTouchSprite);
            scene.remove(hitSprite);
            scene.remove(handSprite);
            if (sceneRef.current) {
              showCoin(
                sceneRef.current,
                boss.position,
                bossRewardAmount,
                coinModel,
              );
            }
            boss.visible = false;
            renderer.domElement.removeEventListener('click', handleClick);
          } else {
            resetHideTimeout();
          }
        }
      };

      renderer.domElement.addEventListener('click', handleClick);

      const animateHand = () => {
        if (boss && boss.visible) {
          requestAnimationFrame(animateHand);
          handSprite.position.set(handSpriteX, handSpriteY, handSpriteZ);
          handTouchSprite.position.set(
            handTouchSpriteX,
            handTouchSpriteY,
            handTouchSpriteZ,
          );
          hitSprite.position.set(
            handTouchSpriteX,
            handTouchSpriteY,
            handTouchSpriteZ,
          );

          const speedFactor = 0.01;
          const scale = 1.7 + 0.1 * Math.sin(Date.now() * speedFactor);
          const scaleTouchSprite = 1 + 0.1 * Math.sin(Date.now() * speedFactor);
          handSprite.scale.set(scale, scale, 1);
          handTouchSprite.scale.set(scaleTouchSprite, scaleTouchSprite, 1);

          if (scale <= 1.65) {
            handTouchSprite.material.opacity = 1;
          } else {
            handTouchSprite.material.opacity = 0;
          }
        }
      };

      animateHand();
      resetHideTimeout();
    };

    const bossInterval = setInterval(
      () => {
        if (bossExplodedTodayRef.current < bossMaxEvents) {
          generateBoss();
        }
      },
      20000 + Math.random() * 10000,
    );

    const generateFlowerPosition = () => {
      const minDistanceFromCenter = 3.5;
      const maxDistance = 5.5;
      const minDistanceFromLastFlower = 2.5; // Minimum distance from the last flower
      const maxAttempts = 10;
      let position: THREE.Vector3;

      for (let i = 0; i < maxAttempts; i++) {
        const angle = Math.random() * Math.PI * 2;
        const radius =
          minDistanceFromCenter +
          Math.random() * (maxDistance - minDistanceFromCenter);
        position = new THREE.Vector3(
          Math.cos(angle) * radius,
          groundLevel + 0.1,
          Math.sin(angle) * radius,
        );

        // Ensure the position is not too close to the last flower
        if (
          position.distanceTo(new THREE.Vector3(0, groundLevel, 0)) >=
            minDistanceFromCenter &&
          (!lastFlowerPositionRef.current ||
            position.distanceTo(lastFlowerPositionRef.current) >=
              minDistanceFromLastFlower)
        ) {
          lastFlowerPositionRef.current = position.clone();
          return position;
        }
      }

      // Fallback to a default position if no suitable position is found
      console.warn('Could not find a suitable position for the flower.');
      return new THREE.Vector3(0, groundLevel + 0.1, 0);
    };

    const removeFlowerFromScene = (flower: THREE.Object3D) => {
      if (scene && flower) {
        scene.remove(flower);
      }
    };

    const animateFlowerExplosion = (
      flower: THREE.Object3D,
      showCoinOnExplode: boolean,
      onComplete: () => void,
    ) => {
      const explosionDuration = 100;
      const startTime = performance.now();

      const explode = () => {
        const elapsedTime = performance.now() - startTime;
        const progress = elapsedTime / explosionDuration;

        if (progress < 1) {
          const scale = 1 + progress * 0.35;
          flower.scale.set(scale, scale, scale);
          requestAnimationFrame(explode);
        } else {
          if (showCoinOnExplode) {
            showCoin(scene, flower.position, 1, flowerModelRef.current!);
          }
          removeFlowerFromScene(flower);
          flowerRef.current = null;
          onComplete();
        }
      };

      explode();
    };

    const clearFlowerTimeout = () => {
      if (flowerTimeoutRef.current !== null) {
        clearTimeout(flowerTimeoutRef.current);
        flowerTimeoutRef.current = null;
      }
    };

    const createNewFlower = () => {
      if (!flowerModelRef.current || disableGenerationRef.current) return;

      disableGenerationRef.current = true; // Disable further generation during this process

      // Ensure any existing flower is removed
      if (flowerRef.current) {
        removeFlowerFromScene(flowerRef.current);
        flowerRef.current = null;
      }

      const flower = flowerModelRef.current.clone();
      flower.position.copy(generateFlowerPosition());
      flower.scale.set(0, 0, 0);
      flower.visible = true;

      scene.add(flower);
      flowerRef.current = flower;

      const duration = 280;
      const startTime = performance.now();

      const easeOutCubic = (t: number) => --t * t * t + 1;

      const animateAppearance = () => {
        const elapsedTime = performance.now() - startTime;
        const progress = elapsedTime / duration;

        if (progress < 1) {
          let scale = 0;
          if (progress < 0.8) {
            scale = easeOutCubic(progress / 0.8) * 1.2;
          } else {
            const t = (progress - 0.8) / 0.2;
            scale = 1.2 - 0.1 * t;
          }
          flower.scale.set(scale, scale, scale);
          requestAnimationFrame(animateAppearance);
        } else {
          flower.scale.set(1, 1, 1);
          disableGenerationRef.current = false; // Re-enable generation after the flower is fully visible
        }
      };

      requestAnimationFrame(animateAppearance);

      // Clear any existing timeout
      clearFlowerTimeout();

      flowerTimeoutRef.current = window.setTimeout(() => {
        if (streakRef.current !== 0) {
          onTapCollect(streakRef.current);
        }
        setStreak(0);
        streakRef.current = 0;

        if (flowerRef.current) {
          animateFlowerExplosion(flowerRef.current, false, () => {
            flowerRef.current = null;
            setTimeout(() => {
              disableGenerationRef.current = false;
              createNewFlower();
            }, 6000);
          });
        }
      }, 2500);
    };

    const generateFlower = () => {
      if (!flowerModelRef.current || disableGenerationRef.current) return;

      // Clear any existing timeout before generating a new flower
      clearFlowerTimeout();

      if (flowerRef.current) {
        animateFlowerExplosion(flowerRef.current, false, () => {
          flowerRef.current = null;
          createNewFlower();
        });
      } else {
        createNewFlower();
      }
    };

    const flowerInitialTimeout = setTimeout(() => {
      generateFlower();
    }, 3000);

    const handleFlowerClick = (event: MouseEvent) => {
      if (
        !flowerRef.current ||
        !flowerRef.current.visible ||
        flowerClickedRef.current
      )
        return;

      const mouse = new THREE.Vector2(
        (event.clientX / window.innerWidth) * 2 - 1,
        -(event.clientY / window.innerHeight) * 2 + 1,
      );

      const raycaster = new THREE.Raycaster();
      raycaster.setFromCamera(mouse, camera);

      const intersects = raycaster.intersectObject(flowerRef.current, true);

      if (intersects.length > 0) {
        if (streakRef.current === 0) {
          onTapGameStart();
        }

        flowerClickedRef.current = true;

        setStreak((prevStreak: number) => {
          const newStreak = prevStreak + 1;
          streakRef.current = newStreak;
          return newStreak;
        });

        animateFlowerExplosion(flowerRef.current, true, () => {
          flowerRef.current = null;
          flowerClickedRef.current = false;
          createNewFlower();
        });

        // Clear the timeout when a flower is successfully clicked
        clearFlowerTimeout();
      }
    };

    renderer.domElement.addEventListener('click', handleFlowerClick);

    return () => {
      clearInterval(killingInterval);
      clearInterval(bombInterval);
      clearInterval(bossInterval);
      clearTimeout(flowerInitialTimeout);
      mountRef.current?.removeChild(renderer.domElement);
      renderer.dispose();
    };
  }, [clan]);

  useEffect(() => {
    const updateTowerModel = (newLevel: number) => {
      const scene = sceneRef.current;
      const currentTower = currentTowerRef.current;
      const towerModels = towerModelsRef.current;

      if (scene && towerModels.length > 0) {
        if (currentTower) {
          scene.remove(currentTower);
        }

        const newTower = towerModels[newLevel - 1];
        if (newTower) {
          scene.add(newTower);
          currentTowerRef.current = newTower;
        }
      }
    };

    if (level <= 5) {
      updateTowerModel(level);
    }
  }, [level]);

  return (
    <div
      ref={mountRef}
      style={{
        position: 'absolute',
        width: '100%',
        height: '100%',
        zIndex: 1,
      }}
    >
      <div className={classes.streak}>
        {streak > 0 && (
          <>
            <img
              src={`${process.env.PUBLIC_URL}/assets/images/mushrooms.svg`}
            />
            {streak}x
          </>
        )}
      </div>
    </div>
  );
};

export default TowerGame;
