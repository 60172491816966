// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_upgrade__dD3\\+t {
  border-radius: 7px;
  padding: 8px;
  background: rgb(117, 83, 240);
  display: flex;
  justify-content: space-between;
}
.style_upgrade__left__wPRpL {
  flex: 4 1;
  display: flex;
  align-items: center;
  gap: 16px;
}
.style_upgrade__right__KJUR1 {
  flex: 1 1;
}
.style_upgrade__title__C-k3V {
  color: #39b283;
  font-size: 1.4rem;
  font-weight: bold;
}
.style_upgrade__description__BJBP0 {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.style_upgrade__item__jBWe2 {
  display: flex;
  gap: 6px;
}
.style_upgrade__item__jBWe2 img {
  width: 14px;
  height: 14px;
}
.style_upgrade__item__jBWe2 i {
  font-size: 1.4rem;
}
.style_upgrade__item__title__Sy\\+-M {
  font-size: 1.4rem;
  color: rgb(255, 255, 255);
}
.style_upgrade__part__AFGcR {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 2px;
}`, "",{"version":3,"sources":["webpack://./src/features/boosts/components/boost-item/boost-upgrade/style.module.scss","webpack://./public/assets/colors.scss"],"names":[],"mappings":"AAEA;EACE,kBAAA;EACA,YAAA;EACA,6BCLK;EDML,aAAA;EACA,8BAAA;AADF;AAEE;EACE,SAAA;EACA,aAAA;EACA,mBAAA;EACA,SAAA;AAAJ;AAGE;EACE,SAAA;AADJ;AAIE;EACE,cCXU;EDYV,iBAAA;EACA,iBAAA;AAFJ;AAKE;EACE,aAAA;EACA,sBAAA;EACA,QAAA;AAHJ;AAME;EACE,aAAA;EACA,QAAA;AAJJ;AAMI;EACE,WAAA;EACA,YAAA;AAJN;AAMI;EACE,iBAAA;AAJN;AAMI;EACE,iBAAA;EACA,yBCvCE;ADmCR;AAOE;EACE,aAAA;EACA,mBAAA;EACA,sBAAA;EACA,QAAA;AALJ","sourcesContent":["@import '../../../../../../public/assets/colors';\n\n.upgrade {\n  border-radius: 7px;\n  padding: 8px;\n  background: $main;\n  display: flex;\n  justify-content: space-between;\n  &__left {\n    flex: 4;\n    display: flex;\n    align-items: center;\n    gap: 16px;\n  }\n\n  &__right {\n    flex: 1;\n  }\n\n  &__title {\n    color: $light-green;\n    font-size: 1.4rem;\n    font-weight: bold;\n  }\n\n  &__description {\n    display: flex;\n    flex-direction: column;\n    gap: 4px;\n  }\n\n  &__item {\n    display: flex;\n    gap: 6px;\n\n    img {\n      width: 14px;\n      height: 14px;\n    }\n    i {\n      font-size: 1.4rem;\n    }\n    &__title {\n      font-size: 1.4rem;\n      color: $white;\n    }\n  }\n  &__part {\n    display: flex;\n    align-items: center;\n    flex-direction: column;\n    gap: 2px;\n  }\n}\n","$main: rgba(117, 83, 240, 1);\n$light-main: rgba(237, 235, 254, 1);\n$black-extra: rgba(27, 27, 27, 1);\n$dark-grey: rgba(135, 135, 135, 1);\n$light-background: rgba(247, 246, 255, 1);\n$white: rgba(255, 255, 255, 1);\n$light-grey: rgba(213, 212, 220, 1);\n$yellow: rgba(255, 199, 1, 1);\n$grey: rgba(158, 158, 158, 1);\n$light-green: #39b283;\n$green: #1f6b4e;\n$dark-green: #1f4c3a;\n$danger: #c10000;\n$red: #e73232;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"upgrade": `style_upgrade__dD3+t`,
	"upgrade__left": `style_upgrade__left__wPRpL`,
	"upgrade__right": `style_upgrade__right__KJUR1`,
	"upgrade__title": `style_upgrade__title__C-k3V`,
	"upgrade__description": `style_upgrade__description__BJBP0`,
	"upgrade__item": `style_upgrade__item__jBWe2`,
	"upgrade__item__title": `style_upgrade__item__title__Sy+-M`,
	"upgrade__part": `style_upgrade__part__AFGcR`
};
export default ___CSS_LOADER_EXPORT___;
