//@ts-ignore
import classes from './style.module.scss';
import UserBadge from './user-badge';
import {
  useFinishOnboardingMutation,
  useGetUserQuery,
  useToggleSoundMutation,
} from '../../services/user/userApi';
import { useNavigate } from 'react-router-dom';
import Balance from '../../components/balance';
import { useDispatch, useSelector } from 'react-redux';
import { hideLoader } from '../../services/loader/loaderSlice';
import { useEffect, useRef, useState } from 'react';
import OnboardingSteps from '../../components/onboarding-steps';
import OnboardingPhase1Step1 from '../../assets/images/onboarding/phase-1/step1.png';
import OnboardingPhase1Step2 from '../../assets/images/onboarding/phase-1/step2.png';
import OnboardingPhase1Step3 from '../../assets/images/onboarding/phase-1/step3.png';
import OnboardingPhase2Step1 from '../../assets/images/onboarding/phase-2/step1.png';
import OnboardingPhase2Step2 from '../../assets/images/onboarding/phase-2/step2.png';
import OnboardingPhase3Step1 from '../../assets/images/onboarding/phase-3/step1.png';
import OnboardingPhase4Step1 from '../../assets/images/onboarding/phase-4/step1.png';
import OnboardingPhase4Step2 from '../../assets/images/onboarding/phase-4/step2.png';

import {
  NotificationType,
  showNotification,
} from '../../services/notification/notificationSlice';
import { RootState } from '../../services/store';
import { showAchievement } from '../../services/achievement/achievementSlice';
import { setUser } from '../../services/user/userSlice';
import Vault from '../../components/game/vault';
import { GtagEventType, trackGtagEvent } from '../../utils/gtagEventHandler';
import Footer from '../../components/footer';
import ROUTES from '../../utils/routes';
import { useLazyGetClanWarsResultsQuery } from '../clans-war/api/clansWarApi';
import CWReward from '../../components/cw-reward';

const Tower = () => {
  const dispatch = useDispatch();
  const isInvitation = useSelector(
    (state: RootState) => state.achievement.isInvitation,
  );

  const [cwRewardDialog, setCWRewardDialog] = useState(false);
  const loader = useSelector((state: RootState) => state.loader.loading);
  const hasLogged = useRef(false);
  const [toggleSound] = useToggleSoundMutation();

  const [fetchClanWarResult, { data: cwResult }] =
    useLazyGetClanWarsResultsQuery();

  const onSoundToggle = async () => {
    await toggleSound();
  };

  const hasSentGtag = useRef(false);

  const navigate = useNavigate();

  const navigateToProfile = () => {
    navigate('/profile');
  };

  const joinClan = () => {
    navigate(ROUTES.JOIN_CLAN);
  };

  const { data: user } = useGetUserQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });

  const [finishOnboarding] = useFinishOnboardingMutation();

  const [currentPhaseSteps, setCurrentPhaseSteps] = useState<string[]>([]);
  const [currentBackgrounds, setBackgrounds] = useState<any>([]);
  const [onboardingStep, setOnboardingStep] = useState<number>(0);
  const [displayOnboarding, setDisplayOnboarding] = useState(true);

  const phaseSteps = [
    ['Create Profile', 'Verify Email', 'Add First Post'],
    ['Game Onboarding Step 1', 'Game Onboarding Step 2'],
    ['Clans'],
    ['clan war 2'],
  ];

  const onOnboardingFinish = async () => {
    try {
      if (user) {
        await finishOnboarding(4).unwrap();
        if (isInvitation) {
          dispatch(
            showAchievement({
              title: 'REWARD',
              amount: 1000,
            }),
          );
        }
        setCurrentPhaseSteps([]);
        setDisplayOnboarding(false);
      }
    } catch (error) {
      dispatch(
        showNotification({
          message: 'Something went wrong on onboarding',
          type: NotificationType.ERROR,
        }),
      );
    }
  };

  useEffect(() => {
    if (!hasSentGtag.current) {
      trackGtagEvent(GtagEventType.VISIT_TOWER);
      hasSentGtag.current = true;
    }
  }, []);

  useEffect(() => {
    if (user?._id) {
      dispatch(setUser(user));
      let steps: Array<string> = [];
      let backgroundUrls: Array<string> = [];

      if (user) {
        if (user.lastOnboardingPhase === 0) {
          steps = steps.concat(
            phaseSteps[0],
            phaseSteps[1],
            phaseSteps[2],
            phaseSteps[3],
          );
          backgroundUrls = [
            OnboardingPhase1Step1,
            OnboardingPhase1Step2,
            OnboardingPhase1Step3,
            OnboardingPhase2Step1,
            OnboardingPhase2Step2,
            OnboardingPhase3Step1,
            OnboardingPhase4Step2,
          ];
        }
        if (user.lastOnboardingPhase === 1) {
          steps = steps.concat(phaseSteps[1], phaseSteps[2], phaseSteps[3]);
          backgroundUrls = [
            OnboardingPhase2Step1,
            OnboardingPhase2Step2,
            OnboardingPhase3Step1,
            OnboardingPhase4Step2,
          ];
        }
        if (user.lastOnboardingPhase === 2) {
          steps = steps.concat(phaseSteps[2], phaseSteps[3]);
          backgroundUrls = [OnboardingPhase3Step1, OnboardingPhase4Step2];
        }
        if (user.lastOnboardingPhase === 3) {
          steps = steps.concat(phaseSteps[3]);
          backgroundUrls = [OnboardingPhase4Step1, OnboardingPhase4Step2];
        }
        setCurrentPhaseSteps(steps);
        setBackgrounds(backgroundUrls);
      }

      if (loader) {
        setTimeout(() => {
          dispatch(hideLoader());
        }, 3000);
      }
    }
  }, [user]);

  useEffect(() => {
    if (user && currentPhaseSteps.length === 0) {
      setDisplayOnboarding(false);
    }
  }, [currentPhaseSteps]);

  useEffect(() => {
    const fetchResults = async () => {
      if (
        !loader &&
        !hasLogged.current &&
        !user?.hasSeenCWresults &&
        !displayOnboarding
      ) {
        try {
          await fetchClanWarResult().unwrap();
          setCWRewardDialog(true);
          hasLogged.current = true;
        } catch (error) {
          console.error('Error fetching Clan War results:', error);
        }
      }
    };

    const timer = setTimeout(fetchResults, 3000);

    return () => clearTimeout(timer); // Clean up the timeout if the component unmounts
  }, [loader, user, displayOnboarding]);

  return (
    <div>
      {user && displayOnboarding && (
        <OnboardingSteps
          steps={currentPhaseSteps}
          currentStep={onboardingStep}
          changeOnboardingStep={setOnboardingStep}
          backgrounds={currentBackgrounds}
          onFinish={onOnboardingFinish}
        />
      )}
      <div className={classes.user}>
        <div className={classes.user__part}>
          {user && (
            <>
              <UserBadge
                user={user}
                onClick={navigateToProfile}
                onSoundToggle={onSoundToggle}
              />
              <div className={classes.balance}>
                <Balance title="Coins balance" amount={user.coinsBalance} />
                {!user.clan.clanId && (
                  <div className={classes.join__wrapper}>
                    <div className={classes.join}>
                      <button
                        className={classes.double__border}
                        onClick={joinClan}
                      >
                        <i className="icon-outline-flag"></i>
                        JOIN CLAN
                      </button>
                    </div>
                  </div>
                )}
              </div>
              <Vault />
            </>
          )}
        </div>
      </div>
      {cwResult && user && cwRewardDialog && (
        <CWReward
          user={user}
          cwResult={cwResult}
          onClose={() => setCWRewardDialog(false)}
        />
      )}
      {user && <Footer />}
    </div>
  );
};

export default Tower;
