// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_vault__A5Er9 {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  position: fixed;
  bottom: 140px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 9999;
  min-width: 150px;
  width: 100%;
}
.style_vault__size__C1-7F {
  display: flex;
  gap: 4px;
  color: rgb(255, 255, 255);
  font-weight: 700;
  font-size: 1.6rem;
  align-items: center;
}
.style_vault__size__C1-7F img {
  width: 18px;
  height: 18px;
}
.style_vault__A5Er9 button {
  max-width: 147px;
}
.style_vault__requirements__cWkYT {
  display: flex;
  flex-direction: column;
  gap: 2px;
}
.style_vault__requirements__title__ZWYt2 {
  font-size: 1.4rem;
  font-weight: 700;
  color: rgb(27, 27, 27);
}
.style_vault__requirements__amount__rJSFa {
  font-size: 1.2rem;
  font-weight: 500;
  display: flex;
  gap: 6px;
}
.style_vault__requirements__amount__rJSFa img {
  width: 12px;
  height: 12px;
}`, "",{"version":3,"sources":["webpack://./src/components/game/vault/style.module.scss","webpack://./public/assets/colors.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,QAAA;EACA,eAAA;EACA,aAAA;EACA,SAAA;EACA,2BAAA;EACA,aAAA;EACA,gBAAA;EACA,WAAA;AADF;AAEE;EACE,aAAA;EACA,QAAA;EACA,yBCZI;EDaJ,gBAAA;EACA,iBAAA;EACA,mBAAA;AAAJ;AACI;EACE,WAAA;EACA,YAAA;AACN;AAEE;EACE,gBAAA;AAAJ;AAGE;EACE,aAAA;EACA,sBAAA;EACA,QAAA;AADJ;AAEI;EACE,iBAAA;EACA,gBAAA;EACA,sBCnCQ;ADmCd;AAGI;EAKE,iBAAA;EACA,gBAAA;EACA,aAAA;EACA,QAAA;AALN;AAFM;EACE,WAAA;EACA,YAAA;AAIR","sourcesContent":["@import 'public/assets/colors';\n\n.vault {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  gap: 8px;\n  position: fixed;\n  bottom: 140px;\n  left: 50%;\n  transform: translateX(-50%);\n  z-index: 9999;\n  min-width: 150px;\n  width: 100%;\n  &__size {\n    display: flex;\n    gap: 4px;\n    color: $white;\n    font-weight: 700;\n    font-size: 1.6rem;\n    align-items: center;\n    img {\n      width: 18px;\n      height: 18px;\n    }\n  }\n  button {\n    max-width: 147px;\n  }\n\n  &__requirements {\n    display: flex;\n    flex-direction: column;\n    gap: 2px;\n    &__title {\n      font-size: 1.4rem;\n      font-weight: 700;\n      color: $black-extra;\n    }\n\n    &__amount {\n      img {\n        width: 12px;\n        height: 12px;\n      }\n      font-size: 1.2rem;\n      font-weight: 500;\n      display: flex;\n      gap: 6px;\n    }\n  }\n}\n","$main: rgba(117, 83, 240, 1);\n$light-main: rgba(237, 235, 254, 1);\n$black-extra: rgba(27, 27, 27, 1);\n$dark-grey: rgba(135, 135, 135, 1);\n$light-background: rgba(247, 246, 255, 1);\n$white: rgba(255, 255, 255, 1);\n$light-grey: rgba(213, 212, 220, 1);\n$yellow: rgba(255, 199, 1, 1);\n$grey: rgba(158, 158, 158, 1);\n$light-green: #39b283;\n$green: #1f6b4e;\n$dark-green: #1f4c3a;\n$danger: #c10000;\n$red: #e73232;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"vault": `style_vault__A5Er9`,
	"vault__size": `style_vault__size__C1-7F`,
	"vault__requirements": `style_vault__requirements__cWkYT`,
	"vault__requirements__title": `style_vault__requirements__title__ZWYt2`,
	"vault__requirements__amount": `style_vault__requirements__amount__rJSFa`
};
export default ___CSS_LOADER_EXPORT___;
