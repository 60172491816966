import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../services/store';
import { hideAchievementReward } from '../../services/achievement-reward/achievementRewardSlice';
import Confetti from 'react-confetti';
import classes from './style.module.scss';
import Ribbon from './ribbon';
import { TwitterShareButton, TelegramShareButton } from 'react-share';
import ShareButton, { ShareButtonVariant } from './share-button';
import { useEffect, useState } from 'react';
import PopoverWarning from '../popovers/popover-warning';
import { useClaimAchievementTaskMutation } from '../../services/tasks/tasksApi';
import {
  NotificationType,
  showNotification,
} from '../../services/notification/notificationSlice';
import ROUTES from '../../utils/routes';

const AchievementReward = () => {
  const content = useSelector((state: RootState) => state.achievementReward);
  const [warning, setWarning] = useState(false);
  const [claimAchievement] = useClaimAchievementTaskMutation();

  const dispatch = useDispatch();

  const close = () => {
    dispatch(hideAchievementReward());
  };

  const shareOnSocialMedia = () => {
    setWarning(true);
  };

  const handleCloseWarning = () => {
    setWarning(false);
  };

  useEffect(() => {
    if (content.show) {
      document.body.style.overflow = 'hidden';
      return () => {
        const noScrollRoutes = [
          ROUTES.HOME,
          ROUTES.BOOSTS,
          ROUTES.REFERRAL,
          ROUTES.REFERRAL_ALL,
          ROUTES.EMAIL,
          ROUTES.JOIN_CLAN,
        ];
        if (noScrollRoutes.includes(location.pathname)) {
          document.body.style.overflow = 'hidden';
        } else {
          document.body.style.overflow = '';
        }
      };
    }
  }, [content.show]);

  const handleComplete = async () => {
    try {
      await claimAchievement(content.taskId).unwrap();
      dispatch(
        showNotification({
          message: 'You received 10000 coins!',
          type: NotificationType.SUCCESS,
        }),
      );
    } catch (error) {
      dispatch(
        showNotification({
          message: 'Something went wrong',
          type: NotificationType.ERROR,
        }),
      );
    }

    handleCloseWarning();
    dispatch(hideAchievementReward());
  };

  const formatReward = (value: number) => {
    if (value >= 10000) {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    }
    return value.toString();
  };

  if (!content.show) {
    return null;
  }

  return (
    <div className={classes.content}>
      <i className={`${classes.close} icon-close`} onClick={close}></i>
      <Confetti
        width={window.innerWidth}
        height={window.innerHeight}
        numberOfPieces={800}
        recycle={false}
        gravity={0.4}
        friction={0.99}
        initialVelocityX={10}
        initialVelocityY={20}
        tweenDuration={4000}
        style={{ position: 'fixed', zIndex: 9999 }}
      />

      <div className={classes.content__prize}>
        <div className={classes.content__logo}>
          <img
            src={`${process.env.PUBLIC_URL}/assets/images/reward/logo.png`}
          />
        </div>
        <div className={classes.sunshine}>
          <img
            src={`${process.env.PUBLIC_URL}/assets/images/reward/sunshine.svg`}
          />
        </div>
        <div className={classes.trophy}>
          <img
            src={`${process.env.PUBLIC_URL}/assets/images/reward/prize.svg`}
            alt="Trophy"
          />
        </div>
        <div className={classes.cloud__left}>
          <img
            src={`${process.env.PUBLIC_URL}/assets/images/reward/cloud.svg`}
            alt="Cloud"
          />
        </div>
        <div className={classes.cloud__right}>
          <img
            src={`${process.env.PUBLIC_URL}/assets/images/reward/cloud.svg`}
            alt="Cloud"
          />
        </div>
        <div className={classes.ribbon__wrapper}>
          <Ribbon title={content.title} subtitle={content.subtitle} />
        </div>
      </div>

      <div className={classes.content__reward}>
        <h4 className={classes.content__reward__title}>REWARD</h4>
        <div className={classes.content__reward__body}>
          <img src={`${process.env.PUBLIC_URL}/assets/images/coin-m.svg`} />
          <p>{formatReward(content.reward)} + 1000 XP</p>
        </div>
      </div>
      <div className={classes.socials}>
        <TwitterShareButton
          url={content.shareLink}
          title={content.shareMessage}
          className={classes.socials__item}
        >
          <ShareButton
            variant={ShareButtonVariant.X}
            onClick={shareOnSocialMedia}
          />
        </TwitterShareButton>
        <TelegramShareButton
          url={content.shareLink}
          title={content.shareMessage}
          className={classes.socials__item}
        >
          <ShareButton
            variant={ShareButtonVariant.TELEGRAM}
            onClick={shareOnSocialMedia}
          />
        </TelegramShareButton>
      </div>
      {warning && (
        <PopoverWarning onClose={handleCloseWarning} onClick={handleComplete} />
      )}
    </div>
  );
};

export default AchievementReward;
