export const levelIntervals: { [key: number]: number } = {
  1: 3000,
  2: 2500,
  3: 2000,
  4: 1500,
  5: 1000,
};

export const vaultSizes: { [key: number]: number } = {
  1: 10000,
  2: 15000,
  3: 20000,
  4: 25000,
  5: 50000,
  6: 75000,
  7: 100000,
  8: 125000,
  9: 150000,
  10: 200000,
};

export const bombReward: { [key: number]: number } = {
  1: 250,
  2: 500,
  3: 750,
  4: 1000,
  5: 1500,
  6: 1750,
  7: 2000,
  8: 2250,
  9: 2500,
  10: 3000,
};

export const bossReward: { [key: number]: number } = {
  1: 100,
  2: 200,
  3: 300,
  4: 400,
  5: 500,
  6: 600,
  7: 700,
  8: 800,
  9: 900,
  10: 1000,
};
