import classes from '../style.module.scss';

const Tribune = () => {
  return (
    <>
      <div className={classes.tribune}>
        <div className={classes.tribune__item}>
          <img
            src={`${process.env.PUBLIC_URL}/assets/images/prizes/prize-2.png`}
          />
          <div className={classes.tribune__item__reward}>
            <h4 className={classes.tribune__item__title}>2nd Place</h4>
            <h3 className={classes.tribune__item__amount}>
              <img src={`${process.env.PUBLIC_URL}/assets/images/coin-m.svg`} />
              100K
            </h3>
          </div>
        </div>
        <div className={classes.tribune__main}>
          <div className={classes.tribune__item}>
            <img
              src={`${process.env.PUBLIC_URL}/assets/images/prizes/prize-1.png`}
              alt="Trophy"
              className={classes.special}
            />
            <div className={classes.tribune__item__reward}>
              <h4 className={classes.tribune__item__title}>1st Place</h4>
              <h3 className={classes.tribune__item__amount}>
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/coin-m.svg`}
                />
                200K
              </h3>
            </div>
          </div>
          <div className={classes.tribune__main__sunshine}>
            <img
              src={`${process.env.PUBLIC_URL}/assets/images/reward/rays.svg`}
            />
          </div>
        </div>
        <div className={classes.tribune__item}>
          <img
            src={`${process.env.PUBLIC_URL}/assets/images/prizes/prize-3.png`}
          />
          <div className={classes.tribune__item__reward}>
            <h4 className={classes.tribune__item__title}>3rd Place</h4>
            <h3 className={classes.tribune__item__amount}>
              <img src={`${process.env.PUBLIC_URL}/assets/images/coin-m.svg`} />
              50K
            </h3>
          </div>
        </div>
      </div>
      <div className={classes.tribune__default}>
        <h3 className={classes.tribune__item__amount}>Other</h3>
        <h3 className={classes.tribune__item__amount}>
          <img src={`${process.env.PUBLIC_URL}/assets/images/coin-m.svg`} />
          20K
        </h3>
      </div>
    </>
  );
};

export default Tribune;
