import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  Task,
  useClaimTaskMutation,
} from '../../../../services/tasks/tasksApi';
import { CompletedTask, User } from '../../../../services/user/types';
import {
  useDeleteEmailMutation,
  useGetUserQuery,
} from '../../../../services/user/userApi';
import classes from '../style.module.scss';
import ActionRemove from '../components/action-remove';
import ROUTES from '../../../../utils/routes';
import ActionAdd from '../components/action-add';
import TaskLabel from '../components/task-label';
import {
  hideConfirmation,
  showConfirmation,
} from '../../../../services/confirmation/confirmationSlice';
import {
  NotificationType,
  showNotification,
} from '../../../../services/notification/notificationSlice';
import Button, {
  ButtonSize,
  ButtonType,
  ButtonVariant,
} from '../../../../components/button';
import RewardAmount from '../components/reward-amount';
import { showAchievement } from '../../../../services/achievement/achievementSlice';
import { setNavigation } from '../../../../services/navigation/navigationSlice';
import {
  GtagEventType,
  trackGtagEvent,
} from '../../../../utils/gtagEventHandler';

interface EmailStrategyProps {
  task: Task;
  user: User;
}

interface TaskStrategy {
  label: React.ReactNode;
  button: JSX.Element;
}

const EmailStrategy = ({ task, user }: EmailStrategyProps) => {
  const navigate = useNavigate();
  const [claimTask] = useClaimTaskMutation();
  const [deleteEmail] = useDeleteEmailMutation();
  const dispatch = useDispatch();
  const { refetch } = useGetUserQuery();

  const onEmailDelete = async () => {
    try {
      await deleteEmail().unwrap();
      dispatch(hideConfirmation());
      trackGtagEvent(GtagEventType.UNLINK_EMAIL);
      dispatch(
        showNotification({
          message: 'Email Unlinked Successfully',
          type: NotificationType.SUCCESS,
        }),
      );
    } catch (error) {
      dispatch(
        showNotification({
          message: 'Something Went Wrong',
          type: NotificationType.ERROR,
        }),
      );
    }
  };

  const handleClaim = async (userTask: CompletedTask) => {
    try {
      await claimTask(userTask.taskId).unwrap();
      dispatch(
        showAchievement({
          title: 'REWARD',
          amount: task.coinsReward,
        }),
      );
      refetch();
    } catch (error) {
      console.error('Error claiming reward:', error);
    }
  };

  const openDeleteConfirmation = () => {
    dispatch(showConfirmation({ mainAction: onEmailDelete }));
  };

  const navigateToEmail = () => {
    dispatch(setNavigation(ROUTES.PROFILE));
    navigate(ROUTES.EMAIL);
  };

  const navigateToRelinkEmail = () => {
    trackGtagEvent(GtagEventType.RELINK_EMAIL);
    navigateToEmail();
  };

  // TODO: revise part
  const strategyContent = (): TaskStrategy => {
    const userTask = user.completedTasks.find((ut) => ut.taskId === task._id);

    if (userTask && !userTask.isClaimed) {
      return {
        label: <TaskLabel icon="email" label={user.email} />,

        button: (
          <Button
            type={ButtonType.PRIMARY}
            variant={ButtonVariant.ORANGE}
            size={ButtonSize.SMALL}
            title="CLAIM"
            onClick={() => {
              handleClaim(userTask);
            }}
          />
        ),
      };
    }
    if (userTask && userTask.isClaimed && user.email) {
      return {
        label: <TaskLabel label={user.email} icon="email" />,
        button: <ActionRemove onClick={openDeleteConfirmation} />,
      };
    } else if (!userTask) {
      return {
        label: <TaskLabel icon="email" label="Email" />,
        button: (
          <ActionAdd onClick={navigateToEmail}>
            <RewardAmount amount={task.coinsReward} />
          </ActionAdd>
        ),
      };
    } else if (
      !user.email ||
      (!user.email && userTask?.isClaimed) ||
      !user.emailVerified
    ) {
      return {
        label: <TaskLabel icon="email" label="Email" />,
        button: (
          <ActionAdd label="Link Email" onClick={navigateToRelinkEmail} />
        ),
      };
    } else
      return {
        label: 'No action available',
        button: <button className={classes.disabled}>No Action</button>,
      };
  };

  const currentStrategy = strategyContent();

  return (
    <div className={classes.task}>
      {currentStrategy.label}
      <div>{currentStrategy.button}</div>
    </div>
  );
};

export default EmailStrategy;
