import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReauth } from '../../../services/baseQuery';
import { Boosts } from './types';

const BASE_URL = '/boosts';
export const boostsApi = createApi({
  reducerPath: 'boostsApi',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['boosts'],
  endpoints: (builder) => ({
    getBoosts: builder.query<Boosts, void>({
      query: () => ({
        url: `${BASE_URL}`,
      }),
      providesTags: ['boosts'],
    }),

    upgradeBoost: builder.mutation<string, string>({
      query: (type) => ({
        url: `${BASE_URL}/upgrade`,
        body: {
          boostType: type,
        },
        method: 'PATCH',
      }),
      invalidatesTags: ['boosts'],
    }),
  }),
});

export const { useGetBoostsQuery, useUpgradeBoostMutation } = boostsApi;
