import classes from './style.module.scss';
import Timer from '../../../../../components/timer';
import { useState } from 'react';

interface RulesProps {
  timeLeft: number;
  week: number;
}
const Rules = ({ timeLeft, week }: RulesProps) => {
  const [timer, setTimer] = useState<number>(timeLeft);

  const onTimerExceed = () => {
    setTimer(0);
  };

  return (
    <div className={classes.rules}>
      {timer > 0 ? (
        <>
          <Timer time={timeLeft} onTimerExceed={onTimerExceed} />
          <p className={classes.rules__description}>
            Earn coins during the tournament week to lead your clan to victory.
          </p>
          <p className={classes.rules__reward}>1 Coin = 1 Point</p>
          <p className={classes.rules__description}>
            {`Coins earned with referrals and "Earn" tasks are excluded.`}
          </p>
        </>
      ) : (
        <>
          <p className={classes.rules__finished__title}>
            Week {week} Tournament ended
          </p>
          <p className={classes.rules__finished__subtitle}>
            Processing rewards.
          </p>
        </>
      )}
    </div>
  );
};

export default Rules;
