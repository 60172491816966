import { ReactNode } from 'react';
import classes from './style.module.scss';

interface ActionAddProps {
  label?: string;
  onClick: () => void;
  children?: ReactNode;
}

const ActionAdd = ({ label, onClick, children }: ActionAddProps) => {
  return (
    <button className={classes.add} onClick={onClick}>
      {label && label}
      {children && children}
    </button>
  );
};

export default ActionAdd;
