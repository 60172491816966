import AnimatedPopoverWrapper from '../components/popover-animated-wrapper';
import PopoverTitle from '../components/popover-title';
import Button, { ButtonSize, ButtonType, ButtonVariant } from '../../button';
import { GtagEventType, trackGtagEvent } from '../../../utils/gtagEventHandler';

interface PopoverWarningProps {
  onClose: () => void;
  onClick: () => void;
}
const PopoverWarning = ({ onClose, onClick }: PopoverWarningProps) => {
  const onHandleWarning = () => {
    trackGtagEvent(GtagEventType.VERIFY_TASK);
    onClick();
  };

  const onHandleClose = () => {
    trackGtagEvent(GtagEventType.CANCEL_VERIFY_TASK);
    onClose();
  };

  return (
    <AnimatedPopoverWrapper isVisible={true}>
      <PopoverTitle title="Mmmmm.." />
      <p
        style={{
          maxWidth: '290px',
          color: '#D5D4DC',
          fontSize: '20px',
          marginTop: '16px',
          textAlign: 'center',
          fontWeight: '500',
        }}
      >
        You are so cool, dude! Your reward is only yours.
      </p>
      <p
        style={{
          maxWidth: '290px',
          color: '#D5D4DC',
          fontSize: '14px',
          margin: '16px 0',
          textAlign: 'center',
        }}
      >
        Cheaters will get x3 penalty.
      </p>
      <div style={{ display: 'flex', gap: '8px', width: '100%' }}>
        <Button
          type={ButtonType.PRIMARY}
          variant={ButtonVariant.GREY}
          size={ButtonSize.LARGE}
          title="CANCEL"
          onClick={onHandleClose}
        />
        <Button
          type={ButtonType.PRIMARY}
          variant={ButtonVariant.ORANGE}
          size={ButtonSize.LARGE}
          title="VERIFY"
          onClick={onHandleWarning}
        />
      </div>
    </AnimatedPopoverWrapper>
  );
};

export default PopoverWarning;
