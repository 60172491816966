import React, { useRef, useEffect } from 'react';
import classes from './style.module.scss'; // Import your SCSS module for styling

interface Props {
  name: string;
  placeholder?: string;
  type?: string;
  register: any;
  error: string | undefined;
}

const Input: React.FC<Props> = ({
  name,
  placeholder,
  type = 'text',
  register,
  error,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  return (
    <div className={classes.input__container}>
      <input
        ref={inputRef}
        type={type}
        placeholder={placeholder}
        {...register(name)}
        className={`${classes.input__field} ${error ? classes.input__error : ''}`}
      />
      {error && <span className={classes.error__message}>{error}</span>}
    </div>
  );
};

export default Input;
