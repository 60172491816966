// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_button__2Jnt3 {
  font-weight: 700;
  font-size: 1.4rem;
  padding: 12px 20px;
  height: 50px;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 100%;
  transition: background-color 0.3s, box-shadow 0.3s, transform 0.3s;
}
.style_button__disabled__sCN7z {
  opacity: 0.5;
}

.style_button__content__mZrMz {
  display: flex;
  align-items: center;
  gap: 6px;
}
.style_button__content__mZrMz img {
  width: 18px;
  height: 18px;
}

.style_button__icon__ljDew {
  font-size: 2rem;
}

.style_button-x__RuA2F {
  background: #393939;
  color: rgb(255, 255, 255);
  border: 2px solid #ffffff;
  box-shadow: 2px 2px 0px #393939;
}

.style_button-telegram__toBXm {
  background: #4e9de5;
  color: rgb(255, 255, 255);
  border: 2px solid #ffffff;
  box-shadow: 2px 2px 0px #4e9de5;
}`, "",{"version":3,"sources":["webpack://./src/components/achievement-reward/share-button/style.module.scss","webpack://./public/assets/colors.scss"],"names":[],"mappings":"AAEA;EACE,gBAAA;EACA,iBAAA;EACA,kBAAA;EACA,YAAA;EACA,mBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,eAAA;EAIA,WAAA;EACA,kEACE;AALJ;AAAE;EACE,YAAA;AAEJ;;AAOA;EACE,aAAA;EACA,mBAAA;EACA,QAAA;AAJF;AAKE;EACE,WAAA;EACA,YAAA;AAHJ;;AAOA;EACE,eAAA;AAJF;;AAOA;EACE,mBAAA;EACA,yBCjCM;EDkCN,yBAAA;EACA,+BAAA;AAJF;;AAOA;EACE,mBAAA;EACA,yBCxCM;EDyCN,yBAAA;EACA,+BAAA;AAJF","sourcesContent":["@import 'public/assets/colors';\n\n.button {\n  font-weight: 700;\n  font-size: 1.4rem;\n  padding: 12px 20px;\n  height: 50px;\n  border-radius: 12px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  cursor: pointer;\n  &__disabled {\n    opacity: 0.5;\n  }\n  width: 100%;\n  transition:\n    background-color 0.3s,\n    box-shadow 0.3s,\n    transform 0.3s;\n}\n\n.button__content {\n  display: flex;\n  align-items: center;\n  gap: 6px;\n  img {\n    width: 18px;\n    height: 18px;\n  }\n}\n\n.button__icon {\n  font-size: 2rem;\n}\n\n.button-x {\n  background: #393939;\n  color: $white;\n  border: 2px solid #ffffff;\n  box-shadow: 2px 2px 0px #393939;\n}\n\n.button-telegram {\n  background: #4e9de5;\n  color: $white;\n  border: 2px solid #ffffff;\n  box-shadow: 2px 2px 0px #4e9de5;\n}\n","$main: rgba(117, 83, 240, 1);\n$light-main: rgba(237, 235, 254, 1);\n$black-extra: rgba(27, 27, 27, 1);\n$dark-grey: rgba(135, 135, 135, 1);\n$light-background: rgba(247, 246, 255, 1);\n$white: rgba(255, 255, 255, 1);\n$light-grey: rgba(213, 212, 220, 1);\n$yellow: rgba(255, 199, 1, 1);\n$grey: rgba(158, 158, 158, 1);\n$light-green: #39b283;\n$green: #1f6b4e;\n$dark-green: #1f4c3a;\n$danger: #c10000;\n$red: #e73232;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `style_button__2Jnt3`,
	"button__disabled": `style_button__disabled__sCN7z`,
	"button__content": `style_button__content__mZrMz`,
	"button__icon": `style_button__icon__ljDew`,
	"button-x": `style_button-x__RuA2F`,
	"button-telegram": `style_button-telegram__toBXm`
};
export default ___CSS_LOADER_EXPORT___;
