import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import classes from '../../style.module.scss';
import { useDispatch } from 'react-redux';
import ROUTES from '../../../../../utils/routes';
import { useLeaveClanMutation } from '../../../api/clansApi';
import { showPopover } from '../../../../../services/popover/popoverSlice';
import PopoverAnimatedWrapper from '../../../../../components/popovers/components/popover-animated-wrapper';
import PopoverTitle from '../../../../../components/popovers/components/popover-title';
import PopoverDescription from '../../../../../components/popovers/components/popover-description';
import Button, {
  ButtonSize,
  ButtonType,
  ButtonVariant,
} from '../../../../../components/button';

interface LeaveClanProps {
  user: any;
  userClan: any;
}

const LeaveClan = ({ user, userClan }: LeaveClanProps) => {
  const [leaveClan, { isLoading: clanLeaveLoader }] = useLeaveClanMutation();
  const dispatch = useDispatch();
  const [leaveClanConfirmation, setLeaveClanConfirmation] = useState(false);
  const [timeToNextDay, setTimeToNextDay] = useState('00:00:00');
  const [canLeaveClan, setCanLeaveClan] = useState(false);
  const navigate = useNavigate();

  const handleShowPopover = () => {
    dispatch(
      showPopover({
        title: '24 hours limit',
        description: 'You can change your clan once every 24 hours.',
      }),
    );
  };

  useEffect(() => {
    if (user?.clan?.lastJoinedTimestamp) {
      const lastJoinedDate = new Date(user.clan.lastJoinedTimestamp);

      const calculateRemainingTime = () => {
        const currentTime = new Date();
        const currentTimeUTC = Date.UTC(
          currentTime.getUTCFullYear(),
          currentTime.getUTCMonth(),
          currentTime.getUTCDate(),
          currentTime.getUTCHours(),
          currentTime.getUTCMinutes(),
          currentTime.getUTCSeconds(),
        );

        const timeDifference = currentTimeUTC - lastJoinedDate.getTime();

        const hoursSinceJoined = timeDifference / (1000 * 60 * 60);

        if (hoursSinceJoined >= 24) {
          setCanLeaveClan(true);
          setTimeToNextDay('00:00:00');
        } else {
          setCanLeaveClan(false);

          const remainingTime = 24 * 60 * 60 * 1000 - timeDifference;
          const hours = Math.floor(remainingTime / (1000 * 60 * 60));
          const minutes = Math.floor(
            (remainingTime % (1000 * 60 * 60)) / (1000 * 60),
          );
          const seconds = Math.floor((remainingTime % (1000 * 60)) / 1000);

          setTimeToNextDay(
            `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`,
          );
        }
      };

      calculateRemainingTime();

      const timer = setInterval(calculateRemainingTime, 1000);

      return () => clearInterval(timer);
    }
  }, [user]);

  const confirmLeave = () => {
    setLeaveClanConfirmation(true);
  };

  const onLeave = async () => {
    try {
      await leaveClan().then(() => {
        navigate(ROUTES.HOME);
      });
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <>
      {canLeaveClan ? (
        <button className={classes.leave} onClick={confirmLeave}>
          <i className="icon-logout"></i>
          <p className={classes.leave__text}>LEAVE CLAN</p>
        </button>
      ) : (
        <button className={classes.leave} onClick={handleShowPopover}>
          <i className="icon-logout"></i>
          <div className={classes.leave__content}>
            <p className={classes.leave__text}>LEAVE CLAN</p>
            <p className={classes.leave__time}>{timeToNextDay}</p>
          </div>
        </button>
      )}

      {leaveClanConfirmation && userClan && (
        <PopoverAnimatedWrapper
          isVisible
          backgroundColor={userClan.coloringSchemaHEX}
        >
          <div className={classes.popover__image}>
            <img
              src={
                process.env.REACT_APP_API_BASE_URL +
                '/images/clans/logos/' +
                userClan.logoFilename
              }
              alt="clan logo"
            />
          </div>
          <PopoverTitle title={`Leave ${userClan.name} Clan?`} />
          <div className={classes.popover__text__limitation}>
            <PopoverDescription description="You can change clan only once per day" />
          </div>
          <div className={classes.popover__actions}>
            <Button
              type={ButtonType.PRIMARY}
              variant={ButtonVariant.GREY}
              size={ButtonSize.LARGE}
              title="CANCEL"
              onClick={() => setLeaveClanConfirmation(false)}
            />
            <Button
              type={ButtonType.PRIMARY}
              variant={ButtonVariant.ORANGE}
              size={ButtonSize.LARGE}
              title="LEAVE"
              onClick={onLeave}
              isLoading={clanLeaveLoader}
            />
          </div>
        </PopoverAnimatedWrapper>
      )}
    </>
  );
};

export default LeaveClan;
