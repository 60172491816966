// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_item__r-oFp {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  color: rgb(27, 27, 27);
  font-weight: 700;
  font-size: 1.6rem;
  gap: 6px;
}
.style_item__r-oFp i {
  color: rgb(27, 27, 27);
}`, "",{"version":3,"sources":["webpack://./src/components/statistics/statistics-text/style.module.scss","webpack://./public/assets/colors.scss"],"names":[],"mappings":"AAEA;EACE,WAAA;EACA,aAAA;EACA,yBAAA;EACA,qBAAA;EACA,sBCLY;EDMZ,gBAAA;EACA,iBAAA;EACA,QAAA;AADF;AAEE;EACE,sBCVU;ADUd","sourcesContent":["@import 'public/assets/colors';\n\n.item {\n  width: 100%;\n  display: flex;\n  justify-content: flex-end;\n  align-items: flex-end;\n  color: $black-extra;\n  font-weight: 700;\n  font-size: 1.6rem;\n  gap: 6px;\n  i {\n    color: $black-extra;\n  }\n}\n","$main: rgba(117, 83, 240, 1);\n$light-main: rgba(237, 235, 254, 1);\n$black-extra: rgba(27, 27, 27, 1);\n$dark-grey: rgba(135, 135, 135, 1);\n$light-background: rgba(247, 246, 255, 1);\n$white: rgba(255, 255, 255, 1);\n$light-grey: rgba(213, 212, 220, 1);\n$yellow: rgba(255, 199, 1, 1);\n$grey: rgba(158, 158, 158, 1);\n$light-green: #39b283;\n$green: #1f6b4e;\n$dark-green: #1f4c3a;\n$danger: #c10000;\n$red: #e73232;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"item": `style_item__r-oFp`
};
export default ___CSS_LOADER_EXPORT___;
