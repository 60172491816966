import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  show: false,
  title: 'Reward',
  subtitle: 'Level 3',
};

const rewardSlice = createSlice({
  name: 'reward',
  initialState,
  reducers: {
    showReward(state, action) {
      state.show = true;
      state.title = action.payload.title;
      state.subtitle = action.payload.subtitle;
    },
    hideReward(state) {
      state.show = false;
      state.title = '';
      state.subtitle = '';
    },
  },
});

export const { showReward, hideReward } = rewardSlice.actions;

export default rewardSlice.reducer;
