import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReauth } from '../baseQuery';
import { User, UserStats } from './types';

export interface RegisterPayload {
  telegramId: number;
  telegramUsername: string;
  referralCode?: string;
}

export const userApi = createApi({
  reducerPath: 'userApi',
  tagTypes: ['user'],
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getUser: builder.query<User, void>({
      query: () => ({
        url: `/user/all_info`,
        method: 'GET',
      }),
      providesTags: ['user'],
    }),
    getUserStats: builder.query<UserStats, void>({
      query: () => ({
        url: `/user/stats`,
        method: 'GET',
      }),
      providesTags: ['user'],
    }),
    register: builder.mutation<any, RegisterPayload>({
      query: (payload) => ({
        url: 'user/register',
        method: 'POST',
        body: payload,
      }),
    }),
    finishOnboarding: builder.mutation<
      { lastOnboardingPhase: number; updatedAt: string },
      number
    >({
      query: (phase) => ({
        url: `/user/onboarding/${phase}`,
        method: 'PATCH',
      }),
      invalidatesTags: ['user'],
    }),
    deleteEmail: builder.mutation<{ message: string }, void>({
      query: () => ({
        url: `/user/email/delete`,
        method: 'DELETE',
      }),
      invalidatesTags: ['user'],
    }),
    linkDiscord: builder.mutation<{ message: string }, string>({
      query: (payload) => ({
        url: '/auth/link_discord',
        method: 'POST',
        body: {
          discord_code: payload,
        },
      }),
      invalidatesTags: ['user'],
    }),
    deleteDiscord: builder.mutation<{ message: string }, void>({
      query: () => ({
        url: `/auth/unlink_discord`,
        method: 'DELETE',
      }),
      invalidatesTags: ['user'],
    }),
    toggleSound: builder.mutation<void, void>({
      query: () => ({
        url: `/user/toggle_sound`,
        method: 'PATCH',
      }),
      invalidatesTags: ['user'],
    }),
    seenCWResult: builder.mutation<void, void>({
      query: () => ({
        url: `/user/seen_results`,
        method: 'PATCH',
      }),
    }),
  }),
});

export const {
  useGetUserQuery,
  useLazyGetUserQuery,
  useRegisterMutation,
  useFinishOnboardingMutation,
  useDeleteEmailMutation,
  useDeleteDiscordMutation,
  useLinkDiscordMutation,
  useGetUserStatsQuery,
  useToggleSoundMutation,
  useSeenCWResultMutation,
} = userApi;
