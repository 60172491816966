import classes from './style.module.scss';

interface ActionRemoveProps {
  onClick: () => void;
}

const ActionRemove = ({ onClick }: ActionRemoveProps) => {
  return <i className={`icon-close ${classes.icon}`} onClick={onClick} />;
};

export default ActionRemove;
