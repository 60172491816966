import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface ConfirmationState {
  isVisible: boolean;
  mainAction: () => void;
}

const initialState: ConfirmationState = {
  isVisible: false,
  mainAction: () => {},
};

const confirmationSlice = createSlice({
  name: 'confirmation',
  initialState,
  reducers: {
    showConfirmation: (
      state,
      action: PayloadAction<{
        mainAction: () => void;
      }>,
    ) => {
      state.isVisible = true;
      state.mainAction = action.payload.mainAction;
    },
    hideConfirmation: (state) => {
      state.isVisible = false;
    },
  },
});

export const { showConfirmation, hideConfirmation } = confirmationSlice.actions;
export default confirmationSlice.reducer;
