import React, { useEffect, useMemo, useState } from 'react';
import classes from './style.module.scss';
import {
  DailyStreak,
  PartnerTask,
  Task,
  TaskActionType,
} from '../../../services/tasks/tasksApi';
import { CompletedTask, User } from '../../../services/user/types';
import SectionDivider from '../../../components/section-divider';
import SectionTitle from '../../../components/section-title';
import CuberiumInApp from '../tasks/cuberium-in-app';
import TasksWrapper from '../tasks/tasks-wrapper';
import { getPartnerTaskDetails, getTaskDetails } from '../tasks/utils';
import { TaskDetails } from '../completed-tasks';
import CuberiumStoriesTask from '../tasks/cuberium-stories';
import CuberiumSurvey from '../tasks/cuberium-survey';
import ExternalWebsite from '../tasks/external-website';
import { CuberiumSocial } from '../tasks/cuberium-social';
import { useGetReferralLinkQuery } from '../../../features/referrals/api/referralApi';
import CuberiumInternalEmail from '../tasks/cuberium-internal-email';
import CuberiumDailyStreak from '../tasks/сuberium-daily-streak';

interface CuberiumTasksProps {
  user: User;
  tasks: Array<Task>;
  dailyStreak: DailyStreak;
  partnerTasks: Array<PartnerTask>;
}

const CuberiumTasks = ({
  user,
  tasks,
  dailyStreak,
  partnerTasks,
}: CuberiumTasksProps) => {
  const [visibleCuberiumTasks, setVisibleCuberiumTasks] = useState<Task[]>([]);
  const [visibleCuberiumPartnerTasks, setVisibleCuberiumPartnerTasks] =
    useState<PartnerTask[]>([]);

  const { data: userReferralLink } = useGetReferralLinkQuery();

  const isTaskVisible = (task: Task) => {
    const isClaimed = user.completedTasks.some(
      (t) => t.taskId === task._id && t.isClaimed,
    );

    if (isClaimed) {
      return false;
    }
    return task.actionType !== TaskActionType.DISCORD;
  };

  const filteredTasks = useMemo(() => {
    if (!tasks || !user) return [];

    return tasks.filter((task) => isTaskVisible(task));
  }, [tasks, user]);

  const filteredPartnerTasks = useMemo(() => {
    if (!partnerTasks || !user) return [];

    return partnerTasks.filter((task) => isTaskVisible(task));
  }, [partnerTasks, user]);

  useEffect(() => {
    setVisibleCuberiumTasks(filteredTasks);
  }, [filteredTasks]);

  useEffect(() => {
    setVisibleCuberiumPartnerTasks(filteredPartnerTasks);
  }, [filteredPartnerTasks]);

  const renderTaskComponent = (
    task: Task,
    details: TaskDetails,
    isDisabled: boolean,
    isPartner: boolean,
  ) => {
    const isClaimed = user.completedTasks.some(
      (t) => t.taskId === task._id && t.isClaimed,
    );

    const taskExistsInCompleted = user.completedTasks.some(
      (t) => t.taskId === task._id,
    );

    if (task.actionType === TaskActionType.EMAIL) {
      return (
        <CuberiumInternalEmail
          task={task}
          readyForClaiming={taskExistsInCompleted}
          details={details}
        />
      );
    }

    if (task.actionType === TaskActionType.VIEW_STORY) {
      return (
        <CuberiumStoriesTask
          task={task}
          readyForClaiming={taskExistsInCompleted}
          details={details}
        />
      );
    }
    if (task.actionType === TaskActionType.SURVEY) {
      return (
        <CuberiumSurvey
          task={task}
          readyForClaiming={taskExistsInCompleted}
          details={details}
        />
      );
    }
    if (task.actionType === TaskActionType.VISIT_WEBSITE) {
      return (
        <ExternalWebsite
          user={user}
          task={task}
          readyForClaiming={taskExistsInCompleted}
          details={details}
          isPartner={isPartner}
        />
      );
    }

    if (task.actionType === TaskActionType.NO_INFO) {
      return (
        <ExternalWebsite
          user={user}
          task={task}
          readyForClaiming={taskExistsInCompleted}
          details={details}
          isPartner={isPartner}
        />
      );
    }
    if (task.actionType === TaskActionType.JOIN_GUILD) {
      return (
        <ExternalWebsite
          user={user}
          task={task}
          readyForClaiming={taskExistsInCompleted}
          details={details}
          isPartner={isPartner}
        />
      );
    }
    if (task.actionType === TaskActionType.JOIN_CHANNEL) {
      return (
        <CuberiumSocial
          user={user}
          task={task}
          readyForClaiming={taskExistsInCompleted}
          details={details}
          isPartner={isPartner}
        />
      );
    }

    return (
      userReferralLink && (
        <CuberiumInApp
          user={user}
          task={task}
          isCompleted={isClaimed}
          details={details}
          isDisabled={isDisabled}
          referralLink={userReferralLink.referralLink}
        />
      )
    );
  };

  const renderTask = (task: Task, index: number) => {
    const details = getTaskDetails(task, user);

    const targetCompletedTask = user.completedTasks.find(
      (completedTask: CompletedTask) => completedTask.taskId == task._id,
    );

    const isDisabled = targetCompletedTask
      ? targetCompletedTask.isClaimed
      : true;
    const totalTasks = filteredTasks.length;

    return (
      <React.Fragment key={task._id}>
        {renderTaskComponent(task, details, isDisabled, false)}
        {index !== totalTasks - 1 && <div className={classes.divider}></div>}
      </React.Fragment>
    );
  };

  const renderPartnerTask = (task: PartnerTask, index: number) => {
    const details = getPartnerTaskDetails(task);

    const targetCompletedTask = user.completedTasks.find(
      (completedTask: CompletedTask) => completedTask.taskId == task._id,
    );

    const isDisabled = targetCompletedTask
      ? targetCompletedTask.isClaimed
      : true;
    const totalTasks = filteredTasks.length;

    return (
      <React.Fragment key={task._id}>
        {renderTaskComponent(task, details, isDisabled, true)}
        {index !== totalTasks - 1 && <div className={classes.divider}></div>}
      </React.Fragment>
    );
  };

  return (
    <div className={classes.section}>
      <CuberiumDailyStreak dailyStreak={dailyStreak} user={user} />
      {visibleCuberiumPartnerTasks.length > 0 && (
        <>
          <SectionDivider>
            <SectionTitle title="PARTNERS" />
          </SectionDivider>
          <TasksWrapper>
            {visibleCuberiumPartnerTasks.map((task, index) =>
              renderPartnerTask(task, index),
            )}
          </TasksWrapper>
        </>
      )}
      <SectionDivider>
        <SectionTitle title="CUBERIUM" />
      </SectionDivider>
      {visibleCuberiumTasks.length > 0 && (
        <TasksWrapper>
          {visibleCuberiumTasks.map((task, index) => renderTask(task, index))}
        </TasksWrapper>
      )}
    </div>
  );
};

export default CuberiumTasks;
