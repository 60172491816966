import { ReactNode } from 'react';
import classes from './style.module.scss';

interface StatisticsItemProps {
  title: string;
  children: ReactNode;
}

const StatisticsItem = ({ title, children }: StatisticsItemProps) => {
  return (
    <li className={classes.item}>
      <div className={classes.item__part}>{title}</div>
      <div className={classes.item__part__right}>{children}</div>
    </li>
  );
};

export default StatisticsItem;
