// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_section__wpXTJ {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  margin-top: 16px;
}

.style_divider__KQxSr {
  height: 1px;
  width: 100%;
  margin-top: 6px;
  margin-bottom: 4px;
  background: rgba(33, 33, 33, 0.1);
}`, "",{"version":3,"sources":["webpack://./src/pages/earn/completed-tasks/style.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,QAAA;EACA,gBAAA;AACF;;AAEA;EACE,WAAA;EACA,WAAA;EACA,eAAA;EACA,kBAAA;EACA,iCAAA;AACF","sourcesContent":[".section {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  gap: 8px;\n  margin-top: 16px;\n}\n\n.divider {\n  height: 1px;\n  width: 100%;\n  margin-top: 6px;\n  margin-bottom: 4px;\n  background: rgba(33, 33, 33, 0.1);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"section": `style_section__wpXTJ`,
	"divider": `style_divider__KQxSr`
};
export default ___CSS_LOADER_EXPORT___;
