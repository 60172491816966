import {
  PartnerTask,
  Task,
  TaskSocialNetworkType,
} from '../../../services/tasks/tasksApi';
import { User } from '../../../services/user/types';
import classes from './style.module.scss';
import CuberiumInApp from '../tasks/cuberium-in-app';
import SectionDivider from '../../../components/section-divider';
import SectionTitle from '../../../components/section-title';
import TasksWrapper from '../tasks/tasks-wrapper';
import { getPartnerTaskDetails, getTaskDetails } from '../tasks/utils';
import PageDescription from '../../../components/page-description';

interface CompletedTasksProps {
  user: User;
  tasks: Array<Task>;
  partnerTasks: Array<PartnerTask>;
}

export interface TaskDetails {
  title: string;
  icon: string;
}

const CompletedTasks = ({ user, tasks, partnerTasks }: CompletedTasksProps) => {
  const claimedTaskIds = user.completedTasks
    .filter((completedTask) => completedTask.isClaimed)
    .map((completedTask) => completedTask.taskId);

  const allTasks = [...tasks, ...partnerTasks];

  const completedAndClaimedTasks = allTasks.filter((task) =>
    claimedTaskIds.includes(task._id),
  );

  const renderTask = (task: Task | PartnerTask, index: number) => {
    let details;
    let isPartner = false;

    if ('partnerSection' in task && task.partnerSection === 'Cuberium') {
      details = getTaskDetails(task as Task, user);
    } else {
      details = getPartnerTaskDetails(task as PartnerTask);
      isPartner = true;
    }

    const totalTasks = completedAndClaimedTasks.length;

    switch (task.socialNetworkType) {
      case TaskSocialNetworkType.IN_APP:
      case TaskSocialNetworkType.ExternalResource:
      case TaskSocialNetworkType.TELEGRAM:
      case TaskSocialNetworkType.TWITTER:
      case TaskSocialNetworkType.DISCORD:
        return (
          <>
            <CuberiumInApp
              user={user}
              task={task}
              isCompleted={true}
              details={details}
              referralLink=""
              isPartner={isPartner}
            />
            {index !== totalTasks - 1 && (
              <div className={classes.divider}></div>
            )}
          </>
        );
      default:
        return 'Unknown';
    }
  };
  return (
    <div className={classes.section}>
      <SectionDivider>
        <SectionTitle title="COMPLETED" />
      </SectionDivider>
      <TasksWrapper>
        {completedAndClaimedTasks.length > 0 ? (
          completedAndClaimedTasks.map((task, index) => (
            <div key={task._id} className={classes.task}>
              {renderTask(task, index)}
            </div>
          ))
        ) : (
          <PageDescription description="Completed tasks will be here"></PageDescription>
        )}
      </TasksWrapper>
    </div>
  );
};

export default CompletedTasks;
