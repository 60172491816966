// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_loader__kz6Q5 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(117, 83, 240);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.style_loader__image__uTdpg {
  max-width: 100px;
  max-height: 100px;
  animation: style_float__Eog5r 2s infinite ease-in-out;
  margin-bottom: 12px;
}

@keyframes style_float__Eog5r {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}`, "",{"version":3,"sources":["webpack://./src/components/loader/style.module.scss","webpack://./public/assets/colors.scss"],"names":[],"mappings":"AAEA;EACE,eAAA;EACA,MAAA;EACA,OAAA;EACA,WAAA;EACA,YAAA;EACA,mCCRK;EDSL,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;EACA,aAAA;AADF;;AAIA;EACE,gBAAA;EACA,iBAAA;EACA,qDAAA;EACA,mBAAA;AADF;;AAIA;EACE;IACE,wBAAA;EADF;EAGA;IACE,4BAAA;EADF;EAGA;IACE,wBAAA;EADF;AACF","sourcesContent":["@import 'public/assets/colors';\n\n.loader {\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background-color: $main;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  z-index: 9999;\n}\n\n.loader__image {\n  max-width: 100px;\n  max-height: 100px;\n  animation: float 2s infinite ease-in-out;\n  margin-bottom: 12px;\n}\n\n@keyframes float {\n  0% {\n    transform: translateY(0);\n  }\n  50% {\n    transform: translateY(-10px);\n  }\n  100% {\n    transform: translateY(0);\n  }\n}\n","$main: rgba(117, 83, 240, 1);\n$light-main: rgba(237, 235, 254, 1);\n$black-extra: rgba(27, 27, 27, 1);\n$dark-grey: rgba(135, 135, 135, 1);\n$light-background: rgba(247, 246, 255, 1);\n$white: rgba(255, 255, 255, 1);\n$light-grey: rgba(213, 212, 220, 1);\n$yellow: rgba(255, 199, 1, 1);\n$grey: rgba(158, 158, 158, 1);\n$light-green: #39b283;\n$green: #1f6b4e;\n$dark-green: #1f4c3a;\n$danger: #c10000;\n$red: #e73232;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loader": `style_loader__kz6Q5`,
	"loader__image": `style_loader__image__uTdpg`,
	"float": `style_float__Eog5r`
};
export default ___CSS_LOADER_EXPORT___;
