import classes from './style.module.scss';
import { HallOfFameEntry } from '../../../../clans-war/api/types';

interface HallOfFameProps {
  clanName: string;
  data: Array<HallOfFameEntry>;
}
const HallOfFame = ({ data, clanName }: HallOfFameProps) => {
  const itemsToShow =
    data.length < 4
      ? [...data, ...Array(4 - data.length).fill({ week: 0, place: 0 })]
      : data;

  return (
    <div className={classes.fame}>
      <h2 className={classes.fame__title}>HALL OF FAME {clanName}</h2>
      <div className={classes.fame__list}>
        {itemsToShow.map((item, index) => (
          <div key={index} className={classes.item}>
            <img
              src={
                item.place > 0
                  ? `${process.env.PUBLIC_URL}/assets/images/prizes/prize-${item.place}.png`
                  : `${process.env.PUBLIC_URL}/assets/images/prizes/prize-placeholder.svg`
              }
              alt={`Place ${item.place}`}
            />
          </div>
        ))}
      </div>
      <p className={classes.fame__subtitle}>Clan achievements</p>
    </div>
  );
};

export default HallOfFame;
