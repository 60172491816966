import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isInvitation: false,
  show: false,
  title: 'REWARD',
  amount: 1000,
  xp: 1000,
};

const achievementSlice = createSlice({
  name: 'achievement',
  initialState,
  reducers: {
    showAchievement(state, action) {
      state.show = true;
      state.title = action.payload.title;
      state.amount = action.payload.amount;
      state.xp = action.payload.xp || 1000;
    },
    hideAchievement(state) {
      state.show = false;
      state.title = '';
      state.amount = 0;
      state.isInvitation = false;
    },
    setInvitation(state, action) {
      state.isInvitation = action.payload;
    },
  },
});

export const { showAchievement, hideAchievement, setInvitation } =
  achievementSlice.actions;

export default achievementSlice.reducer;
