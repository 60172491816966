import React from 'react';
import clsx from 'clsx';
import classes from './style.module.scss';
import { TOWER_POWER, TREASURE_MAP, VAULT_SIZE } from '../../../api/types';

interface BoostItemCurrentProps {
  boost: TOWER_POWER | VAULT_SIZE | TREASURE_MAP;
  maxLevel: number;
  icon: string;
  image: string;
  name: string;
  title: string | null;
}

const BoostItemCurrent: React.FC<BoostItemCurrentProps> = ({
  boost,
  maxLevel,
  icon,
  image,
  title,
  name,
}) => {
  const isFinal = !boost.next;
  const levelText =
    boost.current.level === maxLevel
      ? 'Max Level'
      : `Level ${boost.current.level}`;

  return (
    <div
      className={clsx(classes.boost__current, {
        [classes.boost__final]: isFinal,
      })}
    >
      <div className={classes.boost__current__left}>
        <img
          className={classes.boost__current__image}
          src={`${process.env.PUBLIC_URL}/assets/images/boosts/${image}.svg`}
          alt={`${name} icon`}
        />
        <div
          className={clsx(classes.boost__current__title, {
            [classes.boost__final__title]: isFinal,
          })}
        >
          <p
            className={clsx(classes.boost__current__name, {
              [classes.boost__final__name]: isFinal,
            })}
          >
            {name}
          </p>
          <div
            className={clsx(classes.boost__current__item, {
              [classes.boost__final__item]: isFinal,
            })}
          >
            <i
              className={clsx(`icon-${icon}`, classes.boost__current__icon, {
                [classes.boost__final__icon]: isFinal,
              })}
            ></i>
            <p
              className={clsx(classes.boost__current__title__text, {
                [classes.boost__final__title__text]: isFinal,
              })}
            >
              {title}
            </p>
          </div>
        </div>
      </div>
      <p
        className={clsx(classes.boost__current__level, {
          [classes.boost__final__level]: isFinal,
        })}
      >
        {levelText}
      </p>
    </div>
  );
};

export default BoostItemCurrent;
