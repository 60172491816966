export enum GtagEventType {
  VISIT_PROFILE = 'visit_profile',
  VISIT_REFERS = 'visit_refers',
  VISIT_EARN = 'visit_earn',
  VISIT_TOWER = 'visit_tower',
  VISIT_BOOST = 'visit_boost',
  VISIT_WARS = 'visit_wars',
  INVITE = 'invite',
  INVITE_LINK_COPY = 'invite_link_copy',
  CLAIM_REFERRALS = 'claim_referrals',
  VERIFY_TASK = 'verify_task',
  CANCEL_VERIFY_TASK = 'cancel_verify_task',
  LINK_EMAIL = 'link_email',
  UNLINK_EMAIL = 'unlink_email',
  RELINK_EMAIL = 'relink_email',
  JOIN_DISCORD = 'join_discord',
  JOIN_TELEGRAM = 'join_telegram',
  FOLLOW_TWITTER = 'follow_twitter',
  MEDIUM_TASK = 'medium_task',
  TWITTER_TASK = 'twitter_task',
  STORY_TASK = 'story_task',
  POLL_TASK = 'poll_task',
  WEBSITE_TASK = 'website_task',
  CHECKIN = 'checkin',
  GAME_CLAIM = 'game_claim',
  GAME_BOMB = 'game_bomb',
  GAME_CHEST = 'game_chest',
  GAME_BOSS = 'game_boss',
  FREE_CHEST = 'free_chest',
  COMING_SOON = 'coming_soon',
  BUMP = 'BUMP',
  QAPPI = 'QAPPI',
  CRYPTO_NIC = 'CryptoNic',
  CPTC = 'CPTC',
  JUPBOT = 'JupBot',
  TEMA_ULTRA = 'tema_ultra',
}

export const trackGtagEvent = (
  eventType: GtagEventType | string,
): Promise<void> => {
  return new Promise((resolve) => {
    let eventCategory = '';
    let eventLabel = '';

    if (isGtagEventType(eventType)) {
      const config = eventConfig[eventType];
      eventCategory = config.category;
      eventLabel = config.label;
    } else if (typeof eventType === 'string') {
      eventCategory = eventType;
      eventLabel = eventType;
    } else {
      resolve();
      return;
    }

    // @ts-ignore
    window.gtag('event', eventType, {
      event_category: eventCategory,
      event_label: eventLabel,
    });

    resolve();
  });
};

// Configuration mapping for event types
const eventConfig: Record<GtagEventType, { category: string; label: string }> =
  {
    [GtagEventType.VISIT_PROFILE]: {
      category: 'navigation',
      label: 'Profile Page',
    },
    [GtagEventType.VISIT_REFERS]: {
      category: 'navigation',
      label: 'Visit Refers',
    },
    [GtagEventType.VISIT_EARN]: { category: 'navigation', label: 'Visit Earn' },
    [GtagEventType.COMING_SOON]: {
      category: 'navigation',
      label: 'Coming Soon',
    },
    [GtagEventType.VISIT_TOWER]: {
      category: 'navigation',
      label: 'Tower Page',
    },
    [GtagEventType.VISIT_BOOST]: {
      category: 'navigation',
      label: 'Boost Page',
    },
    [GtagEventType.VISIT_WARS]: { category: 'navigation', label: 'Wars Page' },
    [GtagEventType.INVITE]: { category: 'interaction', label: 'Invite' },
    [GtagEventType.INVITE_LINK_COPY]: {
      category: 'interaction',
      label: 'Copy Invite Link',
    },
    [GtagEventType.CLAIM_REFERRALS]: {
      category: 'interaction',
      label: 'Claim Referrals',
    },
    [GtagEventType.VERIFY_TASK]: {
      category: 'interaction',
      label: 'Verify Task',
    },
    [GtagEventType.CANCEL_VERIFY_TASK]: {
      category: 'interaction',
      label: 'Cancel Verify Task',
    },
    [GtagEventType.LINK_EMAIL]: {
      category: 'interaction',
      label: 'Link Email',
    },
    [GtagEventType.UNLINK_EMAIL]: {
      category: 'interaction',
      label: 'Unlink Email',
    },
    [GtagEventType.RELINK_EMAIL]: {
      category: 'interaction',
      label: 'Relink Email',
    },
    [GtagEventType.JOIN_DISCORD]: {
      category: 'interaction',
      label: 'Join Discord',
    },
    [GtagEventType.JOIN_TELEGRAM]: {
      category: 'interaction',
      label: 'Join Telegram',
    },
    [GtagEventType.FOLLOW_TWITTER]: {
      category: 'interaction',
      label: 'Follow Twitter',
    },
    [GtagEventType.MEDIUM_TASK]: {
      category: 'interaction',
      label: 'Medium Task',
    },
    [GtagEventType.TWITTER_TASK]: {
      category: 'interaction',
      label: 'Twitter Task',
    },
    [GtagEventType.STORY_TASK]: {
      category: 'interaction',
      label: 'Story Task',
    },
    [GtagEventType.POLL_TASK]: { category: 'interaction', label: 'Poll Task' },
    [GtagEventType.WEBSITE_TASK]: {
      category: 'interaction',
      label: 'Website Task',
    },
    [GtagEventType.CHECKIN]: {
      category: 'interaction',
      label: 'Daily Checkin',
    },
    [GtagEventType.GAME_CLAIM]: { category: 'game', label: 'Game Claim' },
    [GtagEventType.GAME_BOMB]: { category: 'game', label: 'Game Bomb' },
    [GtagEventType.GAME_CHEST]: { category: 'game', label: 'Game Chest' },
    [GtagEventType.GAME_BOSS]: { category: 'game', label: 'Game Boss' },
    [GtagEventType.FREE_CHEST]: { category: 'game', label: 'Free Chest' },
    [GtagEventType.BUMP]: {
      category: 'integration',
      label: 'BUMP Integration',
    },
    [GtagEventType.QAPPI]: {
      category: 'integration',
      label: 'QAPPI Integration',
    },
    [GtagEventType.CRYPTO_NIC]: {
      category: 'integration',
      label: 'CryptoNic Integration',
    },
    [GtagEventType.CPTC]: {
      category: 'integration',
      label: 'CPTC Integration',
    },
    [GtagEventType.JUPBOT]: {
      category: 'integration',
      label: 'JupBot Integration',
    },
    [GtagEventType.TEMA_ULTRA]: {
      category: 'integration',
      label: 'tema_ultra Integration',
    },
  };

const isGtagEventType = (event: string): event is GtagEventType => {
  return Object.values(GtagEventType).includes(event as GtagEventType);
};
