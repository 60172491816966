import { useState } from 'react';
import PageDescription from '../../../../../components/page-description';
import PageTitle from '../../../../../components/page-title';
import { TaskSurvey } from '../../../../../services/tasks/tasksApi';
import classes from './style.module.scss';
import clsx from 'clsx'; // Import clsx for conditional class naming
import Button, {
  ButtonSize,
  ButtonType,
  ButtonVariant,
} from '../../../../../components/button';

interface CuberiumSurveyQuizProps {
  task: TaskSurvey;
  onFinish: (answers: Array<string>) => void;
  isLoading: boolean;
}

const CuberiumSurveyQuiz = ({
  task,
  onFinish,
  isLoading,
}: CuberiumSurveyQuizProps) => {
  const [selectedAnswer, setSelectedAnswer] = useState<Array<string>>([]);

  const handleAnswerClick = (answer: string) => {
    setSelectedAnswer([answer]);
  };

  return (
    <div className={classes.quiz}>
      <div className={classes.quiz__title}>
        <PageTitle title={task.data.title} />
      </div>
      <div className={classes.quiz__description}>
        <PageDescription description={task.data.question} />
      </div>
      <div className={classes.quiz__answers}>
        {task.data.answers.map((answer) => (
          <div
            key={answer.answer}
            className={clsx(classes.answer, {
              [classes.answer__selected]: answer.answer === selectedAnswer[0],
            })}
            onClick={() => handleAnswerClick(answer.answer)}
          >
            {answer.image && (
              <img
                src={
                  process.env.REACT_APP_API_BASE_URL + '/images/' + answer.image
                }
                alt=""
              />
            )}
            {answer.answer}
          </div>
        ))}
      </div>
      <div className={classes.quiz__submit}>
        <Button
          type={ButtonType.PRIMARY}
          variant={ButtonVariant.ORANGE}
          size={ButtonSize.LARGE}
          isLoading={isLoading}
          title={'CONFIRM'}
          onClick={() => onFinish(selectedAnswer)}
          isDisabled={selectedAnswer.length === 0}
        />
      </div>
    </div>
  );
};

export default CuberiumSurveyQuiz;
