// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_timer__LrM30 {
  display: flex;
  align-items: center;
  gap: 4px;
}
.style_timer__LrM30 i {
  color: rgb(255, 255, 255);
  font-size: 1.8rem;
}
.style_timer__LrM30 p {
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 20px;
  color: rgb(255, 255, 255);
}`, "",{"version":3,"sources":["webpack://./src/components/timer/style.module.scss","webpack://./public/assets/colors.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,mBAAA;EACA,QAAA;AADF;AAEE;EACE,yBCFI;EDGJ,iBAAA;AAAJ;AAEE;EACE,iBAAA;EACA,gBAAA;EACA,iBAAA;EACA,yBCTI;ADSR","sourcesContent":["@import 'public/assets/colors';\n\n.timer {\n  display: flex;\n  align-items: center;\n  gap: 4px;\n  i {\n    color: $white;\n    font-size: 1.8rem;\n  }\n  p {\n    font-size: 1.6rem;\n    font-weight: 600;\n    line-height: 20px;\n    color: $white;\n  }\n}\n","$main: rgba(117, 83, 240, 1);\n$light-main: rgba(237, 235, 254, 1);\n$black-extra: rgba(27, 27, 27, 1);\n$dark-grey: rgba(135, 135, 135, 1);\n$light-background: rgba(247, 246, 255, 1);\n$white: rgba(255, 255, 255, 1);\n$light-grey: rgba(213, 212, 220, 1);\n$yellow: rgba(255, 199, 1, 1);\n$grey: rgba(158, 158, 158, 1);\n$light-green: #39b283;\n$green: #1f6b4e;\n$dark-green: #1f4c3a;\n$danger: #c10000;\n$red: #e73232;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"timer": `style_timer__LrM30`
};
export default ___CSS_LOADER_EXPORT___;
