import classes from './style.module.scss';
import Tribune from './tribune';
import ExtraRewards from './extra-rewards';

const ClanWarsRewards = () => {
  return (
    <div className={classes.rewards}>
      <h2 className={classes.rewards__title}>Rewards for CLANS</h2>
      <h3 className={classes.rewards__subtitle}>Per member</h3>
      <Tribune />
      <p className={classes.rewards__rules}>
        Only users who played during the tournament week can receive the reward
      </p>
      <ExtraRewards />
    </div>
  );
};

export default ClanWarsRewards;
