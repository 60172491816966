import { useEffect, useRef, useState } from 'react';
import {
  useClaimReferralMutation,
  useGetReferralInfoQuery,
  useLazyGetReferralLinkQuery,
} from '../api/referralApi';
import Confetti from 'react-confetti';

import classes from './style.module.scss';
import PageTitle from '../../../components/page-title';
import ReferralBalance from './referral-balance';
import SmallBalance from '../../../components/small-balance';
import ROUTES from '../../../utils/routes';
import { useNavigate } from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useDispatch, useSelector } from 'react-redux';
import {
  NotificationType,
  showNotification,
} from '../../../services/notification/notificationSlice';
import PageDescription from '../../../components/page-description';
import { RootState } from '../../../services/store';
import { hideLoader } from '../../../services/loader/loaderSlice';
import { useGetUserQuery } from '../../../services/user/userApi';
import Button, {
  ButtonSize,
  ButtonType,
  ButtonVariant,
} from '../../../components/button';
import { GtagEventType, trackGtagEvent } from '../../../utils/gtagEventHandler';
import Footer from '../../../components/footer';
import { formatCoins } from '../../../utils/utils';

const ReferralsDashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [availableBalance, setAvailableBalance] = useState<number>(0);
  const [alreadyClaimed, setAlreadyClaimed] = useState<number>(0);
  const { refetch } = useGetUserQuery();
  const { data: user } = useGetUserQuery();
  const hasSentGtag = useRef(false);

  const [confetti, showConfetti] = useState(false);

  const { data: referralData } = useGetReferralInfoQuery(undefined, {
    skip: false,
    refetchOnMountOrArgChange: true,
  });

  const [triggerGetReferralLink, { data: referralLink }] =
    useLazyGetReferralLinkQuery();

  const loading = useSelector((state: RootState) => state.loader.loading);

  useEffect(() => {
    if (loading) {
      setTimeout(() => {
        dispatch(hideLoader());
      }, 1500);
    }
  }, []);

  useEffect(() => {
    if (!hasSentGtag.current) {
      trackGtagEvent(GtagEventType.VISIT_REFERS);
      hasSentGtag.current = true;
    }
  }, []);

  useEffect(() => {
    if (referralData) {
      setAvailableBalance(referralData.availableForClaim);
      setAlreadyClaimed(referralData.alreadyClaimed);
      triggerGetReferralLink();
    }
  }, [referralData]);

  const [claimReferral, { isLoading: referralsClaiming }] =
    useClaimReferralMutation();

  const handleClaim = async () => {
    try {
      if (referralData) {
        if (availableBalance < 1000) {
          dispatch(
            showNotification({
              message: `Claim only if you have 1000+ coins`,
              type: NotificationType.ERROR,
            }),
          );
        } else {
          showConfetti(true);
          trackGtagEvent(GtagEventType.CLAIM_REFERRALS);
          const response = await claimReferral().unwrap();
          refetch();
          setAlreadyClaimed((prev) => prev + response.claimed);
          setAvailableBalance(0);
          dispatch(
            showNotification({
              message: `Сlaimed ${formatCoins(response.claimed)} coins + 1000XP`,
              type: NotificationType.SUCCESS,
            }),
          );
          setTimeout(() => showConfetti(false), 2000);
        }
      }
    } catch (error) {
      dispatch(
        showNotification({
          message: 'Failed to claim referrals',
          type: NotificationType.ERROR,
        }),
      );
    }
  };

  const viewAll = () => {
    navigate(ROUTES.REFERRAL_ALL);
  };

  const handleShareOnTelegram = () => {
    if (referralLink) {
      trackGtagEvent(GtagEventType.INVITE);
      const textToShare = `\nCheck this out, mate!\nCuberium Defence is an easy-peasy coins farming game for meme tokens folks.\nWe both get 1000 coins when you connect`;
      const telegramUrl = `https://t.me/share/url?text=${encodeURIComponent(textToShare)}&url=${encodeURIComponent(referralLink?.referralLink)}`;
      window.open(telegramUrl, '_blank');
    }
  };

  return (
    <div className={classes.referral}>
      <PageTitle title="REFERRALS" />
      <div className={classes.total}>
        <div className={classes.total__title}>Earned</div>
        <SmallBalance amount={alreadyClaimed} />
      </div>
      <ReferralBalance
        availableBalance={availableBalance}
        onClaim={handleClaim}
        isLoading={referralsClaiming}
      />
      {confetti && (
        <Confetti
          width={window.innerWidth}
          height={window.innerHeight}
          numberOfPieces={800}
          recycle={false}
          gravity={0.4}
          friction={0.99}
          initialVelocityX={10}
          initialVelocityY={20}
          tweenDuration={4000}
          style={{ position: 'fixed', zIndex: 9999 }}
        />
      )}
      <p className={classes.small__text}>
        Earn 5% from your referrals <br />+ an extra 2% from their referrals
      </p>
      <div className={classes.referral__actions}>
        <div className={classes.referral__action}>
          <i className={`${classes.referral__action__icon} icon-person`}></i>
          {referralData?.totalReferrals}
        </div>
        <div className={classes.referral__action__divider} />
        <Button
          type={ButtonType.TEXT}
          variant={ButtonVariant.BLACK}
          title="VIEW ALL"
          size={ButtonSize.MEDIUM}
          onClick={viewAll}
        />
      </div>

      <div className={classes.referral__buttons}>
        <Button
          type={ButtonType.PRIMARY}
          variant={ButtonVariant.PURPLE}
          size={ButtonSize.MEDIUM}
          icon="invite"
          isLoading={false}
          title={'INVITE'}
          onClick={handleShareOnTelegram}
        />
        <CopyToClipboard
          text={referralLink?.referralLink || ''}
          onCopy={() => {
            trackGtagEvent(GtagEventType.INVITE_LINK_COPY);
            dispatch(
              showNotification({
                message: 'Link copied',
                type: NotificationType.SUCCESS,
              }),
            );
          }}
        >
          <Button
            type={ButtonType.TEXT}
            variant={ButtonVariant.PURPLE}
            size={ButtonSize.MEDIUM}
            icon="copy"
            isLoading={false}
            title="COPY LINK"
          />
        </CopyToClipboard>
      </div>
      <PageDescription
        withCoin={true}
        description="1000 Instantly for you and your friend "
      />
      {user && <Footer />}
    </div>
  );
};

export default ReferralsDashboard;
