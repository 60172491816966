// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_reward__AkzMC {
  display: flex;
  justify-content: center;
  gap: 6px;
  align-items: center;
}
.style_reward__icon__XEl6n {
  width: 18px;
  height: 18px;
  border-radius: 100px;
  background: #fdc902;
}
.style_reward__icon__XEl6n img {
  width: 100%;
  height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/pages/profile/socials/components/reward-amount/style.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,uBAAA;EACA,QAAA;EACA,mBAAA;AACF;AAAE;EACE,WAAA;EACA,YAAA;EACA,oBAAA;EACA,mBAAA;AAEJ;AADI;EACE,WAAA;EACA,YAAA;AAGN","sourcesContent":[".reward {\n  display: flex;\n  justify-content: center;\n  gap: 6px;\n  align-items: center;\n  &__icon {\n    width: 18px;\n    height: 18px;\n    border-radius: 100px;\n    background: #fdc902;\n    img {\n      width: 100%;\n      height: 100%;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"reward": `style_reward__AkzMC`,
	"reward__icon": `style_reward__icon__XEl6n`
};
export default ___CSS_LOADER_EXPORT___;
