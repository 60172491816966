// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_section__divider__mc3t3 {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  justify-content: center;
  gap: 24px;
  flex-wrap: nowrap;
}

.style_horizontal__line__0Dk59 {
  position: relative;
  width: 100%;
  height: 2px;
  transform: translateY(-50%);
}
.style_horizontal__line__0Dk59 .style_lineExtension__KoeOI {
  content: "";
  position: absolute;
  width: 12px;
  height: 2px;
  transform: rotate(-35deg);
  transform-origin: left center;
  left: 100%;
  top: 20%;
}

.style_line__container__reversed__zOl7F {
  position: relative;
}

.style_horizontal__line__reversed__66ge3 {
  position: relative;
  width: 100%;
  height: 2px;
  transform: translateY(-50%);
}
.style_horizontal__line__reversed__66ge3 .style_lineExtension__KoeOI {
  content: "";
  position: absolute;
  width: 12px;
  height: 2px;
  transform: rotate(-135deg);
  transform-origin: left center;
  left: 0%;
  top: 20%;
}`, "",{"version":3,"sources":["webpack://./src/components/section-divider/style.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,8BAAA;EACA,qBAAA;EACA,WAAA;EACA,uBAAA;EACA,SAAA;EACA,iBAAA;AACF;;AAEA;EACE,kBAAA;EACA,WAAA;EACA,WAAA;EACA,2BAAA;AACF;AACE;EACE,WAAA;EACA,kBAAA;EACA,WAAA;EACA,WAAA;EACA,yBAAA;EACA,6BAAA;EACA,UAAA;EACA,QAAA;AACJ;;AAGA;EACE,kBAAA;AAAF;;AAGA;EACE,kBAAA;EACA,WAAA;EACA,WAAA;EACA,2BAAA;AAAF;AAEE;EACE,WAAA;EACA,kBAAA;EACA,WAAA;EACA,WAAA;EACA,0BAAA;EACA,6BAAA;EACA,QAAA;EACA,QAAA;AAAJ","sourcesContent":[".section__divider {\n  display: flex;\n  justify-content: space-between;\n  align-items: flex-end;\n  width: 100%;\n  justify-content: center;\n  gap: 24px;\n  flex-wrap: nowrap;\n}\n\n.horizontal__line {\n  position: relative;\n  width: 100%;\n  height: 2px;\n  transform: translateY(-50%);\n\n  .lineExtension {\n    content: '';\n    position: absolute;\n    width: 12px;\n    height: 2px;\n    transform: rotate(-35deg);\n    transform-origin: left center;\n    left: 100%;\n    top: 20%;\n  }\n}\n\n.line__container__reversed {\n  position: relative;\n}\n\n.horizontal__line__reversed {\n  position: relative;\n  width: 100%;\n  height: 2px;\n  transform: translateY(-50%);\n\n  .lineExtension {\n    content: '';\n    position: absolute;\n    width: 12px;\n    height: 2px;\n    transform: rotate(-135deg);\n    transform-origin: left center;\n    left: 0%;\n    top: 20%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"section__divider": `style_section__divider__mc3t3`,
	"horizontal__line": `style_horizontal__line__0Dk59`,
	"lineExtension": `style_lineExtension__KoeOI`,
	"line__container__reversed": `style_line__container__reversed__zOl7F`,
	"horizontal__line__reversed": `style_horizontal__line__reversed__66ge3`
};
export default ___CSS_LOADER_EXPORT___;
