const ROUTES = {
  HOME: '/',
  BOOSTS: '/boosts',
  CHALLENGE: '/challenge',
  EARN: '/earn',
  PROFILE: '/profile',
  REFERRAL: '/referral',
  REFERRAL_ALL: '/referrals-all',
  TELEGRAM_ONLY: '/telegram-only',
  EMAIL: '/email',
  RESTRICTED_DEVICE: '/restricted-device',
  CLANS: '/clans',
  CLAN: '/clan',
  JOIN_CLAN: '/join-clan',
  CLAN_WARS: '/wars',
};

export default ROUTES;
