// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_error__VMSxM {
  width: 100vw;
  height: 100vh;
  background: rgb(117, 83, 240);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 4px;
}
.style_error__emodji__fSLpH {
  font-size: 6.4rem;
  color: rgb(255, 255, 255);
  font-weight: 700;
}
.style_error__VMSxM h1 {
  font-size: 2rem;
  font-weight: 700;
  color: rgb(255, 255, 255);
}
.style_error__VMSxM h2 {
  font-size: 1.6rem;
  font-weight: 400;
  color: rgb(255, 255, 255);
}`, "",{"version":3,"sources":["webpack://./src/pages/telegram-only/style.module.scss","webpack://./public/assets/colors.scss"],"names":[],"mappings":"AAEA;EACE,YAAA;EACA,aAAA;EACA,6BCLK;EDML,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,sBAAA;EACA,QAAA;AADF;AAEE;EACE,iBAAA;EACA,yBCRI;EDSJ,gBAAA;AAAJ;AAEE;EACE,eAAA;EACA,gBAAA;EACA,yBCdI;ADcR;AAEE;EACE,iBAAA;EACA,gBAAA;EACA,yBCnBI;ADmBR","sourcesContent":["@import 'public/assets/colors';\n\n.error {\n  width: 100vw;\n  height: 100vh;\n  background: $main;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column;\n  gap: 4px;\n  &__emodji {\n    font-size: 6.4rem;\n    color: $white;\n    font-weight: 700;\n  }\n  h1 {\n    font-size: 2rem;\n    font-weight: 700;\n    color: $white;\n  }\n  h2 {\n    font-size: 1.6rem;\n    font-weight: 400;\n    color: $white;\n  }\n}\n","$main: rgba(117, 83, 240, 1);\n$light-main: rgba(237, 235, 254, 1);\n$black-extra: rgba(27, 27, 27, 1);\n$dark-grey: rgba(135, 135, 135, 1);\n$light-background: rgba(247, 246, 255, 1);\n$white: rgba(255, 255, 255, 1);\n$light-grey: rgba(213, 212, 220, 1);\n$yellow: rgba(255, 199, 1, 1);\n$grey: rgba(158, 158, 158, 1);\n$light-green: #39b283;\n$green: #1f6b4e;\n$dark-green: #1f4c3a;\n$danger: #c10000;\n$red: #e73232;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"error": `style_error__VMSxM`,
	"error__emodji": `style_error__emodji__fSLpH`
};
export default ___CSS_LOADER_EXPORT___;
