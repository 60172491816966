// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_game__jLKgp {
  height: 100%;
  position: absolute;
  z-index: 5000;
  opacity: 1;
  top: 0;
}

.style_hidden__ExkcX {
  z-index: -1;
}`, "",{"version":3,"sources":["webpack://./src/pages/game/style.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,kBAAA;EACA,aAAA;EACA,UAAA;EACA,MAAA;AACF;;AAEA;EACE,WAAA;AACF","sourcesContent":[".game {\n  height: 100%;\n  position: absolute;\n  z-index: 5000;\n  opacity: 1;\n  top: 0;\n}\n\n.hidden {\n  z-index: -1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"game": `style_game__jLKgp`,
	"hidden": `style_hidden__ExkcX`
};
export default ___CSS_LOADER_EXPORT___;
