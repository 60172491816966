import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../services/store';
import classes from './style.module.scss';
import clsx from 'clsx';
import TowerGame from '../../features/tower/tower-game';
import { incrementVault } from '../../services/user/userSlice';
import { levelIntervals, vaultSizes } from './constants';
import {
  useBoomMutation,
  useClaimTapGameMutation,
  useGetGameEventsQuery,
  useKillBossMutation,
  useStartTapGameMutation,
} from '../../features/tower/api/gameApi';
import { useLazyGetUserQuery } from '../../services/user/userApi';
import { GtagEventType, trackGtagEvent } from '../../utils/gtagEventHandler';
import { useGetClansQuery } from '../../features/clans/api/clansApi';

const Game = () => {
  const isTowerPage = window.location.pathname === '/tower';
  const user = useSelector((state: RootState) => state.user.user);
  const vault = useSelector(
    (state: RootState) => state.user.user.towerGame.vaultBalance,
  );
  const intervalRef = useRef<NodeJS.Timeout | null>(null);
  const vaultRef = useRef(vault);
  const dispatch = useDispatch();

  const { data: gameEvents } = useGetGameEventsQuery(undefined, {
    skip: !user._id,
  });

  const { data: clan } = useGetClansQuery(undefined, {
    skip: !user._id,
  });

  const userClan = clan?.clans.find((clan) => clan._id === user.clan.clanId);

  const [fetchUser] = useLazyGetUserQuery();

  const [explode, { isLoading: explodeLoading }] = useBoomMutation();
  const [onBossExplode, { isLoading: onBossExplodeLoading }] =
    useKillBossMutation();

  const [startTapGame] = useStartTapGameMutation();
  const [claimTapGame] = useClaimTapGameMutation();

  const onBombExplode = async () => {
    if (!explodeLoading) {
      try {
        await explode();
        await fetchUser();
        trackGtagEvent(GtagEventType.GAME_BOMB);
      } catch (error) {
        console.log('error', error);
      }
    }
  };

  const onBossKill = async () => {
    if (!onBossExplodeLoading) {
      try {
        await onBossExplode();
        await fetchUser();
        trackGtagEvent(GtagEventType.GAME_BOSS);
      } catch (error) {
        console.log('error', error);
      }
    }
  };

  const onTapGameStart = async () => {
    await startTapGame().unwrap();
  };

  const onFlowersCollect = async (streak: number) => {
    await claimTapGame({ streak: streak });
    await fetchUser();
  };

  useEffect(() => {
    vaultRef.current = vault;
  }, [vault]);

  useEffect(() => {
    if (user._id && !intervalRef.current) {
      const intervalTime = levelIntervals[user.towerGame.towerLevel] || 3000;
      intervalRef.current = setInterval(() => {
        const currentVaultSize = vaultSizes[user.towerGame.storageLevel];
        if (Math.floor(vaultRef.current) + 1 <= currentVaultSize) {
          dispatch(incrementVault());
        }
      }, intervalTime);
    }

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      }
    };
  }, [user, user.towerGame.towerLevel, isTowerPage, dispatch]);

  if (!user._id || !gameEvents) {
    return null;
  }

  return (
    <div
      className={clsx(classes.game, {
        [classes.hidden]: !isTowerPage,
      })}
    >
      <TowerGame
        towerLevel={user.towerGame.towerLevel}
        sound={user.soundEnabled}
        level={user.level}
        gameEvents={gameEvents}
        onBombExplode={onBombExplode}
        onBossExplode={onBossKill}
        clan={userClan || null}
        onTapCollect={onFlowersCollect}
        onTapGameStart={onTapGameStart}
      />
    </div>
  );
};

export default Game;
