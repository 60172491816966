import classes from './style.module.scss';

interface PageTitleProps {
  title: string;
  isWhite?: boolean;
}

const PageTitle = ({ title, isWhite }: PageTitleProps) => {
  return (
    <h4 className={`${classes.title} ${isWhite && classes.title__white}`}>
      {title}
    </h4>
  );
};

export default PageTitle;
