import React from 'react';
import classes from './style.module.scss';
import { levels } from '../../utils/utils';

interface ProgressProps {
  currentXP: number;
  level: number;
}

const Progress = ({ currentXP, level }: ProgressProps) => {
  const nextLevelXP = levels[level] - levels[level - 1] || levels[level];
  const previousLevelXP = levels[level - 1] || 0;
  const currentLevelXP = currentXP - previousLevelXP;

  const progress = (currentLevelXP / nextLevelXP) * 100;

  return (
    <div className={classes.progress__container}>
      <div
        className={classes.progress__bar}
        style={{ width: `${progress}%`, maxWidth: '100%' }}
      ></div>
    </div>
  );
};

export default Progress;
